import { getCountBranch } from "utils/app.utils"

export enum PACKAGE_TYPE {
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  ULTRA = 'ULTRA'
}

export enum PACKAGE_ADDONS {
  ADDON_APPLICATION_NOTICE = 'ADDON_APPLICATION_NOTICE',
  ADDON_EMAIL_NOTICE = 'ADDON_EMAIL_NOTICE',
  ADDON_LINE_NOTIFY = 'ADDON_LINE_NOTIFY',
  ADDON_FORM_RETRIEVE = 'ADDON_FORM_RETRIEVE',
  // ADDON_DOCTOR_NOTE = 'ADDON_DOCTOR_NOTE',
  ADDON_DOCTOR_NOTE = 'DOCTOR_NOTE',
  ADDON_HOME = 'HOME',
  ADDON_DASHBOARD = 'DASHBOARD',
  ADDON_REGISTER_APP = 'REGISTER_APP',
  ADDON_BANNER = 'BANNER',
  ADDON_ABOUT_US = 'ABOUT_US',
  ADDON_SERVICE = 'SERVICE',
  ADDON_PRICE_LIST = 'PRICE_LIST',
  ADDON_REVIEW = 'REVIEW',
  ADDON_ARTICLE = 'ARTICLE',
  ADDON_CONTACT_US = 'CONTACT_US',
  ADDON_PROMOTIONS = 'PROMOTIONS',
  ADDON_NEWS = 'NEWS',
  ADDON_RETROSPECTIVE_ORDER = 'RETROSPECTIVE_ORDER',

  ADDON_TREATMENT_PLAN_MANAGE = 'TREATMENT_PLAN_MANAGE',
  ADDON_TREATMENT_PLAN_TYPE = 'TREATMENT_PLAN_TYPE',
  ADDON_TREATMENT_PLAN = 'TREATMENT_PLAN',

  ADDON_TREATMENT_COURSE_MANAGE = 'TREATMENT_COURSE_MANAGE',
  ADDON_TREATMENT_COURSE_TYPE = 'TREATMENT_COURSE_TYPE',
  ADDON_TREATMENT_COURSE = 'TREATMENT_COURSE',

  ADDON_DOCTOR_NOTE_TYPE_FORM = 'DOCTOR_NOTE_TYPE_FORM',
  ADDON_DOCTOR_NOTE_FORM = 'DOCTOR_NOTE_FORM',
  ADDON_DOCTOR_NOTE_TYPE_PIC = 'DOCTOR_NOTE_TYPE_PIC',
  ADDON_DOCTOR_NOTE_PIC = 'DOCTOR_NOTE_PIC',

  ADDON_LAB = 'LAB',
  ADDON_LAB_MANAGEMENT = 'LAB_MANAGEMENT',
  ADDON_LAB_LIST = 'LAB_LIST',
  ADDON_LAB_TYPE = 'LAB_TYPE',
  ADDON_LAB_GROUP = 'LAB_GROUP',
  ADDON_LAB_COMPANY = 'LAB_COMPANY',

  ADDON_X_RAY = 'X_RAY',
  ADDON_XRAY_FILM_TYPE = 'XRAY_FILM_TYPE',

  ADDON_INCOME_EXPENSES = 'INCOME_EXPENSES',
  ADDON_ADVANCE = 'ADVANCE',

  ADDON_SETING_DOCTOR_NOTE = 'DOCTOR_NOTE',
  ADDON_WEB_MANAGE = "WEB_MANAGE",

  ADDON_APPROVELIST = "USAGE_APPROVAL_MANAGEMENT"
}

export const STANDARD = [
  PACKAGE_ADDONS.ADDON_HOME,
  PACKAGE_ADDONS.ADDON_FORM_RETRIEVE,
  PACKAGE_ADDONS.ADDON_ADVANCE,
  PACKAGE_ADDONS.ADDON_RETROSPECTIVE_ORDER,
]

export const PREMIUM = [
  ...STANDARD,
  PACKAGE_ADDONS.ADDON_LINE_NOTIFY,
  PACKAGE_ADDONS.ADDON_EMAIL_NOTICE,
  PACKAGE_ADDONS.ADDON_DOCTOR_NOTE,
  PACKAGE_ADDONS.ADDON_RETROSPECTIVE_ORDER,
  PACKAGE_ADDONS.ADDON_TREATMENT_PLAN_MANAGE,
  PACKAGE_ADDONS.ADDON_TREATMENT_PLAN_TYPE,
  PACKAGE_ADDONS.ADDON_TREATMENT_PLAN,
  PACKAGE_ADDONS.ADDON_TREATMENT_COURSE_MANAGE,
  PACKAGE_ADDONS.ADDON_TREATMENT_COURSE_TYPE,
  PACKAGE_ADDONS.ADDON_TREATMENT_COURSE,
  PACKAGE_ADDONS.ADDON_LAB,
  PACKAGE_ADDONS.ADDON_LAB_MANAGEMENT,
  PACKAGE_ADDONS.ADDON_LAB_LIST,
  PACKAGE_ADDONS.ADDON_LAB_TYPE,
  PACKAGE_ADDONS.ADDON_LAB_GROUP,
  PACKAGE_ADDONS.ADDON_LAB_COMPANY,
  PACKAGE_ADDONS.ADDON_X_RAY,
  PACKAGE_ADDONS.ADDON_XRAY_FILM_TYPE,
  PACKAGE_ADDONS.ADDON_INCOME_EXPENSES,
  PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_TYPE_FORM,
  PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_FORM,
  PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_TYPE_PIC,
  PACKAGE_ADDONS.ADDON_DOCTOR_NOTE_PIC
]

export const ULTRA = [
  ...PREMIUM,
  PACKAGE_ADDONS.ADDON_APPLICATION_NOTICE,
  PACKAGE_ADDONS.ADDON_DASHBOARD,
  PACKAGE_ADDONS.ADDON_REGISTER_APP,
  PACKAGE_ADDONS.ADDON_BANNER,
  PACKAGE_ADDONS.ADDON_ABOUT_US,
  PACKAGE_ADDONS.ADDON_SERVICE,
  PACKAGE_ADDONS.ADDON_PRICE_LIST,
  PACKAGE_ADDONS.ADDON_REVIEW,
  PACKAGE_ADDONS.ADDON_ARTICLE,
  PACKAGE_ADDONS.ADDON_CONTACT_US,
  PACKAGE_ADDONS.ADDON_PROMOTIONS,
  PACKAGE_ADDONS.ADDON_NEWS,
  PACKAGE_ADDONS.ADDON_WEB_MANAGE,
  PACKAGE_ADDONS.ADDON_APPROVELIST,
]

export const PACKAGE = {
  STANDARD: STANDARD,
  PREMIUM: PREMIUM,
  ULTRA: ULTRA
}

export interface PackageItem {
  time: string
  package: PACKAGE_TYPE
  packageExpired: string
  countBranch: number
  enabledApp: number
}

export const userLimit = (packageType: PACKAGE_TYPE) => {
  if (packageType === PACKAGE_TYPE.STANDARD) return 10 * getCountBranch()
  else if (packageType === PACKAGE_TYPE.PREMIUM) return 30 * getCountBranch()
  else return 0
}