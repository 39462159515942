import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/62.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethU, TextNumberU } from 'component/ToothChart/style'

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginTop: 'auto',
    top: '1.4%',
    left: '45.2%',
    maxWidth: '65px',
}))

export default function Teeth62(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)

    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 28.03, height: 100, viewBox: "0 0 28.03 110" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);

    useEffect(() => {
        const operative = props.operative || ``
        const valColor = operative ? props.color || colors.themeSecondColor : ''
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })

        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }


        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        setStyleSvg({ width: 28.03, height: 100, viewBox: "0 0 28.03 110" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <DivTeethU className={`div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`md teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`md ${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12340" >
                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ''}
                            <path
                                data-name="Path 5432"
                                d="M4.71301 59.6559C1.22887 68.2564 -0.25708 77.3787 1.39109 86.8759C1.42207 87.5006 1.45844 88.1244 1.49481 88.7483L1.62605 88.104C1.6907 88.4504 1.76864 88.8024 1.8458 89.147C4.139 92.376 7.51596 91.6821 10.2743 91.863C14.8855 92.1589 19.6289 91.4819 23.9822 88.9779C27.3255 86.6085 28.2365 82.4606 27.7569 78.4315C25.5107 59.4159 27.0748 39.6816 20.6018 21.4457C18.858 16.5349 18.7907 11.0801 19.2434 5.70492C19.3639 4.21974 19.4534 2.72052 18.3175 1.15029C15.1214 -0.243488 14.2741 2.97568 13.3029 5.76323C9.85341 15.6955 10.2902 26.3668 9.49452 36.8086C8.88741 44.7383 7.66721 52.3859 4.71301 59.6559Z"
                                transform={"translate(0 5)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5432"
                                    d="M4.71301 59.6559C1.22887 68.2564 -0.25708 77.3787 1.39109 86.8759C1.42207 87.5006 1.45844 88.1244 1.49481 88.7483L1.62605 88.104C1.6907 88.4504 1.76864 88.8024 1.8458 89.147C4.139 92.376 7.51596 91.6821 10.2743 91.863C14.8855 92.1589 19.6289 91.4819 23.9822 88.9779C27.3255 86.6085 28.2365 82.4606 27.7569 78.4315C25.5107 59.4159 27.0748 39.6816 20.6018 21.4457C18.858 16.5349 18.7907 11.0801 19.2434 5.70492C19.3639 4.21974 19.4534 2.72052 18.3175 1.15029C15.1214 -0.243488 14.2741 2.97568 13.3029 5.76323C9.85341 15.6955 10.2902 26.3668 9.49452 36.8086C8.88741 44.7383 7.66721 52.3859 4.71301 59.6559Z"
                                    transform={"translate(0 5)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12340" >
                                <path
                                    data-name="Path 5433"
                                    d="M25.8191 36.6282C27.7889 33.8486 28.6998 27.7202 27.7434 23.3376C27.3749 21.63 26.9043 19.9439 26.3683 18.2825C26.3622 18.2825 26.3622 18.2746 26.3629 18.2675C25.173 14.6204 23.6462 11.0898 22.0814 7.67688C17.9547 -1.33213 9.80623 -1.24515 5.4448 7.50062C5.31529 7.75674 5.19233 8.02018 5.06821 8.29286C2.8033 13.3569 1.05854 18.7133 0.167969 24.4518C0.367327 29.546 -0.474366 31.2544 2.36648 37.0495C5.87449 41.571 21.1848 42.8797 25.8191 36.6282Z"
                                    transform={"translate(0 62)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5434"
                                    d="M25.593 39.4273C27.6062 36.6928 28.6128 30.5866 27.7257 26.1829C27.3837 24.4673 26.9398 22.7715 26.4302 21.0975C26.4231 21.0975 26.4241 21.0896 26.4248 21.0815C25.2914 17.4086 23.8214 13.8431 22.3097 10.3951C18.3264 1.29314 10.1774 1.19288 5.67948 9.83705C5.54651 10.0895 5.41816 10.351 5.28962 10.6204C2.94678 15.6323 1.11792 20.947 0.137681 26.6641C0.257372 31.7618 -0.611068 33.4508 2.13896 39.3105C5.57326 43.9119 20.8613 45.5716 25.593 39.4273Z"
                                    transform={"translate(0 62)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5435"
                                    d="M24.8384 36.9677C26.5753 34.6081 27.4435 29.3384 26.678 25.5388C26.383 24.059 26.0001 22.5946 25.5598 21.1514C25.5544 21.1514 25.5554 21.1443 25.5554 21.1381C24.578 17.9686 23.3092 14.892 22.0056 11.9165C18.5675 4.06205 11.5363 3.97699 7.65513 11.4352C7.54083 11.6532 7.43018 11.8792 7.31934 12.1105C5.29728 16.4355 3.72089 21.0211 2.8742 25.9539C2.97792 30.3534 2.22821 31.8101 4.6003 36.8662C7.56392 40.8369 20.7552 42.2687 24.8384 36.9677Z"
                                    transform={"translate(0 62)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />
                                {(isToothRoot && props.type === 'treatment') ?
                                    <path
                                        data-name="Path 6565"
                                        d="M3.33093 51.7193C3.348 50.2553 3.03813 45.5961 3.17093 38.4841C3.1976 36.7449 3.03333 33.0222 3.4424 30.2623C3.66746 28.7476 5.02266 1.9028 4.9224 1.60093C3.85573 0.800847 3.47308 30.3112 3.44293 31.6889C3.28293 37.7342 3.62266 49.8708 2.78906 51.6436C2.97286 51.634 3.15681 51.6597 3.33093 51.7193Z"
                                        transform="translate(12 9)"
                                        style={{
                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }}
                                    />
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6581" d="m33.788-.5-27.542 80" transform="translate(-5 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        <path data-name="Path 6582" d="m6.246-.5 27.542 80" transform="translate(-5 10.606)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                    </>
                                    : ''}
                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <defs>
                                            <linearGradient id="paint0_linear_4126_154152" x1="14.4903" y1="73.7759" x2="12.3062" y2="86.2425" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4126_154152" x1="14.4903" y1="82.52" x2="14.4903" y2="77.8963" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9384">
                                            <path transform={"translate(0 10)"} d="M4.86846 57.4998C1.49064 65.8378 0.0500442 74.6818 1.64791 83.8892C1.67795 84.4948 1.71321 85.0996 1.74847 85.7044L1.8757 85.0798C1.93838 85.4156 2.01394 85.7568 2.08875 86.0909C4.31196 89.2214 7.58586 88.5487 10.26 88.724C14.7305 89.0109 19.3291 88.3546 23.5496 85.927C26.7909 83.6299 27.674 79.6086 27.2091 75.7025C25.0315 57.2672 26.5478 38.1351 20.2724 20.4558C18.5818 15.6948 18.5165 10.4064 18.9555 5.19532C19.0722 3.75547 19.159 2.302 18.0577 0.779685C14.9592 -0.571554 14.1378 2.54937 13.1962 5.25185C9.85198 14.881 10.2755 25.2266 9.50405 35.3498C8.91546 43.0375 7.7325 50.4517 4.86846 57.4998Z" fill="#E3D1B4" />
                                            <path transform={"translate(0 10)"} d="M25.3293 87.5271C27.2389 84.8323 28.1221 78.891 27.1949 74.6421C26.8376 72.9866 26.3813 71.352 25.8617 69.7412C25.8558 69.7412 25.8558 69.7336 25.8565 69.7267C24.7028 66.1909 23.2227 62.7681 21.7056 59.4593C17.7049 50.7252 9.80506 50.8095 5.57673 59.2884C5.45118 59.5367 5.33196 59.7921 5.21163 60.0565C3.01585 64.9659 1.32433 70.1589 0.460938 75.7223C0.654212 80.661 -0.161795 82.3173 2.59236 87.9355C5.99331 92.319 20.8364 93.5878 25.3293 87.5271Z" fill="#EFEDE7" />
                                            <path transform={"translate(0 10)"} d="M25.1079 90.2407C27.0596 87.5897 28.0355 81.6699 27.1755 77.4005C26.844 75.7374 26.4136 74.0932 25.9196 72.4703C25.9127 72.4703 25.9136 72.4627 25.9144 72.4549C24.8155 68.894 23.3904 65.4373 21.9248 62.0946C18.0631 53.2704 10.1627 53.1732 5.80211 61.5535C5.67319 61.7983 5.54876 62.0518 5.42414 62.313C3.1528 67.1719 1.37975 72.3245 0.429428 77.8671C0.545467 82.8092 -0.29647 84.4466 2.36963 90.1275C5.69913 94.5885 20.5206 96.1975 25.1079 90.2407Z" fill="#F7F7F7" />
                                            <path transform={"translate(0 10)"} d="M24.3741 87.8565C26.058 85.5689 26.8997 80.46 26.1576 76.7764C25.8716 75.3418 25.5003 73.9221 25.0735 72.5229C25.0683 72.5229 25.0692 72.516 25.0692 72.51C24.1217 69.4372 22.8915 66.4545 21.6278 63.5698C18.2945 55.955 11.4779 55.8726 7.71521 63.1032C7.6044 63.3146 7.49713 63.5336 7.38967 63.7578C5.42932 67.9509 3.90103 72.3966 3.08018 77.1788C3.18073 81.4441 2.45391 82.8563 4.7536 87.7581C7.62678 91.6076 20.4155 92.9958 24.3741 87.8565Z" fill="white" />
                                            <path transform={"translate(0 10)"} d="M19.414 70H9.58598C8.15778 70 7 71.1578 7 72.586V82.414C7 83.8422 8.15778 85 9.58598 85H19.414C20.8422 85 22 83.8422 22 82.414V72.586C22 71.1578 20.8422 70 19.414 70Z" fill="#C1C4C5" />
                                            {/* <path transform={"translate(0 10)"} d="M19.416 70.6465H9.58793C8.51678 70.6465 7.64844 71.5148 7.64844 72.586V82.414C7.64844 83.4852 8.51678 84.3535 9.58793 84.3535H19.416C20.4871 84.3535 21.3554 83.4852 21.3554 82.414V72.586C21.3554 71.5148 20.4871 70.6465 19.416 70.6465Z" stroke="#C1C4C5" /> */}
                                            <path transform={"translate(0 10)"} d="M13.3745 84.3314C13.1159 84.7995 15.5416 84.568 17.3608 84.5447C20.0684 84.4878 21.6135 85.0413 21.5876 78.7909C21.6579 76.573 21.5892 74.353 21.382 72.1437C20.6955 71.4765 21.5631 78.9138 19.8059 82.185C18.1483 84.5473 13.818 83.8297 13.3745 84.3314Z" fill="#B0B1B1" />
                                            <path transform={"translate(0 10)"} d="M17.0763 78.0352H11.9043C11.5473 78.0352 11.2578 78.3246 11.2578 78.6817V79.9746C11.2578 80.3317 11.5473 80.6211 11.9043 80.6211H17.0763C17.4333 80.6211 17.7228 80.3317 17.7228 79.9746V78.6817C17.7228 78.3246 17.4333 78.0352 17.0763 78.0352Z" fill="#CBCCCC" />
                                            <path transform={"translate(0 10)"} d="M16.8146 72.6045H11.6426C11.2855 72.6045 10.9961 72.8939 10.9961 73.251V74.544C10.9961 74.901 11.2855 75.1905 11.6426 75.1905H16.8146C17.1716 75.1905 17.4611 74.901 17.4611 74.544V73.251C17.4611 72.8939 17.1716 72.6045 16.8146 72.6045Z" fill="#9E9E9E" />
                                            <path transform={"translate(0 10)"} d="M17.0763 72.6045H11.9043C11.5473 72.6045 11.2578 72.8939 11.2578 73.251V74.544C11.2578 74.901 11.5473 75.1905 11.9043 75.1905H17.0763C17.4333 75.1905 17.7228 74.901 17.7228 74.544V73.251C17.7228 72.8939 17.4333 72.6045 17.0763 72.6045Z" fill="url(#paint0_linear_4126_154152)" />
                                            <path transform={"translate(0 10)"} d="M17.0763 77.7773H11.9043C11.5473 77.7773 11.2578 78.0668 11.2578 78.4238V79.7168C11.2578 80.0739 11.5473 80.3633 11.9043 80.3633H17.0763C17.4333 80.3633 17.7228 80.0739 17.7228 79.7168V78.4238C17.7228 78.0668 17.4333 77.7773 17.0763 77.7773Z" fill="url(#paint1_linear_4126_154152)" />
                                            <path transform={"translate(0 10)"} d="M0.570312 79.2778C0.570312 79.2778 12.2499 74.3061 27.6027 78.246" stroke="#BABBBB" strokeLinecap="round" />
                                            <path transform={"translate(0 10)"} d="M0.570312 79.7885C9.36617 76.7935 18.6575 76.4303 27.6027 78.7318" stroke="#B5B7B7" strokeLinecap="round" />
                                        </g>
                                    </> || ''}
                            </g>
                        </g>
                    </svg>}
            </DivTeethU>

            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q2} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <TextNumberU>62</TextNumberU>
        </Div >
    )
}
