import { menuFirsts } from 'constants/menus'
import Report from 'features/report/report'

import SaleItem from 'features/report/SaleItem/SaleItem'
import Income from 'features/report/Income/Income'
import IncomeMonthly from 'features/report/IncomeMonthly/IncomeMonthly'
import Operative from 'features/report/Operative/Operative'
import TreatmentRight from 'features/report/TreratmentRight/TreatmentRight'
import Remittance from 'features/report/Remittance/remittance'
import Schedules from 'features/report/Schedules/Schedules'
import TransactionRevenue from 'features/report/TransactionRevenue/TransactionRevenue'
import PatientInformation from 'features/report/PatientInformation/PatientInformation'
import DF_LAB from 'features/report/DfLab/DfLab'
import Remuneration from 'features/report/Remuneration/Remuneration'
import LabOperative from 'features/report/LabOperative/LabOperative'
import RemunerationClinicFee from 'features/report/RemunerationClinicFee/RemunerationClinicFee'
import RemunerationDentist from 'features/report/RemunerationDentist/RemunerationDentist'
import RemunerationClinicFeeDentist from 'features/report/RemunerationClinicFeeDentist/RemunerationClinicFeeDentist'
import LabReport from 'features/report/Lab/LabReport'
import Commission from 'features/report/commission/commission'
import NewPatient from 'features/report/NewPatient/NewPatient'
import SchedulesDoctor from 'features/report/SchedulesDoctor/SchedulesDoctor'
import FollowUp from 'features/report/followUp/followUp'
import PatientDateOfBirth from 'features/report/PatientDateOfBirth/PatientDateOfBirth'
import AccessChannel from 'features/report/AccessChannel/AccessChanel'
import Stock from 'features/report/Stock/stock'
import Visit from 'features/report/Visit/Visit'
import TreatmentRightBalance from 'features/report/treatmentRightBalance/treatmentRightBalance'
import TreatmentCourse from 'features/report/TreatmentCourse/TreatmentCourse'
import Df from 'features/report/df/Df'
import PatientReceiveService from 'features/report/PatientReceiveService/PatientReceiveService'
import Remittances from 'features/report/Remittances/remittances'
import RemittanceSecond from 'features/report/RemittanceSecond/remittanceSecond'
import PatientsTreatment from 'features/report/PatientsTreatment/PatientsTreatment'
import Appointment from 'features/report/Appointment/Appointment'
import DoctorFee from 'features/report/DoctorFee/DoctorFee'
import Balance from 'features/report/Balance/Balance'
import AdvanceBalance from 'features/report/Advance/Balance/Balance'
import AdvanceDecrease from 'features/report/Advance/Decrease/Decrease'
import AdvanceIncrease from 'features/report/Advance/Increase/Increase'
import AdvanceSummary from 'features/report/Advance/Summary/Summary'
import Accounting from 'features/report/Accounting/Accounting'
import AccountingCode from 'features/report/AccountingCode/AccountingCode'
import RevenueTransaction from 'features/report/RevenueTransaction/RevenueTransaction'
import IncomeSummaryReport from 'features/report/IncomeSummary/IncomeSummary'
import IncomeExpense from 'features/report/IncomeExpense/IncomeExpense'
import IncomeOperativeReport from 'features/report/IncomeOperative/Income'

const reportConfig = {
  ...menuFirsts.REPORT,
  active: false,
  appBar: true
}
const routeReport = [
  {
    ...reportConfig,
    component: Report
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/income-simple-daily',
    component: Income
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/income-simple-daily2',
    component: IncomeSummaryReport
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/income-simple-monthly',
    component: IncomeMonthly
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/income-simple-daily-operative',
    component: IncomeOperativeReport
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/sale-summary-monthly',
    component: SaleItem
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/operative-summary-month',
    component: Operative
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/treatment-right-month',
    component: TreatmentRight
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remittance',
    component: Remittance
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/schedules',
    component: Schedules
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/transaction-revenue',
    component: TransactionRevenue
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/patient-information',
    component: PatientInformation
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/df-lab',
    component: DF_LAB
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remuneration',
    component: Remuneration
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/df-lab-operative',
    component: LabOperative
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remuneration-clinic-fee',
    component: RemunerationClinicFee
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remuneration_all_dentist',
    component: RemunerationDentist
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remuneration-clinic-fee-all-dentist',
    component: RemunerationClinicFeeDentist
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/lab',
    component: LabReport
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/commission',
    component: Commission
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/new-patient',
    component: NewPatient
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/schedules-doctor',
    component: SchedulesDoctor
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/follow-up',
    component: FollowUp
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/patient-date-of-birth',
    component: PatientDateOfBirth
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/access-channel',
    component: AccessChannel
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/stock',
    component: Stock
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/visit',
    component: Visit
  },

  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/treatment-right-balance',
    component: TreatmentRightBalance
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/treatment-course',
    component: TreatmentCourse
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/df',
    component: Df
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/patients-receive-service',
    component: PatientReceiveService
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remittances',
    component: Remittances
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/remittanceSecond',
    component: RemittanceSecond
  },

  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/patients-treatment',
    component: PatientsTreatment
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/appointment',
    component: Appointment
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/doctor-fee',
    component: DoctorFee
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/balance',
    component: Balance
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/advance-balance',
    component: AdvanceBalance
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/advance-decrease',
    component: AdvanceDecrease
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/advance-increase',
    component: AdvanceIncrease
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/advance',
    component: AdvanceSummary
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/accounting',
    component: Accounting
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/accounting-code',
    component: AccountingCode
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/revenue-transaction',
    component: RevenueTransaction
  },
  {
    ...reportConfig,
    path: menuFirsts.REPORT.path + '/income-expense',
    component: IncomeExpense
  }
]

export default routeReport
