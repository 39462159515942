import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material'
import { ExportProps, ReportsProps } from 'features/report/report-constants'
import { checkPackage, isBuddhistYear, numberFormat, numberToCurrencyFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { ThemeIcon, colors } from 'constants/theme'
import { dateToView, timeToView } from 'utils/date.utils'
import { PACKAGE_ADDONS } from 'constants/package'
import { styled } from '@mui/material/styles'
import { icons } from 'constants/images'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'react-bootstrap'
import { UseSnCode } from 'utils/useSnCode'

export interface IFAppointmentList {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  dataExport: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  date: any
  filterTable: any
}

const TextStatus = styled(`div`)(({ theme }) => ({
  color: colors.white,
  width: 'auto',
  paddingLeft: 3,
  fontSize: 11
}))

export const BoxStatus = styled(`div`)<{ bg?: string }>(({ theme, bg }) => ({
  backgroundColor: bg,
  display: 'flex',
  flexDirection: 'row',
  justifyItems: 'center',
  fontSize: 11,
  paddingLeft: 5,
  paddingRight: 5,
  paddingTop: 2,
  paddingBottom: 2,
  borderRadius: 3,
  '> svg': {
    marginBottom: 'auto',
    marginTop: 'auto'
  }
}))
export default function AppointmentList(props: IFAppointmentList) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const getDurationTextByTimeMinute = (minute: number) => {
    if (minute >= 60) return `${Number(minute / 60)} ${t('TIME_DURATION_TYPE.HOURS')} ` + (minute % 60 > 0 ? `${minute % 60} ${t('TIME_DURATION_TYPE.MINUTES')}` : '')
    else return `${minute} ${t('TIME_DURATION_TYPE.MINUTES')}`
  }

  const statusIcon: any = {
    NORMAL: { icon: <FontAwesomeIcon style={{ color: colors.white }} icon={faUser} />, color: colors.appointmentStatus.statusNormal, label: t('APPOINTMENT.STATUS.NORMAL'), disabled: false },
    CANCEL_APP: { icon: <Image style={{ height: '15px' }} src={icons.appointment.cancel_app} />, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
    CANCEL: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCancel} />, color: colors.appointmentStatus.statusCancel, label: t('APPOINTMENT.STATUS.CANCEL'), disabled: false },
    CAN_NOT_CONTACT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconNotConnect} />, color: colors.appointmentStatus.statusUnConnect, label: t('APPOINTMENT.STATUS.CAN_NOT_CONTACT'), disabled: false },
    PUT_OFF: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconChangeSchedule} />, color: colors.appointmentStatus.statusChange, label: t('APPOINTMENT.STATUS.PUT_OFF'), disabled: false },
    CONFIRM: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconEnter} />, color: colors.appointmentStatus.statusEnter, label: t('APPOINTMENT.STATUS.CONFIRM'), disabled: false },
    ARRIVED: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckTrue} />, color: colors.appointmentStatus.statusArrived, label: t('APPOINTMENT.STATUS.ARRIVED'), disabled: false },
    NOT_YET: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconUserCheckFalse} />, color: colors.appointmentStatus.statusUnArrived, label: t('APPOINTMENT.STATUS.NOT_YET'), disabled: false },
    PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconPendingPayment} />, color: colors.appointmentStatus.statusWaitPayment, label: t('APPOINTMENT.STATUS.PENDING_PAYMENT'), disabled: true },
    PENDING_PAYMENT: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconWaitPayment} />, color: colors.appointmentStatus.statusPayment, label: t('APPOINTMENT.STATUS.PAYMENT'), disabled: true },
    PENDING_SERVICE: { icon: <Image style={{ height: '15px' }} src={icons.appointment.iconCounterCheckIn} />, color: colors.appointmentStatus.statusCounterCheckIn, label: t('APPOINTMENT.STATUS.PENDING_SERVICE'), disabled: true },
    SERVICE: { icon: <Image style={{ height: '17px' }} src={icons.appointment.iconService} />, color: colors.appointmentStatus.statusService, label: t('APPOINTMENT.STATUS.SERVICE'), disabled: true },
    DONE: { icon: <Image style={{ height: '15px' }} src={ThemeIcon.DoneTreatment} />, color: colors.appointmentStatus.statusDone, label: t('APPOINTMENT.STATUS.DONE'), disabled: true }
  }

  const renderAppointStatus = (status: string) => {
    return (
      <BoxStatus bg={statusIcon[status]?.color || ''}>
        {statusIcon[status]?.icon || ''}
        <TextStatus>{statusIcon[status]?.label || ''}</TextStatus>
      </BoxStatus>
    )
  }

  const headCellsLab = checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? [{ id: 'hasLab', disablePadding: false, label: 'LAB ', width: '85px', class: 'text-nowrap' }] : []
  const headCellsXray = checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) ? [{ id: 'hasXray', disablePadding: false, label: t('X-Ray'), width: '85px', class: 'text-nowrap' }] : []
  const headCells = [
    { id: 'no', disablePadding: false, width: '80px', label: t('#') },
    { id: 'branchId', disablePadding: false, label: t('APPOINTMENT.HISTORY.BRANCH'), class: 'text-nowrap', width: '200px !important' },
    { id: 'date', disablePadding: false, label: t('APPOINTMENT.HISTORY.APPOINTMENT_DATE'), class: 'text-nowrap', width: '150px !important' },
    { id: 'timeStart', disablePadding: false, label: t('APPOINTMENT.HISTORY.TIME_START'), class: 'text-nowrap', width: '150px !important' },
    { id: 'durationMinute', disablePadding: false, label: t('APPOINTMENT.HISTORY.APPOINTMENT_DURATION'), align: 'center', class: 'text-nowrap', width: '150px' },
    { id: 'cnNumber', disablePadding: false, label: 'รหัสผู้ป่วย', align: 'center', class: 'text-nowrap', width: '150px', },
    { id: 'branchCnNumber', disablePadding: false, label: 'รหัสผู้ป่วยในสาขา', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: 'snCode', disablePadding: false, label: 'รหัสผู้ป่วยพิเศษ', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: 'patientFullName', disablePadding: false, label: 'ชื่อผู้ป่วย', align: 'left', class: 'text-nowrap', width: '250px' },
    { id: 'phone', disablePadding: false, label: 'เบอร์โทร', align: 'left', class: 'text-nowrap', width: '200px' },
    { id: 'doctorFullname', disablePadding: false, label: t('APPOINTMENT.HISTORY.DOCTOR'), width: '180px' },
    { id: 'appointmentOperatives', disablePadding: false, label: t('HISTORY_TREATMENT.TABLE.SERVICE'), width: '250px' },
    { id: 'statusAppointment', disablePadding: false, label: t('APPOINTMENT.HISTORY.STATUS'), align: 'center', width: '150px !important' },
    { id: 'price', disablePadding: false, label: t('APPOINTMENT.HISTORY.PRICE'), width: '130px !important' },
    { id: 'practice', disablePadding: false, label: t('APPOINTMENT.HISTORY.PRACTICE'), class: 'text-nowrap', width: '200px' },
    { id: 'remark', disablePadding: false, label: t('APPOINTMENT.HISTORY.REMARK'), class: 'text-nowrap', width: '200px' },
    ...headCellsLab,
    ...headCellsXray,
    { id: 'updatedBy', disablePadding: false, label: t('APPOINTMENT.HISTORY.CREATED'), width: '150px !important' },
    { id: 'updatedBy', disablePadding: false, label: t('APPOINTMENT.HISTORY.UPDATED'), width: '150px !important' }
  ]

  const renderData = (objData: any, no: number, exportData: boolean) => {
    const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
    const { appointmentId, branchName, date, timeStart, durationMinute, doctorFullname, service, statusAppointment, price, practice, remark, hasLab, hasXray, createdAt, createdBy, updatedAt, updatedBy } = objData

    const style = { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }

    const objRenderData = {
      key: String(appointmentId),
      id: String(appointmentId),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', width: '80px', style: style, label: no + 1 },
        { option: 'TEXT', align: 'left', style: style, label: branchName || '', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', style: style, label: date ? toBuddhistYear(moment(date), 'DD/MM/YYYY') : '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', style: style, label: `${timeStart.replace(':00', '').replace(':', '.')} ${t('TIME_UNIT')}`, class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', style: style, label: getDurationTextByTimeMinute(durationMinute), class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', style: style, label: objData?.cnNumber || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', style: style, label: objData?.branchCnNumber || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'center', style: style, label: objData?.snCode || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', style: style, label: objData.patientFullname, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', style: style, label: objData.phone, class: 'text-nowrap text-ellipsis' },
        { option: 'TEXT', align: 'left', style: style, label: doctorFullname },
        { option: 'TEXT', align: 'left', style: style, label: service, class: 'appointmentOperatives' },
        { option: 'COMPONENT', align: 'center', component: renderAppointStatus(statusAppointment), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: style, label: numberToCurrencyFormat(price) },
        { option: 'TEXT', align: 'left', style: style, label: practice || '-', class: 'practice hard-break ' },
        { option: 'TEXT', align: 'left', style: style, label: remark || '-', class: 'remark hard-break ' },
        checkPackage(PACKAGE_ADDONS.ADDON_LAB) && { option: 'TEXT', align: 'left', style: style, label: t(`${hasLab}`) },
        checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) && { option: 'TEXT', align: 'left', style: style, label: t(`${hasXray}`) },
        { option: 'UPDATE_BY', align: 'center', style: style, label: { updatedBy: createdBy, updatedAt: createdAt } },
        { option: 'UPDATE_BY', align: 'center', style: style, label: { updatedBy: updatedBy, updatedAt: updatedAt } }
      ]
    }
    if (!snType) {
      objRenderData.columns.splice(7, 1)
    }
    return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
  }

  const renderHeadCell = () => {
    const indexExport = 7
    const newHeadCell = [...headCells]
    if (!snType) {
      return newHeadCell.filter((_, index) => index !== indexExport)
    }
    return headCells
  }

  return (
    <>
      <UseStyled.ResponsiveReportTable className="mt-2" verticalAlign="top">
        <TableCustom
          customScroll
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={renderHeadCell()}
          rowsData={_.map(props.data, (d: any, indexData: number) => {
            return renderData(d, indexData, false)
          })}
          tableFixedWidth
          tableMinWidth={2100}
        />
      </UseStyled.ResponsiveReportTable>

      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.APPOINTMENT`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(renderHeadCell(), (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.label)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(props.dataExport, (d: any, index: number) => {
                      return renderData(d, index, true)
                    })}
                  </tbody>
                </table>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}
