import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, Divider, Typography, Grid } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { UseSnCode } from 'utils/useSnCode'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import Loading from 'component/Loading'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from 'component/Print/print'
import MainButton from 'new-components/buttons/main-button'
import SelectCheckBoxInput from 'new-components/inputs/select_checkbox_input'

/** REPORT */
import IncomeList, { SummaryList, SummaryPayment, SummaryPD, SummaryTx } from './IncomeList'

/** CONSTANTS */
import { getBranch, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { colors } from 'constants/theme'
import { dateTimeToView, dateToView } from 'utils/date.utils'
import { routeName } from 'routes/routes-name'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import ProductsApi from 'api/warehouses/product.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

export default function IncomeOperativeReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const componentRefPDF = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]

  const [loading, setLoading] = useState<boolean>(true)
  const [branches, setBranches] = useState<any[]>([])


  const [filter, setFilter] = useState<{ branchId: any, page: number, pageLimit: number, sortBy: string, sortType: string, rangeDate: string[], productTypeId: number, productId: number }>({ branchId: getBranch(), page: 1, pageLimit: 10, sortBy: 'createAt', sortType: 'ASC', rangeDate: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], productTypeId: 0, productId: 0 });


  const [incomeData, setIncomeData] = useState<any[]>([])
  const [incomeSummeryData, setIncomeSummeryData] = useState<any[]>([])
  const [incomeDataProduct, setIncomeDataProduct] = useState<any[]>([])
  const [incomeDataTX, setIncomeDataTX] = useState<any[]>([])
  const [incomeExport, setIncomeExportData] = useState<any[]>([])
  const [incomeRowCount, setIncomeRowCount] = useState<number>(0)


  const [productType, setProductType] = useState([])
  const [productTypeSelected, setProductTypeSelected] = useState({ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') })

  const [product, setProduct] = useState([])
  const [productSelected, setProductSelected] = useState({ productId: 0, productName: t('REPORT.FILTER.ALL') })

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'INCOME_SIMPLE_DAILY_OPERATIVE') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })
    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  /** TABLE 1 */
  const fetchIncome = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: filter.page,
      pageLimit: filter.pageLimit,
      branchId: filter.branchId,
    }
    if (filter.sortType) condition = { ...condition, sortType: filter.sortType }
    if (filter.sortBy) condition = { ...condition, sortBy: filter.sortBy }

    if (filter.productTypeId > 0) condition = { ...condition, productTypeId: filter.productTypeId }
    if (filter.productId > 0) condition = { ...condition, productId: filter.productId }

    if (filter.rangeDate[0] !== null && filter.rangeDate[1] !== null) condition = { ...condition, dateStart: filter.rangeDate[0], dateEnd: filter.rangeDate[1] }

    ReportApi.reportIncomeSimpleDailyOperative(condition)
      .then(({ headers, data }) => {
        setIncomeData(data)
        setIncomeRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [filter])

  /** TABLE PRODUCT */

  const fetchIncomeProduct = useCallback(() => {
    let condition: FindAllReportInterface = {
      page: filter.page,
      pageLimit: filter.pageLimit,
      branchId: filter.branchId,
    }
    if (filter.sortType) condition = { ...condition, sortType: filter.sortType }
    if (filter.sortBy) condition = { ...condition, sortBy: filter.sortBy }

    if (filter.productTypeId > 0) condition = { ...condition, productTypeId: filter.productTypeId }
    if (filter.productId > 0) condition = { ...condition, productId: filter.productId }

    if (filter.rangeDate[0] !== null && filter.rangeDate[1] !== null) condition = { ...condition, dateStart: filter.rangeDate[0], dateEnd: filter.rangeDate[1] }

    ReportApi.reportIncomeSimpleDailySummery(condition)
      .then(({ headers, data }) => {
        const d = _.groupBy(data, 'refItemType')
        setIncomeDataProduct(d['ORDER_PRODUCT'])
        setIncomeDataTX(d['ORDER_TREATMENT'])
        setIncomeSummeryData(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [filter])


  const onChangeIncomeSort = (sortByVal: string, sortTypeVal: any) => {
    setFilter((val) => ({ ...val, sortType: sortTypeVal, sortBy: sortByVal }))
  }

  const onChangeIncomeRowPerPage = (limit: number) => {
    setFilter((val) => ({ ...val, pageLimit: limit }))

  }

  const onChangeIncomePage = (page: number) => {
    setFilter((val) => ({ ...val, page: page }))
  }

  const loadIncomeExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId: filter.branchId,
      export: 1
    }

    if (filter.rangeDate[0] !== null && filter.rangeDate[1] !== null) condition = { ...condition, dateStart: filter.rangeDate[0], dateEnd: filter.rangeDate[1] }
    if (filter.productTypeId > 0) condition = { ...condition, productTypeId: filter.productTypeId }
    if (filter.productId > 0) condition = { ...condition, productId: filter.productId }

    ReportApi.reportIncomeSimpleDailyOperative(condition)
      .then(({ data }) => setIncomeExportData(data))
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [filter])

  const loadProductType = useCallback((search = '') => {
    ProductTypesApi.findAll({ status: 'ACTIVE', pageLimit: 50, search: search })
      .then(({ data }) => setProductType(data))
      .catch((e) => {
        return
      })
  }, [])

  useEffect(() => {
    ProductsApi.findByType({ status: 'ACTIVE', pageLimit: 50, itemTypeId: productTypeSelected.productTypeId })
      .then(({ data }) => setProduct(data))
      .catch((e) => {
        return
      })
  }, [productTypeSelected, productSelected])


  /** UseEffect */
  useEffect(() => {
    fetchIncome()
  }, [fetchIncome])

  useEffect(() => {
    fetchIncomeProduct()
  }, [fetchIncomeProduct])


  useEffect(() => {
    loadIncomeExport()
  }, [loadIncomeExport,])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)} ${filter.rangeDate ? `${filter.rangeDate[0]} - ${filter.rangeDate[1]}` : ''}`,
    sheetName: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)}`
  })

  const setHeaderSearch = [
    {
      name: `${t('REPORT.FILTER.BRANCH')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${_.get(_.find(branches, { branchId: filter.branchId }), 'branchName' || 'branchNameEn')}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.RECEIPT_STATUS')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },

    {
      name: `${t('REPORT.FILTER.RECEIPT_TYPE')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },

    {
      name: `${t('REPORT.FILTER.START')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${filter.rangeDate[0] ? toBuddhistYear(moment(filter.rangeDate[0]), 'DD/MM/YYYY') : '-'}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.DATE_END')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${filter.rangeDate[1] ? toBuddhistYear(moment(filter.rangeDate[1]), 'DD/MM/YYYY') : '-'}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: ``,
      style: {}
    }
  ]

  const onLoadData = () => {
    fetchIncome()
    fetchIncomeProduct()
    loadIncomeExport()
  }

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY_OPERATIVE`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className="mx-0">
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => setFilter((val) => ({ ...val, branchId: e.target.value, page: 1, pageLimit: 10 }))}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: filter.branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={filter.branchId}
                options={_.map(branches, (b: BranchInterface) => {
                  return (
                    <MenuItem key={b.branchId} value={b.branchId}>
                      {b.branchName || b.branchNameEn}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>

            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <InputSecondNewRangePicker
                inputHeight={32}
                value={filter.rangeDate || emptyRangePicker}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setFilter((val) => ({ ...val, rangeDate: emptyRangePicker, page: 1, }))
                  else setFilter((old) => ({ ...old, rangeDate: [moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')], page: 1, }))
                }}
                label={''}
                allowClear
                onClear={() => setFilter((val) => ({ ...val, rangeDate: emptyRangePicker, page: 1, }))}
              />
            </Col>

            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <Box position={'relative'} sx={{ '.MuiInputBase-root': { height: '32px' } }}>


                <AutocompleteSelect
                  key={'operativeType'}
                  labelId="select-operative-type"
                  inputLabel={t('ประเภทผลิตภัณฑ์')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={[{ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') }, ...productType]}
                  getOptionLabel={(option: any) => option.productTypeName}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option.productTypeName}
                    </Box>
                  )}
                  onchange={(e, value: any) => {
                    if (value) {
                      setFilter((val) => ({ ...val, productTypeId: value.productTypeId || '' }))
                      setProductTypeSelected({ productTypeId: value.productTypeId, productTypeName: value.productTypeName })
                      setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                    } else {
                      setFilter((val) => ({ ...val, productTypeId: 0 }))
                      setProductTypeSelected({ productTypeId: 0, productTypeName: t('REPORT.FILTER.ALL') })
                    }
                  }}
                  inputProps={{
                    onChange: (e: any) => {
                      if (productType.length === 50) {
                        setFilter((val) => ({ ...val, productTypeId: 0 }))
                        setProductType([])
                        loadProductType(e.target.value)
                      }
                    }
                  }}
                  onOpen={(e: any) => {
                    setFilter((val) => ({ ...val, productTypeId: 0 }))
                    setProductTypeSelected({ productTypeId: 0, productTypeName: '' })
                    loadProductType()
                  }}
                  value={productTypeSelected}
                  disableClearable
                />
              </Box>
            </Col>
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <Box position={'relative'} sx={{ '.MuiInputBase-root': { height: '32px' } }}>
                <AutocompleteSelect
                  key={'operative'}
                  labelId="select-operative"
                  inputLabel={t('รายการผลิตภัณฑ์')}
                  noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                  options={[{ productId: 0, productName: t('REPORT.FILTER.ALL') }, ...product]}
                  getOptionLabel={(option: any) => option.productName}
                  renderOption={(props, option: any) => (
                    <Box key={option} component="li" {...props}>
                      {option.productName}
                    </Box>
                  )}
                  onchange={(e, value: any) => {
                    if (value) {
                      setFilter((val) => ({ ...val, productId: value.productId }))
                      setProductSelected({ productId: value.productId, productName: value.productName })
                    } else {
                      setFilter((val) => ({ ...val, productId: 0 }))
                      setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                    }
                  }}
                  inputProps={{
                    onChange: (e: any) => {
                      if (product.length === 50) {
                        setFilter((val) => ({ ...val, productId: 0 }))

                        setProduct([])
                      }
                    }
                  }}
                  onOpen={(e: any) => {
                    setFilter((val) => ({ ...val, productId: 0 }))
                    setProductSelected({ productId: 0, productName: t('REPORT.FILTER.ALL') })
                  }}
                  value={productSelected}
                  disableClearable
                />
              </Box>
            </Col>


            <Col md={4} lg={3} xl={2} className={'d-flex justify-content-end ml-xl-auto pt-2 px-1 gap-2'}>
              <Box maxWidth={'100px'} width={'100%'} sx={{ button: { width: '100%' } }}>
                <MainButton
                  title={t('INPUT.SEARCH')}
                  onClick={() => {
                    setFilter((val) => ({ ...val, page: 1 }))
                    onLoadData()
                  }}
                  type={'primary'}
                  variant={'contained'}
                />
              </Box>
              <Box>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRefPDF.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm;  } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Box>

            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <IncomeList
            page={filter.page}
            pageLimit={filter.pageLimit}
            rowCount={incomeRowCount}
            sortBy={filter.sortBy}
            sortType={filter.sortType}
            data={incomeData}
            dataExport={incomeExport}
            onRequestSort={onChangeIncomeSort}
            handleChangeRowsPerPage={onChangeIncomeRowPerPage}
            handleChangePage={onChangeIncomePage}
          />
        </Box>
        <Grid container gridColumn={2} spacing={2}>
          <Grid item >
            <SummaryPayment data={incomeExport} />
          </Grid>
          <Grid item >
            <SummaryList data={incomeExport} />
          </Grid>
          <Grid item >
            <SummaryTx data={incomeExport} />
          </Grid>
          <Grid item >
            <SummaryPD data={incomeDataProduct} />
          </Grid>
        </Grid>
      </Card.Body>
      <Loading show={loading} type="fullLoading" />

      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(setHeaderSearch, (val: any) => {
                        return <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500, border: 'none', ...val.style }}>{t(val.name)}</td>
                      })}
                    </tr>
                  </thead>
                </table>
              </UseStyled.Styles>
              <br></br>
              <SummaryPayment data={incomeExport} />
              <br></br>
              <SummaryList data={incomeExport} />
              <br></br>
              <SummaryTx data={incomeExport} />
              <br></br>
              <SummaryPD data={incomeDataProduct} />
            </>
          }
        />
      </div>

      <div className="print-show" ref={componentRefPDF}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY_OPERATIVE`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <div style={{ fontSize: '14px' }}>
                  <span>{t('REPORT.FILTER.BRANCH')}: </span>
                  <span style={{ marginRight: '13px' }}>{_.get(_.find(branches, { branchId: filter.branchId }), 'branchName' || 'branchNameEn')}</span>
                  <span>{t('REPORT.FILTER.DAY')} </span>
                  <span style={{ marginRight: '5px' }}>{filter.rangeDate[0] ? toBuddhistYear(moment(filter.rangeDate[0]), 'DD/MM/YYYY') : '-'}</span>
                  <span>{t('REPORT.FILTER.TO')} </span>
                  <span style={{ marginRight: '5px' }}>{filter.rangeDate[1] ? toBuddhistYear(moment(filter.rangeDate[1]), 'DD/MM/YYYY') : '-'}</span>
                  <span>{t('ประเภทผลิตภัณฑ์')}: </span>
                  <span style={{ marginRight: '13px' }}>{productTypeSelected ? productTypeSelected.productTypeName : t('REPORT.FILTER.ALL')}</span>
                  <span>{t('รายการผลิตภัณฑ์')}: </span>
                  <span style={{ marginRight: '13px' }}>{productSelected ? productSelected.productName : t('REPORT.FILTER.ALL')}</span>
                </div>
              </UseStyled.Styles>
            </>
          }
        />
      </div>
    </Card>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}




export function ExportComponant({ incomeExport, incomeTypeExport, incomeOverdueExport }: any) {
  const { t } = useTranslation()
  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])
  // REPORT.TABLE.INCOME_DAY.PATIENT_CN_NUMBER
  const headCellIncome = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.DATE_TIME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'APPLICATION.TABLE.CELL.CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_AGE', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_STATUS', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_CHANNELS', align: 'left', class: 'text-wrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_PAY', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_OVERDUE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK', align: 'center', class: 'text-wrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CREATED_BY', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK_CANCEL', align: 'center', style: { width: '20px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', important: true } },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CANCEL_BY', align: 'center', class: 'text-nowrap' }
  ]
  if (!snType) {
    headCellIncome.splice(4, 1)
  }
  const headCellIncomeType = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const headCellIncomeOverdue = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const renderIncomeOverdueExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: data.paymentChannelName || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || '-' }
        ]}
      />
    )
  }

  const renderIncomeTypeExport = (data: any, no: number) => {
    const num = no + 1
    const paymentChanel = data.paymentChannelName === 'Voucher' ? t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.VOUCHER') : data.paymentChannelName
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'left', label: `${paymentChanel} ${data.cardTypeName !== null ? `(${data.cardTypeName})` : ``}` || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || '-' }
          ]}
        />
      </>
    )
  }

  const renderIncomeExport = (data: any, no: number) => {
    const num = no + 1
    const columns = [
      { option: 'TEXT', align: 'left', label: data.billNo, class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: dateTimeToView(data.paidAt), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.snCode || '', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.patientFullname, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.age, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: t(`REPORT.BILL_STATUS.${data.billStatus}`), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.paymentChannel, class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.overdue), class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.remark, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.createdBy, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.cancelRemark, class: 'text-wrap' },
      { option: 'TEXT', align: 'center', label: data.cancelBy, class: 'text-nowrap' }
    ]
    if (!snType) {
      columns.splice(4, 1)
    }
    return (
      <>
        <TableRowExport key={num.toString()} id={no.toString()} obj={data} columns={columns} />
      </>
    )
  }

  return (
    <>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncome, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {_.map(incomeExport, (d: any, index: number) => {
              return renderIncomeExport(d, index)
            })}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-5">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncomeType, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {[
              _.map(incomeTypeExport, (d: any, index: number) => {
                return renderIncomeTypeExport(d, index)
              })
            ]}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncomeOverdue, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {[
              _.map(incomeOverdueExport, (d: any, index: number) => {
                return renderIncomeOverdueExport(d, index)
              })
            ]}
          </tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}
