import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography, Divider, IconButton, MenuItem, Tooltip } from '@mui/material';
import moment from 'moment'
import _, { forEach } from 'lodash'
import AddBoxIcon from '@mui/icons-material/AddBox'


/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import ClinicApi from 'api/master/clinic.api'
import MedicinesApi from 'api/warehouses/medicine.api'
import ProductInstructionsApi from 'api/setting/default-products/product-instruction.api'
import ProductUnitsApi from 'api/setting/default-products/product-unit.api'
import ProductUsesApi from 'api/setting/default-products/product-use.api'

/** CONSTANT */
import { colors, templateImage } from 'constants/theme'
import { icons, onImgError } from 'constants/images';
import { treatment, setTreatmentByKey, TreatmentStateInterface, ProductStickerType, StickerType, newStickerProduct, setNewStickerProduct, loadPromotionTreatments, promotionsTreatments, setSummeryTreatments, setProductsState } from 'app/slice/treatment.slice'
import { numberFormat, toBuddhistYear, getClinicInfo, getBranch, getCrm } from 'utils/app.utils';

/** COMPONENT */
import { ImageType } from 'features/treatments/MainMenu/OPD/Med'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextField from 'component/Input/InputTextField'
import InputTextarea from 'component/Input/InputTextarea'
import FunctionPrint from 'component/Print/print'
import PdfMedSticker from 'features/pdf/pdf-med-sticker'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { DoctorsType } from 'features/treatments/treatmentsIndex';
import { doctorHasOverDue } from 'features/treatments/SummaryTreatment/SummaryTreatment';

/** STYLED */
import * as UseStyled from 'component/Treatments/style/popupManageProdStyled'
import TreatmentProductApi from 'api/dentists/treatmentProduct.api'
import ProductSymptomApi from 'api/setting/default-products/product-symptom';
import { IconAddBox } from 'features/treatments/MainMenu/OPD/style/UseStyle';
import { showModal } from 'app/slice/modal.slice';
import { PopupManageProductAddForm } from './PopupManageProductAddForm';
import { notiError, notiSuccess } from 'component/notifications/notifications';
import BasicSelect from 'component/Select/BasicSelect';
import { CURRENCY_UNIT, ModalVoucherSelectLot } from './PopupManageTreatmentCourse';
import InputTextSearch from 'component/Input/InputTextSearch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import MainButton from 'new-components/buttons/main-button';
import InfoIcon from '@mui/icons-material/Info';
import { ModalSelectPromotionExtra, onPromotionSave } from 'features/manage-crm/promotion/promotion-component';
import { selectMe } from 'app/slice/user.slice'
import Loading from 'component/Loading';
import TextInput from 'new-components/inputs/text-input';
import { ProductBarCode, ProductBox } from 'features/treatments/MainMenu/OPD/style/ProductUseStyle';
import IconCheck from 'assets/icon/iconCheck';
import { selectIndex, setSelected } from 'app/slice/select.slice';
import { AlertProductOutOfStockType } from './AlertProductOutOfStock';
import { setTimeout } from 'timers';

import IconClose from '@mui/icons-material/CloseRounded';
import PromotionsApi from 'api/promotion/promotion.api'
import { ApprovalStatus } from 'api/approval/approval.api'
import VoucherApi, { VoucherFindByCodeProps } from 'api/voucher/voucher.api'

interface PopupManageProductProps {
    page: 'Treatment' | 'Payment' | 'RetrospectiveOrder'
    group: 'MEDICINE' | 'PRODUCT' | 'MATERIAL'
    type: 'thumbnail' | 'summaryTreatment'
    patientName: string
    patientPhone?: string
    item: ProductStickerType
    index?: number
    patientCaseId: number
    hasChangeProductPrice?: 'PERMISS' | 'UNPERMISS'
    hasChangeProductDiscountAdditional?: 'PERMISS' | 'UNPERMISS'
    onSubmit?: () => void
    onCancel?: () => void
    patientId?: number
    select?: boolean
    userId?: number
    openScanBarcode?: boolean
}

export const initialProductState: ProductStickerType = {
    sticker: ``,
    tempOrderProductId: 0,
    doctorId: 0,
    qty: 0,
    price: 0,
    discount: 0,
    discountUnit: "BAHT" || "PERCENT",
    additional: 0,
    additionalUnit: "BAHT" || "PERCENT",
    total: 0,
    itemId: 0
}

interface ProductObjType {
    id: number
    name: string
    nameEn?: string
}

const initialErrorMessageState = {
    EMPTY_QTY_ORDER: "",
    EMPTY_PRICE: "",
    EMPTY_DOCTOR: ""
}

const initProductOutOfStock = {
    name: '',
    code: '',
    qty: 0,
    unit: ''
}

export type PromotionUsageProps = {
    promotionBranchId?: number
    promotionId?: number
    promotionType?: 'DISCOUNT' | 'BUY' | 'EXTRA'
    isMain?: "1" | "0"
    groupNumber?: number

}

export default function PopupManageProduct(props: PopupManageProductProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const treatments: TreatmentStateInterface = useSelector(treatment)
    const branch = getClinicInfo();
    const user = useSelector(selectMe)
    const componentRef = useRef<HTMLDivElement>(null)
    const newStickerProducts: ProductStickerType[] = useSelector(newStickerProduct)
    const units: CURRENCY_UNIT[] = [CURRENCY_UNIT.PERCENT, CURRENCY_UNIT.BAHT]
    const promotions = useSelector(promotionsTreatments)
    const [loadPromotionFrist, setLoadPromotionFrist] = useState<boolean>(true)
    const [promotionCurrent, setPromotionCurrent] = useState<any>([])
    const [promotionBranchMain, setPropmotionBranchMain] = useState<any>({})
    const [promotionLoadClear, setPromotionLoadClear] = useState<boolean>(false)
    const [promotionDelId, setPromotionDelId] = useState<number>(0)
    const [promotionProduct, setPromotionProduct] = useState<PromotionUsageProps[]>([])
    const [promotionProductCurrent, setPromotionProductCurrent] = useState<PromotionUsageProps[]>([])

    const [popPromotionExtra, setPopPromotionExtra] = useState<boolean>(false)
    const [promotionExtraValue, setPromotionExtraValue] = useState<any>({})
    const [promotionExtraBuy, setPromotionExtraBuy] = useState<any>([])
    const [promotionExtraNew, setPromotionExtraNew] = useState<any>([])

    const [promotionBuy, setPromotionBuy] = useState<any>([])
    const [promotionExtra, setPromotionExtra] = useState<any>([])
    const [disabledSave, setDisabledSave] = useState<boolean>(false)

    const [voucherLoadClear, setVoucherLoadClear] = useState<boolean>(false)
    const [voucherProduct, setVoucherProduct] = useState<any>({})
    const [vouchers, setVoucher] = useState<any>({})
    const [popVoucherSelectLot, setPopVoucherSelectLot] = useState<boolean>(false)
    const [dataPopVoucherSelectLot, setDataPopVoucherSelectLot] = useState<any>({})
    const [searchVoucher, setSearchVoucher] = useState<string>("")
    const [errorSearchVoucher, setErrorSearchVoucher] = useState<string>("")

    // data
    const [product, setProduct] = useState<ProductStickerType>(initialProductState);
    const [stickerProduct, setStickerProduct] = useState<StickerType>({});
    const [errorMessage, setErrorMessage] = useState(initialErrorMessageState);
    const [isShowAddProductChoice, setIsShowAddProductChoice] = useState<boolean>(false)
    const [typeAdd, setTypeAdd] = useState<'PRODUCT_SYMPTOM' | 'PRODUCT_USES' | 'PRODUCT_UNITS' | 'PRODUCT_INSTRUCTION'>('PRODUCT_INSTRUCTION')
    const [items, setItems] = useState<any>()
    const [medicineInformationId, setMedicineInformationId] = useState<any>()

    // default data
    const [doctors, setDoctors] = useState<DoctorsType[]>([]);
    const [productInstructions, setProductInstructions] = useState<ProductObjType[]>([]);
    const [productUnits, setProductUnits] = useState<ProductObjType[]>([]);
    const [productUses, setProductUses] = useState<ProductObjType[]>([]);
    const [productSymptom, setProductSymptom] = useState<ProductObjType[]>([]);

    // loading
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [maxLength, setMaxLength] = useState<number>(0);
    const [textLength, setTextLength] = useState<number>(0)
    const [errorMaxLength, setErrorMaxLength] = useState<boolean>(false)
    const [popupProductOutOfStock, setPopupProductOutOfStock] = useState<AlertProductOutOfStockType>(initProductOutOfStock)
    const previewMedRef = useRef<any>(null)
    const selectedIndex = useSelector((state: any) => state.select.index);
    const [scanBarcode, setScanBarcode] = useState('')
    const [itemsBarcode, setItemsBarcode] = useState<any>([])
    const [stickerBarcode, setStickerBarcode] = useState<any>([])
    const [isActive, setIsActive] = useState(false);


    const loadData = async () => {
        const newItem: any = props?.item
        if (newItem.itemId !== 0 && !props.openScanBarcode) {
            try {
                setLoading(true)
                setProduct(props.item)
                setItemsBarcode([props.item])
                setPromotionProduct(newItem?.promotion)
                setVoucherProduct(newItem?.voucher)
                setSearchVoucher(newItem?.voucher?.voucherCode)
                setPromotionProductCurrent(newItem?.promotion)
                if (newItem.sticker && props.group !== 'MEDICINE') {
                    const sticker = JSON.parse(newItem.sticker)
                    setStickerProduct(sticker)
                    handleChangeStickerProductDetail(sticker)
                } else {
                    const stickerPd: StickerType = createBaseSticker(newItem, JSON.parse(newItem.sticker))
                    if (props.group === 'MEDICINE' && newItem.sticker) {
                        await handleSticker(newItem.itemId, JSON.parse(newItem.sticker))
                    } else if (props.group === 'MEDICINE' && !newItem.sticker) {
                        const sticker = await handleSticker(newItem.itemId, stickerPd)
                        handleChangeStickerProductDetail(sticker)
                    } else {
                        setStickerProduct(stickerPd)
                        handleChangeStickerProductDetail(stickerPd)
                    }
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            } finally {
                setLoading(false);
            }
        } else {
            const condition: any = { page: 1, pageLimit: 100, branchId: getBranch(), userId: props.userId, group: props.group };
            if (scanBarcode.length === 13) {
                try {

                    const resp = await TreatmentProductApi.findAllProduct({ ...condition, search: scanBarcode });
                    if (resp.data.length === 1) {
                        const resInfo = (props.group === 'MEDICINE' && resp.data[0]?.itemId) ? await TreatmentProductApi.getMedicineInformation(resp.data[0]?.itemId) : null
                        const newSticker = (resInfo?.data && resInfo?.data?.sticker) ? JSON.parse(resInfo?.data?.sticker) : {}
                        const newData = (resInfo?.data && resInfo?.data?.productId)
                            ?
                            { ...resp.data[0], oldSticker: newSticker, medicineInformationId: resInfo?.data?.medicineInformationId || 0 }
                            :
                            resp.data[0]
                        await handleBarcodeScan(newData);
                    } else {
                        notiError(t('ไม่พบสินค้านี้'))
                        setScanBarcode("")
                    }
                } catch (error) {
                    console.error('Error fetching product:', error);
                } finally {
                    setLoading(false);
                }
            } return
        }
        setLoading(false)
    }

    const handleSticker = async (id: number, sticker: any) => {
        if (id) {
            const res = props.group === 'MEDICINE' ? await TreatmentProductApi.getMedicineInformation(id) : ''
            if (res.status === 200 && res.data) {
                const rawData = res.data.sticker
                const data = JSON.parse(rawData)
                const objSticker: StickerType = {
                    ...sticker,
                    dosage: data.dosage,
                    ...handleProductDetail(sticker, data),
                    logo: branch?.branchLogo,
                    clinicName: branch?.clinicAndBranchName,
                    branchAddress: branch?.branchFullAddress,
                    branchTel: branch?.branchPhone,
                    patientFullName: props?.patientName
                }
                let newData: any = {}
                if ((!objSticker?.image || !objSticker?.stock)) {
                    const item: any = props.item
                    newData = {
                        ...stickerProduct, ...objSticker,
                        image: item?.image || {},
                        stock: item?.stock || "0"
                    }
                } else {
                    newData = { ...stickerProduct, ...objSticker }
                }
                setStickerProduct({ ...newData, medicineInformationId: res.data.medicineInformationId })
                handleChangeStickerProductDetail({ ...newData, medicineInformationId: res.data.medicineInformationId })
                setMedicineInformationId(res.data.medicineInformationId)
                return newData
            } else if (props.group === 'MEDICINE' && props.type === 'thumbnail' && id) {
                await MedicinesApi.findById(id)
                    .then(({ data }: any) => {
                        const objSticker: StickerType = {
                            ...sticker,
                            productInstructionId: data?.productInstruction?.productInstructionId,
                            productInstructionName: data?.productInstruction?.productInstructionName,
                            productUnitId: data?.productUnit?.productUnitId,
                            productUnitName: data?.productUnit?.productUnitName,
                            productUnitNameEn: data?.productUnit?.productUnitNameEn,
                            productUseId: data?.productUse?.productUseId,
                            productUseDetail: data?.productUse?.productUseDetail,
                            logo: branch?.branchLogo,
                            clinicName: branch?.clinicAndBranchName,
                            branchAddress: branch?.branchFullAddress,
                            branchTel: branch?.branchPhone,
                            patientFullName: props?.patientName
                        }
                        setStickerProduct({ ...stickerProduct, ...objSticker })
                        handleChangeStickerProductDetail({ ...stickerProduct, ...objSticker })
                        return { ...stickerProduct, ...objSticker }
                    })
                    .catch(() => { return })
                    .finally(() => setLoading(false))
            } else {
                setStickerProduct(sticker)
                handleChangeStickerProductDetail(sticker)
                setLoading(false)
                return sticker
            }
        }
        setLoading(false)
    }

    const handleProductDetail = (sticker: any, data: any) => {
        const newData = {
            productInstructionId: sticker?.productInstructionId || data.productInstructionId,
            productInstructionName: sticker?.productInstructionName || data.productInstructionName,
            productUnitId: sticker?.productUnitId || data.productUnitId,
            productUnitName: sticker?.productUnitName || data.productUnitName,
            productUseId: sticker?.productUseId || data.productUseId,
            productUseDetail: sticker?.productUseDetail || data.productUseDetail,
            productSymptomId: sticker?.productSymptomId || data.productSymptomId,
            productSymptomDetail: sticker?.productSymptomDetail || data.productSymptomDetail,
        }
        return newData
    }

    const loadDefaultProductInstructions = (search?: string) => {
        ProductInstructionsApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductInstructions: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductInstructions = [...newProductInstructions, { id: i.productInstructionId, name: i.productInstructionName || i.productInstructionNameEn }]
                })
                setProductInstructions(newProductInstructions)
            })
            .catch(() => { return })
            .finally(() => {
                setDataLoading(false)
            })
    }

    const loadDefaultProductUnits = (search?: string) => {
        ProductUnitsApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductUnits: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductUnits = [...newProductUnits, { id: i.productUnitId, name: i.productUnitName, nameEn: i.productUnitNameEn }]
                })
                setProductUnits(newProductUnits)
            })
            .catch(() => { return })
            .finally(() => {
                setDataLoading(false)
            })
    }

    const loadDefaultProductUses = (search?: string) => {
        ProductUsesApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductUses: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductUses = [...newProductUses, { id: i.productUseId, name: i.productUseDetail }]
                })
                setProductUses(newProductUses)
            })
            .catch(() => { return })
            .finally(() => {
                setDataLoading(false)
            })
    }
    const loadDefaultProductSymtom = (search?: string) => {
        ProductSymptomApi
            .findAll({ status: 'ACTIVE', group: 'MEDICINE', pageLimit: 10, search: search || '' })
            .then(({ data }) => {
                let newProductSymptom: ProductObjType[] = []
                _.forEach(data, (i) => {
                    newProductSymptom = [...newProductSymptom, { id: i.productSymptomId, name: i.detail }]
                })
                setProductSymptom(newProductSymptom)
            })
            .catch(() => { return })
            .finally(() => {
                setDataLoading(false)
            })
    }

    const loadDefaultDoctor = () => {
        ClinicApi.findAllDoctors()
            .then(({ data }) => setDoctors(data))
            .catch(() => { return })
    }

    const renderImage = (image: ImageType) => {
        if (image?.filePath && image?.fileName) return `${fileUrl}/${image.filePath}/${image.fileName}`
        else if (props.group === 'MATERIAL') return icons.matDefault
        else return icons.medDefault
    }


    const handleChangeProductDetail = (name: string, val: number | string) => {
        let value = val
        const elementNameNumber = ['doctorId', "discountUnit", "additionalUnit"]
        if (!elementNameNumber.includes(name)) {
            const valueNumber = !value ? 0 : String(value).replace(/[^0-9.]/g, '')
            value = Number(valueNumber)
            setVoucherProduct({})
            setSearchVoucher("")
        }
        setProduct({ ...product, [name]: value })
        if (!elementNameNumber.includes(name)) {
            setVoucherProduct({})
            setSearchVoucher("")
            const updatedItemsBarcode = [...itemsBarcode];
            updatedItemsBarcode[selectedIndex] = { ...updatedItemsBarcode[selectedIndex], [name]: value }
            const promotionDel = updatedItemsBarcode[selectedIndex]?.promotion?.length ? updatedItemsBarcode[selectedIndex].promotion.map((item: any) => item?.promotionBranchId) : []
            const newItemBarcode = updatedItemsBarcode?.map((item: any) => {
                if (item?.promotion?.length) {
                    return { ...item, promotion: [...item?.promotion?.filter((info: any) => !promotionDel.includes(info?.promotionBranchId))] }
                } else {
                    return item
                }
            })
            onLoadPromotionTreatments(newItemBarcode)
            setItemsBarcode(newItemBarcode)

            if (name === 'qty') {
                setPromotionCurrent([]);
                setPropmotionBranchMain({});
                // setPromotionDelId(0);
                setPromotionProduct([]);
                setPromotionProductCurrent([]);
                setPromotionExtraValue({});
                setPromotionExtraBuy([]);
                setPromotionExtraNew([]);
                setPromotionBuy([]);
                setPromotionExtra([]);
            }

        } else {
            const updatedItemsBarcode = [...itemsBarcode];
            updatedItemsBarcode[selectedIndex] = {
                ...updatedItemsBarcode[selectedIndex],
                [name]: value
            }
            setItemsBarcode(updatedItemsBarcode);
        }

        // const index = _.findIndex(itemsBarcode, { itemId: product.itemId });
        // const hasItem = _.some(itemsBarcode, { itemId: product.itemId });
        // if (!hasItem) {
        //     setItemsBarcode([...itemsBarcode, { ...product, [name]: value }]);
        // } else {
        //     if (index !== -1 && itemsBarcode.length > 0) {
        //         itemsBarcode[index] = { ...itemsBarcode[index], ...{ ...product, [name]: value } }
        //     }
        // }

        setErrorMessage(initialErrorMessageState);
    };

    const handleChangeStickerProductDetail = (sticker: any) => {
        const index = _.findIndex(stickerBarcode, { itemId: sticker.itemId });
        const hasSticker = _.some(stickerBarcode, { itemId: sticker.itemId });
        if (!hasSticker) {
            setStickerBarcode([...stickerBarcode, sticker]);
        } else {
            if (index !== -1 && stickerBarcode.length > 0) {
                stickerBarcode[index] = { ...stickerBarcode[index], ...sticker }
            }
        }
    };


    const onRemember = async () => {
        const newValue: any = {
            sticker: JSON.stringify(stickerProduct),
            qty: product.qty,
            itemId: product.itemId
        }

        const newId = stickerBarcode.find((item: any) => item?.itemId === newValue?.itemId)
        if (newId?.medicineInformationId) {
            const res = await TreatmentProductApi.editMedicineInformation(newId?.medicineInformationId, newValue)
            if (res.status === 200) {
                notiSuccess(t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.REMEM_SUCCESS'))
            }
        }
        else {
            const res = await TreatmentProductApi.addMedicineInformation(newValue)
            if (res.status === 201) {
                notiSuccess(t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.SAVE_SUCCESS'))
            }
        }
    }

    const handlePromotionDiscount = (pd: any) => {
        const dataPromotion = pd?.promotionBranchMain
        if (dataPromotion?.type === "DISCOUNT") {
            if (dataPromotion?.promotionPriceType === "DISCOUNT") {
                const newData = {
                    promotionDiscount: dataPromotion?.promotionPrice,
                    promotionDiscountUnit: dataPromotion?.promotionPriceUnit === CURRENCY_UNIT.PERCENT ? dataPromotion?.promotionPriceUnit : CURRENCY_UNIT.BAHT
                }
                return newData
            } else {
                const newData = {
                    promotionDiscount: cal_sum(true, pd) - dataPromotion?.promotionPrice,
                    promotionDiscountUnit: dataPromotion?.promotionPriceUnit === CURRENCY_UNIT.PERCENT ? dataPromotion?.promotionPriceUnit : CURRENCY_UNIT.BAHT
                }
                return newData
            }
        } else {
            return {
                promotionDiscount: 0,
                promotionDiscountUnit: CURRENCY_UNIT.BAHT
            }
        }
    }

    const handleVoucherDiscount = (pd: any) => {
        const dataVoucher = pd?.voucher
        const check = dataVoucher?.voucherId
        const newVoucherDiscount = dataVoucher?.voucherPriceType === "DISCOUNT" ? Number(dataVoucher?.voucherPrice || 0) : cal_sum(true, pd) - Number(dataVoucher?.voucherPrice || 0)
        return {
            voucher: dataVoucher || {},
            voucherListId: check ? (Number(dataVoucher?.voucherListId || 0)) : 0,
            voucherDiscount: check ? newVoucherDiscount : 0,
            voucherDiscountUnit: check ? (dataVoucher?.voucherPriceUnit === "BATH" || dataVoucher?.voucherPriceUnit === "BAHT") ? CURRENCY_UNIT.BAHT : CURRENCY_UNIT.PERCENT : CURRENCY_UNIT.BAHT,
        }
    }

    const onCheckPromotionCurrent = (data: any) => {
        const promotionCurrentId = promotionProductCurrent?.find((item: any) => item?.isMain === '1')?.promotionBranchId
        const promotionId = promotionProduct?.find((item: any) => item?.isMain === '1')?.promotionBranchId
        if (promotionCurrentId && promotionCurrentId !== promotionId) {
            const newData: any[] = []
            data.forEach((item: any) => {
                if (item?.promotion?.length && item?.promotion?.some((info: any) => info?.promotionBranchId === promotionCurrentId)) {
                    if (item?.promotion?.some((info: any) => info?.promotionType !== "EXTRA")) {
                        return newData.push({ ...item, promotion: item?.promotion?.filter((i: any) => i?.promotionBranchId !== promotionCurrentId) })
                    }
                } else {
                    return newData.push(item)
                }
            })
            return newData
        } else {
            return data
        }
    }

    const handleDataSubmit = async () => {
        let newTreatmentSum: any[] = handleSetTreatmentAll();
        for (const i in itemsBarcode) {
            const barcodeItem = itemsBarcode[i];

            if (barcodeItem.qty > (stickerBarcode[i].stock || 0) || barcodeItem.discount > (barcodeItem.price * barcodeItem.qty)) {
                deleteItem(Number(i));
                continue;
            } else if (barcodeItem.price.toString() === '' || !barcodeItem.qty) {
                setErrorMessage({
                    EMPTY_QTY_ORDER: !barcodeItem.qty ? t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.EMPTY_QTY_ORDER') : '',
                    EMPTY_PRICE: barcodeItem.price.toString() === '' ? t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.EMPTY_PRICE') : '',
                    EMPTY_DOCTOR: ''
                });
                continue;
            } else {
                const isReload = props.openScanBarcode || promotionLoadClear || voucherLoadClear;
                const total = cal_sum(false, barcodeItem);

                let newTemId = 0
                if (!barcodeItem?.tempOrderProductId && props.page !== "RetrospectiveOrder") {
                    await TreatmentProductApi.findStickerById(props.patientCaseId, barcodeItem.itemId, { isCreate: '1' }).then(({ data }) => {
                        newTemId = Math.max(...data.map((item: any) => item.tempOrderProductId))
                    });
                }
                const newProduct = {
                    ...barcodeItem,
                    tempOrderProductId: newTemId || barcodeItem?.tempOrderProductId,
                    stock: stickerBarcode[i].stock,
                    total: total,
                    sticker: JSON.stringify(stickerBarcode[i]),
                    fullPrice: cal_sum(true, barcodeItem),
                    isReload: isReload,
                    promotion: barcodeItem?.promotion,
                    ...handlePromotionDiscount(barcodeItem),
                    ...handleVoucherDiscount(barcodeItem)
                };

                if (props.page !== "RetrospectiveOrder") {
                    onSaveSticker(total, newProduct, stickerBarcode[i]);
                }

                const { dataTreatmentsPromotions: summaryTreatment, errorExtra, newBuy, newExtra, errorBuy } = await onPromotionSave({
                    patient: { patientCaseId: props?.patientCaseId, patientName: props?.patientName, patientId: props?.patientId },
                    promotionDelId: newProduct?.promotionDelId || 0,
                    promotionExtraBuy: newProduct?.promotionExtraBuy || [],
                    promotionExtraNew: newProduct?.promotionExtraNew || [],
                    promotionExtraValue: newProduct?.promotionExtraValue || [],
                    dataPromotions: promotionLoadClear && newProduct,
                    treatments: { ...treatments, summeryTreatments: newTreatmentSum },
                    user,
                    t: t
                });
                if (errorExtra || errorBuy) {
                    setDisabledSave(false);
                    return;
                }

                const summaryTreatments = props.page !== "RetrospectiveOrder" ? summaryTreatment : _.merge([], newTreatmentSum);
                const hasItemInSummary = _.findIndex(summaryTreatments, (sum: any) => {
                    if (!sum?.promotion?.length) return sum?.itemId === newProduct?.itemId;
                    else return sum?.itemId === newProduct?.itemId && sum?.promotion.every((info: any) => info?.promotionType !== 'EXTRA');
                });
                if (hasItemInSummary < 0) {

                    newTreatmentSum = [...summaryTreatments, newProduct, ...newBuy, ...newExtra];
                } else {
                    if (hasItemInSummary || hasItemInSummary === 0) {
                        const newSummaryTreatments = [...summaryTreatments];
                        newSummaryTreatments[hasItemInSummary] = newProduct
                        const newTreatment = onCheckPromotionCurrent(newSummaryTreatments);
                        newTreatmentSum = [...newTreatment, ...newBuy, ...newExtra];
                    }
                }
            }
        }

        return newTreatmentSum;
    };


    const onSubmitPopup = async () => {
        if (_.isEmpty(itemsBarcode)) setItemsBarcode(product)
        if (_.isEmpty(stickerBarcode)) setStickerBarcode(stickerProduct)

        const newTreatmentSum: any = await handleDataSubmit()
        // const mergedResult = mergeSummeryTreatments(treatments.summeryTreatments, itemsBarcode);
        dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: newTreatmentSum }));
        setDisabledSave(false)
        props.onSubmit && props.onSubmit()
    }


    const mergeSummeryTreatments = (currentItem: any, newItem: any) => {
        const newItemMap = new Map(newItem.map((item: any) => [item.itemId, item]));
        const mergedItem = currentItem.map((item: any) => {
            return newItemMap.has(item.itemId) ? newItemMap.get(item.itemId) : item;
        });

        newItem.forEach((item: any) => {
            if (!currentItem.some((existingItem: any) => existingItem.itemId === item.itemId)) {
                mergedItem.push(item);
            }
        });
        return mergedItem;
    };

    const onSaveSticker = async (total: number, pd?: any, sk?: any) => {
        const pdItem = !pd ? product : pd
        const skItem = !sk ? stickerProduct : sk
        const newValue: ProductStickerType = {
            sticker: JSON.stringify(skItem),
            tempOrderProductId: pdItem.tempOrderProductId,
            doctorId: pdItem.doctorId,
            qty: pdItem.qty,
            price: pdItem.price,
            discount: pdItem.discount,
            discountUnit: pdItem.discountUnit,
            additional: pdItem.additional,
            additionalUnit: pdItem.additionalUnit,
            total: total,
            itemId: pdItem.itemId
        }
        if (!newValue.tempOrderProductId) {
            const i = _.filter(newStickerProducts, (stk: ProductStickerType) => stk.itemId !== pd.itemId)
            dispatch(setNewStickerProduct({ value: [...i, newValue] }))
        } else {
            await TreatmentProductApi.updateStickerById(props.patientCaseId, newValue.itemId, newValue).then(() => { return }).catch(() => { return })
        }
    }

    const handleInputSearch = useCallback(
        _.debounce((type: 'productInstructions' | 'productUnits' | 'productUses' | 'prodctSymptom', search?: string) => {
            if (type === 'productInstructions') loadDefaultProductInstructions(search)
            if (type === 'productUnits') loadDefaultProductUnits(search)
            if (type === 'productUses') loadDefaultProductUses(search)
            if (type === 'prodctSymptom') loadDefaultProductUses(search)
        }, 1000), []);

    useEffect(() => {
        loadData()
    }, [scanBarcode])

    useEffect(() => {
        if (props.group === 'MEDICINE' && (props.type === 'thumbnail' || props.type === 'summaryTreatment')) {
            loadDefaultProductInstructions()
            loadDefaultProductUnits()
            loadDefaultProductUses()
            loadDefaultProductSymtom()
            loadDefaultDoctor()
        }
        else {
            // loadData()
            loadDefaultDoctor()
        }
    }, [props.group, props.type, props.item]);

    const reloadProduct = () => {
        loadDefaultProductInstructions()
        loadDefaultProductUnits()
        loadDefaultProductUses()
        loadDefaultProductSymtom()
    }

    const onAddProduct = (type: any, items: any, maxLength: number) => {
        setTypeAdd(type);
        setItems(items);
        setMaxLength(maxLength);
        setIsShowAddProductChoice(true);
        dispatch(showModal());
    };

    const onAddProductInstruction = () => onAddProduct('PRODUCT_INSTRUCTION', productInstructions, 80);
    const onAddProductUnit = () => onAddProduct('PRODUCT_UNITS', productUnits, 10);
    const onAddProductUse = () => onAddProduct('PRODUCT_USES', productUses, 40);
    const onAddProductSymptom = () => onAddProduct('PRODUCT_SYMPTOM', productSymptom, 40);

    const handleBarcodeScan = async (data: any) => {
        if (!data?.itemId) { return; }
        if (data?.qty < 1) {
            switch (props.group) {
                case 'MEDICINE':
                    notiError(t("TREATMENT_PRODUCT.MEDICINE.MED_OUT"));
                    break;
                case 'MATERIAL':
                    notiError(t("TREATMENT_PRODUCT.MATERIAL.PD_OUT"));
                    break;
                default:
                    notiError(t("TREATMENT_PRODUCT.PRODUCT.PD_OUT"));
            }
            return;
        }

        // setLoading(true)
        // selectedIndex
        const hasItem = treatments.summeryTreatments.find((item: any) => item.itemId === data.itemId && (!item?.promotion?.length || item?.promotion?.every((info: any) => info?.promotionType !== 'EXTRA')));
        const hasItemsBarcode = itemsBarcode.some((item: any) => item.itemId === data.itemId);

        if (hasItem?.tempOrderProductId) {
            const sticker = JSON.parse(hasItem?.sticker || "{}") || {}
            const currentQty = (hasItem?.qty || 0) + 1;
            const baseProduct = {
                ...hasItem,
                qty: currentQty,
                total: cal_sum(false, { ...hasItem, qty: currentQty }),
                image: sticker?.image || {}
            }

            setProduct(baseProduct);

            const delPromotionId = baseProduct?.promotion?.length ? baseProduct?.promotion?.map((item: any) => item?.promotionBranchId) : 0
            setPromotionCurrent([]);
            setPropmotionBranchMain({});
            setPromotionDelId(delPromotionId);
            setPromotionProduct([]);
            setPromotionProductCurrent([]);
            setPromotionExtraValue({});
            setPromotionExtraBuy([]);
            setPromotionExtraNew([]);
            setPromotionBuy([]);
            setPromotionExtra([]);

            setVoucherProduct({})
            setSearchVoucher("")

            const updatedItemsBarcode = [...itemsBarcode, baseProduct];
            const newItemBarcode = updatedItemsBarcode?.map((item: any) => {
                if (item?.promotion?.length) {
                    return { ...item, promotion: [...item?.promotion?.filter((info: any) => !delPromotionId.includes(info?.promotionBranchId))] }
                } else {
                    return item
                }
            })
            setItemsBarcode(newItemBarcode);
            if (updatedItemsBarcode.length >= 1) {
                setIsActive(!isActive);
                dispatch(selectIndex(updatedItemsBarcode.length - 1));
                dispatch(setSelected({ key: updatedItemsBarcode.length - 1, value: updatedItemsBarcode[updatedItemsBarcode.length - 1] }));
            }
            setScanBarcode('');
            if (baseProduct.sticker) {
                const sticker = JSON.parse(baseProduct.sticker)
                setStickerProduct(sticker)
                handleChangeStickerProductDetail(sticker)
            } else {
                const stickerPd: StickerType = createBaseSticker(data, data.sticker)
                if (props.group === 'MEDICINE') {
                    const sticker = await handleSticker(data.itemId, stickerPd)
                    handleChangeStickerProductDetail(sticker)
                } else {
                    setStickerProduct(stickerPd)
                    handleChangeStickerProductDetail(stickerPd)
                }
            }
        } else if (!hasItemsBarcode && !data.sticker) {

            const stickerPd: StickerType = await createBaseSticker(data, data?.oldSticker)
            const baseProduct = await createBaseProduct(data, stickerPd, 1);

            setStickerProduct(stickerPd)
            handleChangeStickerProductDetail(stickerPd)

            const index = _.findIndex(stickerBarcode, { itemId: data.itemId });
            setProduct(baseProduct);
            setScanBarcode('');
            const updatedItemsBarcode = [...itemsBarcode, baseProduct];

            setPromotionCurrent([]);
            setPropmotionBranchMain({});
            setPromotionDelId(0);
            setPromotionProduct([]);
            setPromotionProductCurrent([]);
            setPromotionExtraValue({});
            setPromotionExtraBuy([]);
            setPromotionExtraNew([]);
            setPromotionBuy([]);
            setPromotionExtra([]);

            setVoucherProduct({})
            setSearchVoucher("")

            onLoadPromotionTreatments(updatedItemsBarcode)
            setItemsBarcode(updatedItemsBarcode);

            if (index !== -1) {
                stickerBarcode[index] = { ...stickerBarcode[index], ...stickerPd }
            }
            if (updatedItemsBarcode.length >= 1) {
                setIsActive(!isActive);
                dispatch(selectIndex(updatedItemsBarcode.length - 1));
                dispatch(setSelected({ key: updatedItemsBarcode.length - 1, value: updatedItemsBarcode[updatedItemsBarcode.length - 1] }));
            }
            //   setLoading(false)
        } else {
            const index = _.findIndex(itemsBarcode, { itemId: data.itemId });
            const currentQty = Number(itemsBarcode[index].qty || 0) + 1;
            const updatedItem = {
                ...itemsBarcode[index],
                qty: currentQty,
                total: itemsBarcode[index].price * currentQty,
                discount: Number(itemsBarcode[index]?.discount) || 0,
                discountUnit: itemsBarcode[index]?.discountUnit || 'BAHT',
                additional: Number(itemsBarcode[index]?.additional) || 0,
                additionalUnit: itemsBarcode[index]?.additionalUnit || 'BAHT'
            };

            const stickerPd: StickerType = await createBaseSticker(data, data.sticker)
            setStickerProduct(stickerPd)
            setProduct(updatedItem)

            const newItemsBarcode = itemsBarcode.map((info: any, num: number) => {
                if (num === index) {
                    return updatedItem
                } else {
                    return info
                }
            })

            onLoadPromotionTreatments(newItemsBarcode)
            setItemsBarcode(newItemsBarcode);

            setPromotionCurrent([]);
            setPropmotionBranchMain({});
            setPromotionDelId(0);
            setPromotionProduct([]);
            setPromotionProductCurrent([]);
            setPromotionExtraValue({});
            setPromotionExtraBuy([]);
            setPromotionExtraNew([]);
            setPromotionBuy([]);
            setPromotionExtra([]);

            setVoucherProduct({})
            setSearchVoucher("")

            setIsActive(!isActive);
            dispatch(selectIndex(index));
            dispatch(setSelected({ key: index, value: updatedItem }));
            setScanBarcode('');
        }
    };


    const createBaseSticker = (data: any, sticker: any) => ({
        ...sticker,
        itemId: data.itemId,
        itemName: data.itemName,
        itemNameEn: data.itemNameEn,
        itemCode: data.itemCode,
        image: {
            fileId: data?.image?.fileId || null,
            filePath: data?.image?.filePath || '',
            fileName: data?.image?.fileName || ''
        },
        stock: data.qty,
        defaultProductUnitName: data.itemUnitName || data.itemUnitNameEn,
        dosage: 1,
        expiredDate: data.expireDate || '',
        itemRecommend: '',
        medicineInformationId: data?.medicineInformationId || 0
    });


    const createBaseProduct = (data: any, sticker: StickerType, qty: number) => ({
        ...data,
        sticker: JSON.stringify(sticker),
        doctorId: data.doctorId,
        qty,
        price: data.price,
        discount: 0,
        discountUnit: 'BAHT',
        additional: 0,
        additionalUnit: 'BAHT',
        total: data.price * qty
    });



    const seleteItemBarCode = (med: any, index: number) => {
        setLoading(true)
        setTimeout(() => {
            setProduct(med)

            setPromotionDelId(med?.promotionDelId || [])
            setPromotionExtraBuy(med?.promotionExtraBuy || [])
            setPromotionExtraValue(med?.promotionExtraValue || [])
            setPromotionExtraNew(med?.promotionExtraNew || [])
            setPromotionProduct(med?.promotion)

            setVoucherProduct(med?.voucher)
            setSearchVoucher(med?.voucher?.voucherCode)

            const newOption = promotions.find((item: any) => item?.itemId === med?.itemId)?.vouchers || []
            setVoucher(newOption)

            setStickerProduct(stickerBarcode[index])
        }, 500);
        setIsActive(!isActive)
        dispatch(selectIndex(index));
        dispatch(setSelected({ key: index, value: med }));
        setLoading(false)
    }



    const handleSubmit = (data: any, type: any) => {
        const autoSelect: any = {
            PRODUCT_INSTRUCTION: { productInstructionId: data.productInstructionId, productInstructionName: data.productInstructionName, productInstructionNameEn: data.productInstructionNameEn },
            PRODUCT_UNITS: { productUnitId: data.productUnitId, productUnitName: data.productUnitName, productUnitNameEn: data.productUnitNameEn },
            PRODUCT_USES: { productUseId: data.productUseId, productUseDetail: data.productUseDetail, productUseDetailEn: data.productUseDetailEn },
            PRODUCT_SYMPTOM: { productSymptomId: data.productSymptomId, productSymptomDetail: data.detail, productSymptomDetailEn: data.detailEn }
        }
        reloadProduct()
        setStickerProduct({ ...stickerProduct, ...autoSelect[type] })
        return setIsShowAddProductChoice(false)
    }

    const handleDiscard = async (labelId: any, id: any) => {
        if (labelId === 'productInstructionId') {
            await ProductInstructionsApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    const newStickerProduct = {
                        ...stickerProduct,
                        productInstructionId: 0,
                        productInstructionName: ''
                    }
                    setStickerProduct(newStickerProduct)
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (labelId === 'productUnitId') {
            await ProductUnitsApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    const newStickerProduct = {
                        ...stickerProduct,
                        productUnitId: 0,
                        productUnitName: '',
                        productUnitNameEn: '',
                    }
                    setStickerProduct(newStickerProduct)
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (labelId === 'productUseId') {
            await ProductUsesApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    const newStickerProduct = {
                        ...stickerProduct,
                        productUseId: 0,
                        productUseDetail: ''
                    }
                    setStickerProduct(newStickerProduct)
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }
        if (labelId === 'productSymptomId') {
            await ProductSymptomApi
                .remove(id)
                .then(({ data }) => {
                    reloadProduct()
                    const newStickerProduct = {
                        ...stickerProduct,
                        productSymptomId: 0,
                        productSymptomDetail: ''
                    }
                    setStickerProduct(newStickerProduct)
                })
                .catch((e) => {
                    const err = e.response.data
                    return notiError(t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.${err.message}`))
                })
        }

    }

    const cal_sum = (fullPrice?: boolean, pd?: any) => {
        const pdItem = !pd ? product : pd
        const dataPromotion = pd?.promotionBranchMain || promotionBranchMain
        const dataVoucher = pd?.voucher || voucherProduct
        const renderCalPromtoion = (total: number) => {
            const baht = ["BAHT", "BATH"]
            if (dataPromotion?.type === "DISCOUNT") {
                if (dataPromotion?.promotionPriceType === "DISCOUNT") {
                    if (baht.includes(dataPromotion?.promotionPriceUnit)) {
                        return total - dataPromotion.promotionPrice <= 0 ? 0 : total - dataPromotion?.promotionPrice
                    } else {
                        const perDiscount = (total * dataPromotion?.promotionPrice || 0) / 100
                        return total - perDiscount <= 0 ? 0 : total - perDiscount
                    }
                } else {
                    return dataPromotion.promotionPrice
                }
            } else {
                return total
            }
        }
        const rendercalVoucher = (total: number) => {
            if (dataVoucher?.voucherId) {
                if (dataVoucher?.voucherPriceUnit === 'PERCENT') {
                    return total * ((dataVoucher?.voucherPrice || 0) / 100)
                } else {
                    return dataVoucher?.voucherPrice || 0
                }
            } else {
                return 0
            }
        }

        if (props.group !== 'MEDICINE') {
            const newTotal = ((pdItem.price * pdItem.qty) - pdItem.discount) + pdItem.additional
            if ((dataPromotion?.promotionBranchId || dataVoucher?.voucherId) && !fullPrice) {
                return renderCalPromtoion(newTotal) - rendercalVoucher(newTotal)
            } else {
                return newTotal || 0
            }
        } else {
            const discount = pdItem.discountUnit === "BAHT" ? pdItem.discount : (pdItem.price * pdItem.qty) * (pdItem.discount / 100)
            const additional = pdItem.additionalUnit === "BAHT" ? pdItem.additional : (pdItem.price * pdItem.qty) * (pdItem.additional / 100)
            const newTotal = ((pdItem.price * pdItem.qty) - discount) + additional
            if ((dataPromotion?.promotionBranchId || dataVoucher?.voucherId) && !fullPrice) {
                return renderCalPromtoion(newTotal) - rendercalVoucher(newTotal)
            } else {
                return newTotal || 0
            }
        }
    }

    const handleSizePreviewMed = useCallback(() => {
        if (previewMedRef?.current) {
            const newWidth = previewMedRef.current.clientWidth / 302.36
            const newHeight = previewMedRef.current.clientHeight / 188.97
            return `scale(${newHeight})`
        } else {
            return `scale(1)`
        }
    }, [previewMedRef])


    const onClerPopPromotionExtra = () => {
        setPromotionExtra([])
        setPopPromotionExtra(false)
    }

    const onChangePromotionExtra = (data: any, dataBuy?: any) => {
        if (promotionExtraValue?.promotionBranchId && (dataBuy[0]?.groupNumber || promotionExtraBuy[0]?.groupNumber)) {
            setPromotionLoadClear(true)
            onClerPopPromotionExtra()
            if (dataBuy?.length) setPromotionExtraBuy(dataBuy)
            setPromotionExtraNew(data)
            const newproductPromotion = promotionProduct?.length ? promotionProduct?.filter((item) => item?.isMain !== "1") : []
            setPromotionProduct([
                ...newproductPromotion,
                {
                    promotionBranchId: promotionExtraValue?.promotionBranchId,
                    promotionType: "BUY",
                    promotionId: promotionExtraValue?.promotionId,
                    isMain: '1',
                    groupNumber: (dataBuy[0]?.groupNumber || promotionExtraBuy[0]?.groupNumber)
                }
            ])
        }
    }

    const handleSetTreatmentAll = (data?: any) => {
        const newItem = data || itemsBarcode
        const oldData = treatments?.summeryTreatments?.filter((sum: any) => {
            if (!newItem.some((item: any) => item?.itemId === sum.itemId) || (sum?.promotion?.length && sum.promotion.every((info: any) => info?.promotionType === "EXTRA"))) {
                return sum
            }
        })
        const newProduct = newItem?.map((item: any) => {
            const newSticker = stickerBarcode?.find((info: any) => info?.itemId === item?.itemId)
            return {
                ...item,
                stock: newSticker?.stock || 0,
                total: cal_sum(false, item),
                sticker: newSticker ? JSON.stringify(newSticker) : "",
                fullPrice: cal_sum(true, item)
            }
        })
        const summaryTreatments = [...oldData, ...newProduct]
        return summaryTreatments
    }

    const handleChangePromotionProduct = (value: any) => {
        const pCId = promotionProductCurrent?.find((item: any) => item?.isMain === '1')?.promotionBranchId || 0
        setPromotionDelId(pCId)
        setPromotionLoadClear(true)
        if (value?.promotionBranchId) {
            if (value?.type === "EXTRA" && value?.extra?.length) {
                const newBuy: any[] = []
                const newExtra: any[] = []
                value?.extra.forEach((item: any) => {
                    if (item?.type === "BUY") {
                        const buyInfo = newBuy[item?.groupNumber - 1] || []
                        newBuy[item?.groupNumber - 1] = [...buyInfo, item]
                    } else {
                        const extraInfo = newExtra[item?.groupNumber - 1] || []
                        newExtra[item?.groupNumber - 1] = [...extraInfo, item]
                    }
                })

                const onChangeTreatmentSum = (num: number) => {
                    const newData = [...handleSetTreatmentAll()]
                    newData[num] = product
                    return newData
                }

                const treatmentSum = handleSetTreatmentAll()?.findIndex((item: any) => item?.itemId === product?.itemId && (!item?.promotion?.length || item?.promotion?.length && item?.promotion?.every((info: any) => info?.promotionType !== "EXTRA")))
                const treatmentSummerys = treatmentSum < 0 ? [...handleSetTreatmentAll(), product] : onChangeTreatmentSum(treatmentSum)

                let autoPromotionBuy = null

                if (newBuy?.length) {
                    const treatmentSummerysCanUse = treatmentSummerys.filter((item: any) => {
                        if (!item?.promotion?.length || (item?.promotion?.length && item?.promotion.some((info: any) => info?.promotionType !== "EXTRA"))) return item
                    }).map((item: any) => { return { itemId: item?.operativeId || item?.itemId, qty: item.qty } })
                    const dataBuyCanUse = newBuy.find((info: any) => info.every((i: any) => {
                        return treatmentSummerysCanUse.some((t: any) => t?.itemId === i?.itemId && t?.qty === i?.qty)
                    }))
                    if (dataBuyCanUse) {
                        autoPromotionBuy = dataBuyCanUse
                        setPromotionExtraBuy(dataBuyCanUse)
                    }
                    else setPromotionBuy(newBuy?.filter((item: any) => item.some((info: any) => info?.itemId === product?.itemId))
                        .map((item: any) => item.map((info: any) => { return { ...info, disabled: !treatmentSummerysCanUse.some((t: any) => t?.itemId === info?.itemId && t?.qty === info?.qty) } })) || [])
                }

                if (autoPromotionBuy && autoPromotionBuy[0]?.groupNumber && newExtra?.length === 1) {
                    setPromotionExtraValue(value)
                    setPromotionExtraNew(newExtra[0])
                    const newproductPromotion = promotionProduct?.length ? promotionProduct?.filter((item) => item?.isMain !== "1") : []
                    setPromotionProduct([
                        ...newproductPromotion,
                        {
                            promotionBranchId: value?.promotionBranchId,
                            promotionType: "BUY",
                            promotionId: value?.promotionId,
                            isMain: '1',
                            groupNumber: autoPromotionBuy[0]?.groupNumber
                        }
                    ])
                } else {
                    setPromotionExtraValue(value)
                    setPromotionExtra(newExtra)
                    setPopPromotionExtra(true)
                }
            } else {
                setPromotionExtraNew([])
                setPromotionExtraBuy([])
                setPromotionBuy([])
                setPromotionExtraValue({})
                const newproductPromotion = promotionProduct?.length ? promotionProduct?.filter((item) => item?.isMain !== "1") : []
                setPromotionProduct([
                    ...newproductPromotion,
                    {
                        promotionBranchId: value?.promotionBranchId,
                        promotionType: value?.type,
                        promotionId: value?.promotionId,
                        isMain: '1'
                    }
                ])
            }
        } else {
            setPromotionExtraNew([])
            setPromotionExtraBuy([])
            setPromotionBuy([])
            setPromotionExtraValue({})
            const newproductPromotion = promotionProduct?.length ? promotionProduct?.filter((item) => item?.isMain !== "1") : []
            setPromotionProduct(newproductPromotion)
        }
    }

    const onLoadPromotionTreatments = (data: any) => {
        if (props?.patientId && props.page !== "RetrospectiveOrder") {
            const hasItemInSummary = _.some(treatments.summeryTreatments, (sum: any, index) => {
                if (!sum?.promotion?.length) {
                    return data.some((item: any) => item?.itemId === sum.itemId)
                } else {
                    return data.some((item: any) => item?.itemId === sum.itemId) && sum?.promotion.every((info: any) => info?.promotionType !== 'EXTRA')
                }
            })
            if (!hasItemInSummary) {
                const newData = [...treatments.summeryTreatments, ...data]
                dispatch(loadPromotionTreatments(props?.patientId, newData))
            } else {

                const oldData = treatments?.summeryTreatments?.filter((sum: any) => {
                    if (!data.some((item: any) => item?.itemId === sum.itemId)) {
                        return sum
                    }
                })

                const newProduct = data?.map((item: any) => {
                    const newSticker = stickerBarcode?.find((info: any) => info?.itemId === item?.itemId)
                    return {
                        ...item,
                        stock: newSticker?.stock || 0,
                        total: cal_sum(false, item),
                        sticker: newSticker ? JSON.stringify(stickerProduct) : "",
                        fullPrice: cal_sum(true, item)
                    }
                })
                const summaryTreatments = [...oldData, ...newProduct]
                // summaryTreatments[hasItemInSummary] = newProduct
                dispatch(loadPromotionTreatments(props?.patientId, summaryTreatments))
            }
        }
    }

    useEffect(() => {
        if (loadPromotionFrist && itemsBarcode?.length) {
            setLoadPromotionFrist(false)
            onLoadPromotionTreatments(itemsBarcode)
        }
    }, [itemsBarcode])

    const handleSetPromotionCurrent = (option?: any) => {
        let mainId = 0
        const newOp = option || promotionProduct || []
        if (newOp?.length) {
            const promotionUsage = newOp?.map((item: any) => {
                if (item?.isMain === '1') {
                    mainId = item?.promotionBranchId || 0
                }
                return item?.promotionBranchId
            })
            const newExtra: any[] = []
            const newPromotions = promotions.find((item: any) => item?.itemId === product?.itemId)?.promotions || []
            const promotionItem = treatments?.summeryTreatments.find((item: any) => item?.promotion?.some((info: any) => info?.promotionType === 'EXTRA' && info?.promotionBranchId === mainId))?.promotion?.find((item: any) => item?.promotionBranchId === mainId)
            const newPromotionCurrent = (newPromotions?.length && promotionUsage?.length) ? newPromotions.filter((item: any) => {
                if (mainId === item?.promotionBranchId) {
                    if (item?.type === "EXTRA" && promotionItem?.groupNumber) {
                        item?.extra.forEach((info: any) => {
                            if (info?.type === "EXTRA" && promotionItem?.groupNumber === info?.groupNumber) {
                                newExtra.push(info)
                            }
                        })
                    }
                    setPropmotionBranchMain(item)
                }
                return promotionUsage.includes(item?.promotionBranchId)
            }) : []
            if (promotionItem?.groupNumber && !promotionExtraNew?.length) {
                setPromotionExtraNew(newExtra)
            }
            setPromotionCurrent(newPromotionCurrent)
        } else {
            setPromotionCurrent([])
        }
        if (!mainId) {
            setPropmotionBranchMain({})
        }
    }

    useEffect(() => {
        handleSetPromotionCurrent()
    }, [promotionProduct])

    const handleVoucher = () => {
        if (promotions?.length && product?.itemId) {
            const newOption = promotions.find((item: any) => item?.itemId === product?.itemId)?.vouchers || []
            // if (!newOption?.length || (newOption?.length && !newOption.some((item: any) => item?.voucherId === voucherProduct?.voucherId))) {
            //     setVoucherProduct({})
            //     setSearchVoucher("")
            // }
            setVoucher(newOption)
        }
    }

    useEffect(() => {
        if (promotions?.length && product?.itemId) {
            const newOption = promotions.find((item: any) => item?.itemId === product?.itemId)?.promotions || []
            const promotionUsage = promotionProduct?.map((item: any) => item?.promotionBranchId) || []
            const newPromotion: any = []
            const operativeMain = promotionProduct?.find((item: any) => item?.isMain === '1')
            newOption.forEach((item: any) => {
                if (promotionUsage.includes(item?.promotionBranchId)) {
                    const checkMain = operativeMain?.promotionBranchId === item?.promotionBranchId
                    newPromotion.push({
                        promotionBranchId: item?.promotionBranchId,
                        promotionType: checkMain && item?.type === "EXTRA" ? "BUY" : item?.type,
                        promotionId: item?.promotionId,
                        isMain: checkMain ? '1' : '0'
                    })
                }
            })
            setPromotionProduct(newPromotion)
        }
        handleVoucher()
    }, [promotions])

    const renderPromotionOption = useCallback(() => {
        if (promotions?.length && product?.itemId) {
            const newOption = promotions.find((item: any) => item?.itemId === product?.itemId)?.promotions || []
            return newOption
        }
        return []
    }, [promotions, product?.itemId])

    const renderDetailListExtra = (data: any, index?: number) => {
        if (data?.length) {
            const newText = data.map((item: any, index: number) => {
                return `${item?.itemName || ''} ${item?.qty || 0} ${item?.itemUnitName || ''}`
            })
            return newText.join(', ')
        }

        if (promotionCurrent?.length && (index || index === 0) && promotionCurrent[index]) {
            const newText: string[] = []
            if (promotionCurrent[index]?.type === "EXTRA" && promotionProduct?.length) {
                const promotionItem = treatments?.summeryTreatments.find((item: any) => item?.promotion?.some((info: any) => info?.promotionType === 'EXTRA' && info?.promotionBranchId === promotionCurrent[index]?.promotionBranchId))?.promotion?.find((item: any) => item?.promotionBranchId === promotionCurrent[index]?.promotionBranchId)
                if (promotionItem?.groupNumber) {
                    promotionCurrent[index]?.extra.forEach((item: any) => {
                        if (item?.type === "EXTRA" && promotionItem?.groupNumber === item?.groupNumber) {
                            newText.push(item?.itemName)
                        }
                    })
                    return newText.join(', ')
                }
            }
        }
        return <></>
    }


    const deleteItem = (id: number) => {
        const updatedItemsBarcode = [...itemsBarcode];
        const updatedStickerBarcode = [...stickerBarcode]
        updatedItemsBarcode.splice(id, 1);
        updatedStickerBarcode.splice(id, 1);
        setProduct(initialProductState)

        onLoadPromotionTreatments(updatedItemsBarcode)
        setItemsBarcode(updatedItemsBarcode);
        setStickerBarcode(updatedStickerBarcode)
        if (updatedItemsBarcode.length) {
            seleteItemBarCode(updatedItemsBarcode[updatedItemsBarcode.length - 1], updatedItemsBarcode.length - 1)
        }

        setPromotionCurrent([]);
        setPropmotionBranchMain({});
        setPromotionDelId(0);
        setPromotionProduct([]);
        setPromotionProductCurrent([]);
        setPromotionExtraValue({});
        setPromotionExtraBuy([]);
        setPromotionExtraNew([]);
        setPromotionBuy([]);
        setPromotionExtra([]);

        setVoucherProduct({})
        setSearchVoucher("")

        setStickerProduct({});
        setErrorMessage(initialErrorMessageState);
        setItems(undefined);
        setMedicineInformationId(undefined);
        setProductInstructions([]);
        setProductUnits([]);
        setProductUses([]);
        setProductSymptom([]);
    }


    const onOpenPopVoucherSelectLot = (item: any) => {
        setDataPopVoucherSelectLot(item)
        setPopVoucherSelectLot(true)
    }

    const onClerPopVoucherSelectLot = () => {
        setDataPopVoucherSelectLot({})
        setPopVoucherSelectLot(false)
    }

    const handleVoucherSelect = (item?: any) => {
        setVoucherLoadClear(true)
        setSearchVoucher(item?.voucherCode || '')
        if (item?.voucherListId) {
            setVoucherProduct({ ...dataPopVoucherSelectLot, ...item })
            onClerPopVoucherSelectLot()
        } else {
            setVoucherProduct({})
        }
    }

    const handleNewTreatment = () => {
        const hasItemInSummary = _.merge(treatments.summeryTreatments.filter((sum: any) => {
            if (!sum?.promotion?.length) {
                return !itemsBarcode.some((item: any) => item?.itemId === sum?.itemId);
            } else {
                return !itemsBarcode.some((item: any) =>
                    sum.itemId === item?.itemId &&
                    sum?.promotion.every((info: any) => info?.promotionType !== 'EXTRA')
                );
            }
        }), itemsBarcode.map((item: any) => {
            return { ...item, total: cal_sum(false, item) }
        }));
        return hasItemInSummary || []
    }

    const onLoadVoucher = async () => {
        if (searchVoucher && searchVoucher !== '' && voucherProduct?.voucherCode !== searchVoucher && props.group !== "MATERIAL") {
            const patientId = treatments?.summeryTreatments?.find((item: any) => item?.patientId)?.patientId || 0

            const newTreatment = handleNewTreatment()

            const condition: VoucherFindByCodeProps = {
                code: searchVoucher,
                itemType: props.group,
                itemId: product?.itemId || 0,
                qty: product?.qty,
                min: _.sumBy(newTreatment.map((item: any) => {
                    if (item?.approvalItems?.status === ApprovalStatus.PENDING) {
                        const newData = JSON.parse(item?.approvalItems?.body)
                        return { ...item, total: newData?.total }
                    } else {
                        return { ...item }
                    }
                }), 'total'),
                patientId: props?.patientId || patientId
            }

            await VoucherApi.findVoucherByCode(condition).then((res) => {
                handleVoucherSelect(res?.data[0])
            }).catch((err) => {
                const error = err?.response?.data
                if (error.statusCode === 404) {
                    if (error.message === "NOT_FOUND_VOUCHER_CODE") {
                        setErrorSearchVoucher(t(`PAYMENT.MESSAGE.ERROR.${error.message}`))
                    } else {
                        notiError(t(`VOUCHER.POP_PRODUCT.MESSAGE.ERROR.${error.message}`))
                    }
                } else {
                    notiError(t(`VOUCHER.POP_PRODUCT.MESSAGE.ERROR.ERROR`))
                }
            })
        }
    }

    useEffect(() => {
        const updatedItemsBarcode = [...itemsBarcode];
        if (
            itemsBarcode?.length && updatedItemsBarcode[selectedIndex] &&
            product?.itemId &&
            updatedItemsBarcode[selectedIndex]?.itemId === product?.itemId
        ) {
            const newData = updatedItemsBarcode.map((item: any, index: number) => {
                if (index === selectedIndex && product?.itemId === item?.itemId) {
                    return {
                        ...product,
                        promotionBranchMain: promotionBranchMain,
                        promotionDelId: promotionDelId,
                        promotionExtraBuy: promotionExtraBuy,
                        promotionExtraNew: promotionExtraNew,
                        promotionExtraValue: promotionExtraValue,
                        promotion: promotionProduct,
                        ...handlePromotionDiscount({ promotionBranchMain: promotionBranchMain }),
                        ...handleVoucherDiscount({ voucher: voucherProduct })
                    }

                } else if (promotionExtraValue?.promotionBranchId && promotionExtraBuy.some((info: any) => info?.itemType !== "OPERATIVE" && info?.itemId === item?.itemId && info?.qty === item?.qty && item?.promotion?.every((i: any) => i?.promotionBranchId !== promotionExtraValue?.promotionBranchId))) {
                    return {
                        ...item,
                        promotion: [...item?.promotion,
                        {
                            promotionBranchId: promotionExtraValue?.promotionBranchId,
                            promotionType: "BUY",
                            promotionId: promotionExtraValue?.promotionId,
                            isMain: '0',
                            groupNumber: promotionExtraBuy[0]?.groupNumber
                        }]
                    }
                }
                else {
                    return item
                }
            })
            const promotionAll = renderCheckCurrentUsePromtion(newData)
            const newItemBarcode = newData?.map((item: any) => {
                if (item?.promotion?.length) {
                    return { ...item, promotion: [...item?.promotion?.filter((info: any) => promotionAll.includes(info?.promotionBranchId))] }
                } else {
                    return item
                }
            })
            setItemsBarcode(newItemBarcode);
        }
    }, [promotionDelId, promotionExtraBuy, promotionExtraNew, promotionExtraValue, promotionProduct, promotionBranchMain, voucherProduct])

    const renderVoucher = () => {
        if (voucherProduct?.voucherId) {
            return (
                <Box width={"100%"}>
                    <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} marginBottom={'8px'}>
                        <Box width={'140px'}>
                            {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_NAME')}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                {voucherProduct?.voucherName}
                                {voucherProduct?.detail &&
                                    (
                                        <span>
                                            <Tooltip title={voucherProduct?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </span>
                                    )
                                }
                            </Box>
                        </Box>

                    </Box>
                    <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                        <Box width={'140px'}>
                            {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_COST')}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                {voucherProduct?.voucherPrice}
                                {voucherProduct?.voucherPriceUnit === "PERCENT" ?
                                    <Box>
                                        {'%'}
                                    </Box>
                                    : <Box>
                                        {t('PROMOTION.FORM.BATH')}
                                    </Box>
                                }
                            </Box>
                        </Box>

                    </Box>
                </Box>
            )
        } else if (vouchers?.length) {
            return (
                <Box width={"100%"}>
                    {vouchers.map((item: any, index: number) => {
                        return (
                            <Box key={index} display={'flex'} alignItems={'center'} flexWrap={'wrap'}>
                                <Box width={'140px'}>
                                    {t('Gift Voucher')}
                                </Box>
                                <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                        {item?.voucherName}
                                        {item?.detail &&
                                            (
                                                <span>
                                                    <Tooltip title={item?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </span>
                                            )
                                        }
                                    </Box>
                                    <Box
                                        sx={{ textDecoration: 'underline', color: colors.themeSecondColor, cursor: 'pointer', width: 'fit-content' }}
                                        onClick={() => onOpenPopVoucherSelectLot(item)}
                                    >
                                        {t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_SELECT_INDEX', { index: item?.voucherDetails?.length || 0 })}
                                    </Box>
                                </Box>

                            </Box>
                        )
                    })}
                </Box>
            )
        }
    }

    const renderCheckCurrentUsePromtion = (data?: any) => {
        const promotionAll: number[] = []
        handleSetTreatmentAll(data)?.forEach((item: any) => {
            if (item?.promotion?.length && item?.promotion?.some((info: any) => info.isMain === '1')) {
                const newItem = item?.promotion?.find((info: any) => info.isMain === '1')
                promotionAll.push(newItem?.promotionBranchId)
            }
        })
        return promotionAll
    }

    return (
        <ModalCustom
            size='xl'
            title={t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.${props.group}_TITLE`)}
            modalStyle={`popup-manage-product`}
            component={
                (loading) ?
                    <Loading show type='fullLoading' /> :
                    <Box>{props.openScanBarcode && (
                        <Box>
                            <Typography sx={{ marginRight: { xs: '32px' }, fontWeight: 500 }}>
                                {t('TREATMENT_PRODUCT.CARD.ALL_ITEMS')}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                <Box sx={{ width: '100%', maxWidth: 379, paddingY: '16px' }}>
                                    <TextInput
                                        placeholder={t('TREATMENT_PRODUCT.CARD.SPECIFY_BARCODE')}
                                        value={scanBarcode}
                                        onChange={(event: any) => {
                                            setScanBarcode(event.target.value.replace(/\D/g, '').slice(0, 13));
                                        }}
                                        width={350}
                                        focus={true}
                                        inputProps={{
                                            value: scanBarcode,
                                            endAdornment: !scanBarcode ? (
                                                <FontAwesomeIcon icon={faSearch} style={{ color: colors.black60, fontSize: 15 }} />
                                            ) : (
                                                <FontAwesomeIcon icon={faTimes} onClick={() => setScanBarcode('')} style={{ color: colors.black60, fontSize: 17, cursor: 'pointer' }} />
                                            ),
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <MainButton
                                        onClick={() => {
                                            loadData()
                                            setScanBarcode('');
                                        }}
                                        title={t('MEDICINE.TEXT.SEARCH')}
                                        type={'primary'}
                                        variant={'contained'}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ border: '1px solid #E5E5E5', width: '100%', overflowX: 'auto', display: 'flex', flexDirection: 'row', paddingY: '8px', marginBottom: '16px', whiteSpace: 'nowrap' }}>
                                {(!_.isEmpty(itemsBarcode) && _.map(itemsBarcode, (med: any, index: number) => {
                                    return (
                                        <ProductBox
                                            key={`med-${index}`}
                                            className="px-1 px-xl-2 mb-2 mb-xl-3"
                                            onClick={() => seleteItemBarCode(med, index)}
                                            sx={{ flexShrink: 0 }}
                                        >
                                            <ProductBarCode className={selectedIndex === index ? 'active' : ''}>
                                                <Box sx={{ position: 'absolute', top: 6, right: 8, zIndex: 1 }}>
                                                    <IconButton
                                                        className={'btn-delete'}
                                                        disableFocusRipple
                                                        disableRipple
                                                        disableTouchRipple
                                                        aria-label="delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteItem(index)
                                                        }
                                                        }
                                                        sx={{ backgroundColor: 'transparent !important', padding: 0, '&:disabled': { filter: 'grayscale(1)', opacity: 0.6 } }}
                                                    >
                                                        <img src={icons.btnTrash} alt="trash" style={{ width: 24 }} />
                                                    </IconButton>
                                                </Box>
                                                {selectedIndex === index && (
                                                    <Box sx={{
                                                        position: 'absolute', top: 0, left: 0, zIndex: 1,
                                                        background: colors.themeSecondColor,
                                                        borderRadius: '0px 0px 20px 0px',
                                                        width: '26px',
                                                        height: '26px',
                                                    }}>
                                                        <Box sx={{ marginTop: '-4px', marginLeft: '3px' }}><IconCheck fill={colors.white} /></Box>
                                                    </Box>
                                                )}
                                                <Box className="card-image">
                                                    <Box className="position-relative">
                                                        <img
                                                            src={renderImage(med.image)}
                                                            alt={med.itemName}
                                                            className={`${_.isEmpty(med.image) || !med.image?.filePath || !med.image?.fileName ? 'no-prod-image' : ''}`}
                                                            onError={onImgError}
                                                            style={{ ...(med.isOutOfStock && { opacity: 0.5 }) }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </ProductBarCode>
                                        </ProductBox>
                                    );
                                })) || (
                                        <Typography
                                            className="w-100 text-center"
                                            sx={{ color: colors.disabledGray, paddingY: '30px', justifyContent: 'center' }}
                                        >
                                            {t('TREATMENT_PRODUCT.TITLE.EMPTY_MEDICINE')}
                                        </Typography>
                                    )}
                            </Box>
                        </Box>
                    )}
                        <UseStyled.CustomPopupManageMedicine>
                            <Box className='d-flex flex-column flex-sm-row'>
                                {stickerProduct?.itemCode && (
                                    <Typography sx={{ marginRight: { xs: '32px' }, fontWeight: 500 }}>
                                        {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.CODE')}: <span className='pl-1 pl-md-3'>{stickerProduct.itemCode}</span>
                                    </Typography>
                                )}
                                <Typography sx={{ fontWeight: 500 }}>
                                    {t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.${props.group}_NAME`)}: <span className='pl-1 pl-md-3'>{stickerProduct.itemName || stickerProduct.itemNameEn}</span>
                                </Typography>
                            </Box>
                            <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                            <Box className='d-sm-flex'>
                                <UseStyled.ImageMedicine className='w-100 mx-auto mb-4 mb-sm-0'>
                                    <Box className='image-wrap position-relative'>
                                        <img src={renderImage(stickerProduct.image || {})} alt={stickerProduct.itemName} onError={onImgError} className={`w-100 h-100`} />
                                    </Box>
                                    <Box className='d-flex justify-content-between inventory pt-1'>
                                        <Typography sx={{ flex: 'none' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.QTY')}</Typography>
                                        <Box className='d-flex align-items-center w-100 overflow-hidden'>
                                            <Typography>{stickerProduct.stock}</Typography>
                                            <Typography className='pl-1 text-ellipsis' title={stickerProduct.defaultProductUnitName}>{stickerProduct.defaultProductUnitName}</Typography>
                                        </Box>
                                    </Box>
                                </UseStyled.ImageMedicine>
                                <Box className='w-100' sx={{ paddingTop: { md: ((props.group === 'MEDICINE') && '8px') || 0 } }}>
                                    <Grid container spacing={2} className={'m-sm-0'} sx={{ width: { xs: '100%' } }}>
                                        {(props.group === 'MEDICINE') && (
                                            <>
                                                <Grid item md={4} lg={5} className={'w-100 pt-sm-0'}>
                                                    <Grid container spacing={2}>
                                                        <Grid item lg={11} className='' style={{ width: "calc(100% - 45px)" }}>
                                                            <AutocompleteSelect
                                                                labelId="productInstructionId"
                                                                inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_INSTRUCTION')}
                                                                noOptionsText={t('NOT_FOUND')}
                                                                options={productInstructions}
                                                                getOptionLabel={(option: any) => option.name || stickerProduct.productInstructionName}
                                                                renderOption={(props, option: any) => (
                                                                    <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        {option.name}
                                                                        <IconButton className={`p-0`}
                                                                            onClick={() => handleDiscard("productInstructionId", option.id)}
                                                                            sx={{ backgroundColor: 'transparent', filter: 'grayscale(0)', opacity: 1 }}>
                                                                            <img src={icons.btnMinus} alt="discard" />
                                                                        </IconButton>
                                                                    </Box>

                                                                )}
                                                                onchange={(e, value: any) => {
                                                                    const newStickerProduct = {
                                                                        ...stickerProduct,
                                                                        productInstructionId: value?.id || 0,
                                                                        productInstructionName: _.get(_.find(productInstructions, { id: value?.id || 0 }), 'name')
                                                                    }
                                                                    setStickerProduct(newStickerProduct)
                                                                    setErrorMessage(initialErrorMessageState)
                                                                    handleChangeStickerProductDetail(newStickerProduct)
                                                                }}
                                                                inputProps={{
                                                                    onChange: (e: any) => {
                                                                        setDataLoading(true)
                                                                        setProductInstructions([])
                                                                        handleInputSearch('productInstructions', e.target.value)
                                                                    }
                                                                }}
                                                                onOpen={(e: any) => loadDefaultProductInstructions()}
                                                                value={stickerProduct?.productInstructionId ? _.find(productInstructions, { id: stickerProduct?.productInstructionId }) || '' : null}
                                                                loading={dataLoading}
                                                                disabledBorder
                                                                disableClearable={!stickerProduct.productInstructionId} />
                                                        </Grid>
                                                        <Grid item lg={1} className="pl-4" >
                                                            <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductInstruction}>
                                                                <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                            </IconAddBox>
                                                        </Grid>
                                                        <Grid item lg={4} className='w-100'>
                                                            <InputTextField
                                                                type='number'
                                                                hideControl
                                                                label={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_DOSAGE')}
                                                                value={!stickerProduct?.dosage ? '' : stickerProduct?.dosage}
                                                                onchange={(e: any) => {
                                                                    const valueNumber = !e.target.value ? 0 : String(e.target.value).replace(/[^0-9.]/g, '')
                                                                    const newStickerProduct = { ...stickerProduct, dosage: Number(valueNumber) }
                                                                    setStickerProduct(newStickerProduct)
                                                                    setErrorMessage(initialErrorMessageState)
                                                                    handleChangeStickerProductDetail(newStickerProduct)
                                                                }} />
                                                        </Grid>
                                                        <Grid item lg={7} className='' style={{ width: "calc(100% - 45px)" }}>
                                                            <AutocompleteSelect
                                                                inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_UNIT')}
                                                                labelId="productUnitId"
                                                                noOptionsText={t('NOT_FOUND')}
                                                                options={productUnits}
                                                                getOptionLabel={(option: any) => option.name || stickerProduct.productUnitName}
                                                                renderOption={(props, option: any) => (
                                                                    <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        {option.name}
                                                                        <IconButton className={`p-0`}
                                                                            onClick={() => handleDiscard("productUnitId", option.id)}
                                                                            sx={{ backgroundColor: 'transparent', filter: 'grayscale(0)', opacity: 1 }}>
                                                                            <img src={icons.btnMinus} alt="discard" />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                                onchange={(e, value: any) => {
                                                                    const newStickerProduct = {
                                                                        ...stickerProduct,
                                                                        productUnitId: value?.id || 0,
                                                                        productUnitName: _.get(_.find(productUnits, { id: value?.id || 0 }), 'name'),
                                                                        productUnitNameEn: _.get(_.find(productUnits, { id: value?.id || 0 }), 'nameEn'),
                                                                    }
                                                                    setStickerProduct(newStickerProduct)
                                                                    setErrorMessage(initialErrorMessageState)
                                                                    handleChangeStickerProductDetail(newStickerProduct)
                                                                }}
                                                                inputProps={{
                                                                    onChange: (e: any) => {
                                                                        setDataLoading(true)
                                                                        setProductUnits([])
                                                                        handleInputSearch('productUnits', e.target.value)
                                                                    }
                                                                }}
                                                                onOpen={(e: any) => loadDefaultProductUnits()}
                                                                value={stickerProduct.productUnitId ? _.find(productUnits, { id: stickerProduct.productUnitId }) || '' : null}
                                                                loading={dataLoading}
                                                                disabledBorder
                                                                disableClearable={!stickerProduct.productUnitId} />
                                                        </Grid>
                                                        <Grid item lg={1} className="pl-4">
                                                            <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductUnit}>
                                                                <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                            </IconAddBox>
                                                        </Grid>
                                                        <Grid item lg={11} className='' style={{ width: "calc(100% - 45px)" }}>
                                                            <AutocompleteSelect
                                                                inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_USE')}
                                                                labelId="productUseId"
                                                                noOptionsText={t('NOT_FOUND')}
                                                                options={productUses}
                                                                getOptionLabel={(option: any) => option.name || stickerProduct.productUseDetail}
                                                                renderOption={(props, option: any) => (
                                                                    <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        {option.name}
                                                                        <IconButton className={`p-0`}
                                                                            onClick={() => handleDiscard("productUseId", option.id)}
                                                                            sx={{ backgroundColor: 'transparent', filter: 'grayscale(0)', opacity: 1 }}>
                                                                            <img src={icons.btnMinus} alt="discard" />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                                onchange={(e, value: any) => {
                                                                    const newStickerProduct = {
                                                                        ...stickerProduct,
                                                                        productUseId: value?.id || 0,
                                                                        productUseDetail: _.get(_.find(productUses, { id: value?.id || 0 }), 'name')
                                                                    }
                                                                    setStickerProduct(newStickerProduct)
                                                                    setErrorMessage(initialErrorMessageState)
                                                                    handleChangeStickerProductDetail(newStickerProduct)
                                                                }}
                                                                inputProps={{
                                                                    onChange: (e: any) => {
                                                                        setDataLoading(true)
                                                                        setProductUses([])
                                                                        handleInputSearch('productUses', e.target.value)
                                                                    }
                                                                }}
                                                                onOpen={(e: any) => loadDefaultProductUses()}
                                                                value={stickerProduct.productUseId ? _.find(productUses, { id: stickerProduct.productUseId }) || '' : null}
                                                                loading={dataLoading}
                                                                disabledBorder
                                                                disableClearable={!stickerProduct.productUseId} />
                                                        </Grid>
                                                        <Grid item lg={1} className="pl-4">
                                                            <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductUse}>
                                                                <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                            </IconAddBox>
                                                        </Grid>
                                                        <Grid item lg={11} className='' style={{ width: "calc(100% - 45px)" }}>
                                                            <AutocompleteSelect
                                                                inputLabel={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.SYMPTOM')}
                                                                labelId="productSymptomId"
                                                                noOptionsText={t('NOT_FOUND')}
                                                                options={productSymptom}
                                                                getOptionLabel={(option: any) => option.name || ''}
                                                                renderOption={(props, option: any) => (
                                                                    <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        {option.name}
                                                                        <IconButton className={`p-0`}
                                                                            onClick={() => handleDiscard("productSymptomId", option.id)}
                                                                            sx={{ backgroundColor: 'transparent', filter: 'grayscale(0)', opacity: 1 }} >
                                                                            <img src={icons.btnMinus} alt="discard" />
                                                                        </IconButton>
                                                                    </Box>
                                                                )}
                                                                onchange={(e, value: any) => {
                                                                    const newStickerProduct = {
                                                                        ...stickerProduct,
                                                                        productSymptomId: value?.id || 0,
                                                                        productSymptomDetail: _.get(_.find(productSymptom, { id: value?.id || 0 }), 'name')
                                                                    }
                                                                    setStickerProduct(newStickerProduct)
                                                                    setErrorMessage(initialErrorMessageState)
                                                                    handleChangeStickerProductDetail(newStickerProduct)
                                                                }}
                                                                inputProps={{
                                                                    onChange: (e: any) => {
                                                                        setDataLoading(true)
                                                                        setProductSymptom([])
                                                                        handleInputSearch('prodctSymptom', e.target.value)
                                                                    }
                                                                }}
                                                                onOpen={(e: any) => loadDefaultProductSymtom()}
                                                                value={stickerProduct.productSymptomId ? _.find(productSymptom, { id: stickerProduct.productSymptomId }) || '' : null}
                                                                loading={dataLoading}
                                                                disabledBorder
                                                                disableClearable={!stickerProduct.productSymptomId} />

                                                        </Grid>
                                                        <Grid item lg={1} className="pl-4">
                                                            <IconAddBox className="" sx={{ backgroundColor: 'transparent !important', marginLeft: '-25px' }} onClick={onAddProductSymptom}>
                                                                <AddBoxIcon style={{ width: 30, height: 30 }} />
                                                            </IconAddBox>
                                                        </Grid>
                                                        <Grid item className='w-100'>
                                                            <InputTextarea
                                                                height={'80px'}
                                                                label={t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRODUCT_NOTE')}
                                                                value={!stickerProduct.itemRecommend ? '' : stickerProduct.itemRecommend}
                                                                onchange={(e: any) => {
                                                                    if (e.target.value.length <= 40) {
                                                                        const newStickerProduct = { ...stickerProduct, itemRecommend: e.target.value }
                                                                        setStickerProduct(newStickerProduct)
                                                                        setErrorMessage(initialErrorMessageState)
                                                                        handleChangeStickerProductDetail(newStickerProduct)

                                                                        const contentLength = e.target.value.length
                                                                        if (contentLength > 40) setErrorMaxLength(true)
                                                                        else {
                                                                            setTextLength(contentLength)
                                                                            setErrorMaxLength(false)
                                                                        }
                                                                    }
                                                                }}
                                                                inputProps={{ maxLength: 40 }}
                                                            />
                                                            <Box sx={{ position: 'absolute', marginTop: '-20px', left: 530 }}>
                                                                <Typography sx={{ fontWeight: 400, fontSize: '10px', color: '#808080' }}>{`${textLength}/40`}</Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* label */}
                                                <Grid item md={8} lg={7} className={'w-100 pt-lg-0 d-flex flex-column'}>
                                                    <Typography sx={{ fontWeight: 500, marginTop: { md: '-16px' } }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MEDICINE_LABEL_PREVIEW')}</Typography>
                                                    <Box ref={previewMedRef} className="h-100 w-100" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        <Box sx={{ width: '302.36220472px ', height: '188.97637795px', transform: handleSizePreviewMed(), backgroundColor: "#fff" }}>
                                                            <Box ref={componentRef} className="h-100 w-100">
                                                                <PdfMedSticker stickerData={[{ ...product, sticker: JSON.stringify(stickerProduct) }]} doctors={doctors} patientId={props?.patientId} isBarcode={props?.openScanBarcode} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </>
                                        )}

                                        {/* summary */}
                                        <Grid item className={`${((props.group === 'MEDICINE') && 'mt-2') || 'pt-sm-0'} w-100`}>
                                            <Typography sx={{ fontWeight: 500 }}>{t(`TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.${props.group}_DETAIL`)}</Typography>
                                            <Box className='d-sm-flex align-items-center justify-content-between mt-3'>
                                                <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none' }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PREVIEW.DOCTOR')}</Typography>
                                                <Box className='w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <AutocompleteSelect
                                                        labelId="sl-dentist"
                                                        options={doctors}
                                                        getOptionLabel={(option) => option.fullname}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <Box
                                                                    {...props}
                                                                    key={option.userId}
                                                                    component="li"
                                                                    style={{
                                                                        pointerEvents: doctorHasOverDue(treatments, option.userId) ? 'none' : 'auto',
                                                                        color: doctorHasOverDue(treatments, option.userId) ? colors.disabledGray : colors.textPrimary
                                                                    }}>
                                                                    {option.fullname}
                                                                </Box>
                                                            )
                                                        }}
                                                        onchange={(e, value) => handleChangeProductDetail('doctorId', value.userId)}
                                                        noOptionsText={t('NOT_FOUND')}
                                                        filterOptions={(option) => option.fullname}
                                                        value={product.doctorId ? _.find(doctors, { userId: product.doctorId }) : null}
                                                        disableClearable
                                                        errMessage={errorMessage.EMPTY_DOCTOR || ``}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className='d-sm-flex justify-content-between mt-2'>
                                                <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px' }}>
                                                    {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.QTY_ORDER')}
                                                </Typography>
                                                <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                        <InputTextField
                                                            inputTextAlign={'right'}
                                                            value={product.qty}
                                                            onchange={(e: any) => handleChangeProductDetail('qty', Number(e.target.value))}
                                                            helperText={Number(product.qty) > (stickerProduct.stock || 0) && t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.OVER_STOCK', { stock: stickerProduct.stock, unit: stickerProduct.defaultProductUnitName }) || errorMessage.EMPTY_QTY_ORDER || ''} />
                                                    </Box>
                                                    <Typography className='pl-1 text-right' sx={{ width: '80px', paddingTop: '10px' }}>{stickerProduct.productUnitName}</Typography>
                                                </Box>
                                            </Box>
                                            <Box className='d-sm-flex justify-content-between mt-2'>
                                                <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px' }}>
                                                    {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.PRICE')}
                                                </Typography>
                                                <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                        <InputTextField
                                                            disabled={props.hasChangeProductPrice === 'UNPERMISS'}
                                                            inputTextAlign={'right'}
                                                            value={product.price}
                                                            onchange={(e: any) => handleChangeProductDetail('price', e.target.value)}
                                                            helperText={errorMessage.EMPTY_PRICE || ''} />
                                                    </Box>
                                                    <Typography className='pl-1 text-right' sx={{ width: '80px', paddingTop: '10px' }}>{t('CURRENCY_CHANGE.TH')}</Typography>
                                                </Box>
                                            </Box>
                                            <Box className='d-sm-flex justify-content-between mt-2'>
                                                <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px' }}>
                                                    {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.DISCOUNT')}
                                                </Typography>
                                                <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                        <InputTextField
                                                            disabled={props.hasChangeProductDiscountAdditional === 'UNPERMISS'}
                                                            hideControl
                                                            inputTextAlign={'right'}
                                                            value={product.discount}
                                                            onchange={(e: any) => handleChangeProductDetail('discount', e.target.value)}
                                                            helperText={product.discount > (product.price * product.qty) && t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.MESSAGE.ERROR.OVER_DISCOUNT', { price: numberFormat(product.price * product.qty || 0) }) || ''} />
                                                    </Box>
                                                    {props.group !== 'MEDICINE' ?
                                                        <Typography className='pl-1 text-right' sx={{ width: '80px', paddingTop: '10px' }}>{t('CURRENCY_CHANGE.TH')}</Typography>
                                                        :
                                                        <Box sx={{ width: "80px" }}>
                                                            <BasicSelect
                                                                labelId="lb-unit"
                                                                selectId="sl-unit"
                                                                label=""
                                                                value={product.discountUnit || ""}
                                                                options={
                                                                    _.map(units, (unit: string, index: number) => {
                                                                        return <MenuItem key={index} value={unit}>
                                                                            {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                                                        </MenuItem>
                                                                    })
                                                                }
                                                                onchange={(e: any) => handleChangeProductDetail('discountUnit', e.target.value)}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box className='d-sm-flex align-items-center justify-content-between mt-2'>
                                                <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none' }}>
                                                    {t('TREATMENT_SUMMARY.POPUP.MANAGE_PRODUCT.ADDON')}
                                                </Typography>
                                                <Box className='d-flex align-items-center justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                        <InputTextField
                                                            disabled={props.hasChangeProductDiscountAdditional === 'UNPERMISS'}
                                                            inputTextAlign={'right'}
                                                            value={product.additional}
                                                            onchange={(e: any) => handleChangeProductDetail('additional', e.target.value)} />
                                                    </Box>
                                                    {props.group !== 'MEDICINE' ?
                                                        <Typography className='pl-1 text-right' sx={{ width: '80px' }}>{t('CURRENCY_CHANGE.TH')}</Typography>
                                                        : <Box sx={{ width: "80px" }}>
                                                            <BasicSelect
                                                                labelId="lb-unit"
                                                                selectId="sl-unit"
                                                                label=""
                                                                value={product.additionalUnit || ""}
                                                                options={
                                                                    _.map(units, (unit: string, index: number) => {
                                                                        return <MenuItem key={index} value={unit}>
                                                                            {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                                                        </MenuItem>
                                                                    })
                                                                }
                                                                onchange={(e: any) => handleChangeProductDetail('additionalUnit', e.target.value)}
                                                            />
                                                        </Box>}
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item className={'w-100'} display={(props?.page === 'RetrospectiveOrder' || props.group === "MATERIAL" || !getCrm()) ? 'none' : 'block'}>
                                            <Box className='d-sm-flex justify-content-between mt-2'>
                                                <Box width={'100%'} >
                                                    <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                                                        <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px', width: '100px' }}>
                                                            {t('PROMOTION.MODAL_MANAGE.PROMOTION')}
                                                        </Typography>
                                                        <Box sx={{ maxWidth: '400px', width: '100%', '.MuiOutlinedInput-root': { paddingRight: '9px !important' }, '.MuiAutocomplete-popupIndicator': { transform: 'rotate(0deg) !important' } }}>
                                                            <AutocompleteSelect
                                                                labelId={'promotion'}
                                                                inputLabel={t('PROMOTION.FORM.PROMOTION_NAME')}
                                                                options={renderPromotionOption()}
                                                                noOptionsText={t('NOT_FOUND')}
                                                                getOptionLabel={(option) => option?.promotionFullName || ''}
                                                                renderOption={(props, option) => {
                                                                    return (
                                                                        <Box {...props}
                                                                            aria-disabled={renderCheckCurrentUsePromtion().includes(option?.promotionBranchId)}
                                                                        >
                                                                            {option?.promotionFullName || ''}
                                                                        </Box>
                                                                    )
                                                                }}
                                                                popupIcon={<FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />}
                                                                onchange={(e, value) => handleChangePromotionProduct(value)}
                                                                value={promotionBranchMain?.promotionBranchId ? promotionBranchMain : ''}
                                                                disableClearable={!promotionBranchMain?.promotionBranchId}

                                                            />
                                                        </Box>
                                                    </Box>
                                                    {(promotionBranchMain?.promotionBranchId) &&
                                                        <Box margin={'8px 0'}>
                                                            <Box padding={'0 24px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                                <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                    <Box minWidth={'160px'}>
                                                                        {t('PROMOTION.FORM.PROMOTION_NAME')}
                                                                    </Box>
                                                                    <Box className={'text-ellipsis'} width={'100%'} >
                                                                        {promotionBranchMain?.promotionName}
                                                                        {promotionBranchMain?.detail &&
                                                                            (
                                                                                <span>
                                                                                    <Tooltip title={promotionBranchMain?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}>
                                                                                        <InfoIcon />
                                                                                    </Tooltip>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                                <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                    <Box minWidth={'160px'}>{t('PROMOTION.FORM.TYPE')}</Box>
                                                                    <Box className={'text-ellipsis'} width={'100%'} >
                                                                        {promotionBranchMain?.type === 'DISCOUNT' ? t('PROMOTION.PROMOTION_TYPE.DISCOUNT') : t('PROMOTION.PROMOTION_TYPE.EXTRA')}
                                                                    </Box>
                                                                </Box>
                                                                {promotionBranchMain?.type === 'DISCOUNT' ?
                                                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                        <Box minWidth={'160px'}> {t('PROMOTION.FORM.COST')}</Box>
                                                                        <Box className={'text-ellipsis'} width={'100%'} >
                                                                            {promotionBranchMain?.promotionPrice} {promotionBranchMain?.promotionPriceUnit === "PERCENT" ? "%" : t('PROMOTION.FORM.BATH')}
                                                                        </Box>
                                                                    </Box>
                                                                    :
                                                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                        <Box minWidth={'160px'}>{t('PROMOTION.MODAL_MANAGE.LIST_EXTRA')}</Box>
                                                                        <Box className={'text-ellipsis'} width={'100%'} title={renderDetailListExtra(promotionExtraNew)}>
                                                                            {renderDetailListExtra(promotionExtraNew)}
                                                                        </Box>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    }
                                                    {promotionCurrent?.length && promotionCurrent.filter((item: any) => item?.promotionBranchId !== promotionBranchMain?.promotionBranchId)?.length ?
                                                        <Box margin={'8px 0'}>
                                                            <Box>
                                                                {t('PROMOTION.MODAL_MANAGE.PROMOTION_BIND')}
                                                            </Box>
                                                            <Box width={"100%"}>
                                                                {promotionCurrent.filter((item: any) => item?.promotionBranchId !== promotionBranchMain?.promotionBranchId).map((item: any, index: number) => {
                                                                    if (item?.promotionBranchId !== promotionBranchMain?.promotionBranchId) {
                                                                        return (
                                                                            <Box key={index} padding={'0 24px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                                                <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                                    <Box minWidth={'160px'}>{t('PROMOTION.FORM.PROMOTION_NAME')}</Box>
                                                                                    <Box className={'text-ellipsis'} width={'100%'} >
                                                                                        {item?.promotionName}
                                                                                        {item?.detail && (<span><Tooltip title={item?.detail} placement={'top-start'} sx={{ 'path': { fill: '#A8A8A8' }, }}><InfoIcon /></Tooltip></span>)}
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                                    <Box minWidth={'160px'}>{t('PROMOTION.FORM.TYPE')}</Box>
                                                                                    <Box className={'text-ellipsis'} width={'100%'} >
                                                                                        {item?.type === 'DISCOUNT' ? t('PROMOTION.PROMOTION_TYPE.DISCOUNT') : t('PROMOTION.PROMOTION_TYPE.EXTRA')}
                                                                                    </Box>
                                                                                </Box>
                                                                                {item?.type === 'DISCOUNT' ?
                                                                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                                        <Box minWidth={'160px'}>{t('PROMOTION.FORM.COST')}</Box>
                                                                                        <Box className={'text-ellipsis'} width={'100%'} >{item?.promotionPrice} {item?.promotionPriceUnit === "PERCENT" ? "%" : t('PROMOTION.FORM.BATH')}</Box>
                                                                                    </Box> :
                                                                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                                                                        <Box minWidth={'160px'}>{t('PROMOTION.MODAL_MANAGE.LIST_EXTRA')}</Box>
                                                                                        <Box className={'text-ellipsis'} width={'100%'} title={renderDetailListExtra([], index)}>
                                                                                            {renderDetailListExtra([], index)}
                                                                                        </Box>
                                                                                    </Box>
                                                                                }
                                                                            </Box>
                                                                        )
                                                                    }
                                                                })}
                                                            </Box>
                                                        </Box>
                                                        : <></>}
                                                </Box>
                                                <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                        <InputTextField
                                                            disabled
                                                            inputTextAlign={'right'}
                                                            value={promotionBranchMain?.type === "DISCOUNT" && promotionBranchMain?.promotionPrice || ''}
                                                        />
                                                    </Box>
                                                    <Box sx={{ width: "80px" }}>
                                                        <BasicSelect
                                                            disabled
                                                            labelId="lb-unit"
                                                            selectId="sl-unit"
                                                            label=""
                                                            value={promotionBranchMain?.promotionBranchId ? promotionBranchMain?.promotionPriceUnit === "PERCENT" ? units[0] : units[1] : ''}
                                                            options={
                                                                _.map(units, (unit: string, index: number) => {
                                                                    return <MenuItem key={index} value={unit}>
                                                                        {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                                                    </MenuItem>
                                                                })
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item className={'w-100'} display={(props?.page === 'RetrospectiveOrder' || props.group === "MATERIAL" || !getCrm()) ? 'none' : 'block'}>
                                            <Box className='d-sm-flex justify-content-between mt-2'>
                                                <Box width={'100%'} >
                                                    <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                                                        <Typography className='pb-1 pb-sm-0' sx={{ flex: 'none', paddingTop: '10px', width: '100px' }}>
                                                            {t('Gift Voucher')}
                                                        </Typography>
                                                        <Box sx={{ maxWidth: '400px', width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            <Box sx={{ width: '100%', '.MuiOutlinedInput-root': { paddingRight: '9px !important' }, '.MuiFormControl-root': { position: 'relative' }, '.MuiFormHelperText-root': { position: 'absolute', bottom: '-20px' } }}>
                                                                <InputTextField
                                                                    label={t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CODE')}
                                                                    onchange={(event) => {
                                                                        setErrorSearchVoucher("")
                                                                        setSearchVoucher(event.target.value)
                                                                    }}
                                                                    value={searchVoucher || ''}
                                                                    inputProps={{
                                                                        endAdornment: (
                                                                            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                                                                {voucherProduct?.voucherName &&
                                                                                    <Box sx={{ cursor: 'pointer' }} onClick={handleVoucherSelect} >
                                                                                        <IconClose />
                                                                                    </Box>
                                                                                }
                                                                                <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />
                                                                            </Box>
                                                                        ),
                                                                    }}
                                                                    helperText={errorSearchVoucher}
                                                                />
                                                            </Box>
                                                            <Box sx={{ minWidth: '100px', button: { width: '100%' } }}>
                                                                <MainButton
                                                                    onClick={onLoadVoucher}
                                                                    title={t('VOUCHER.POP_PRODUCT.FORM.VOUCHER_CHECKCODE')}
                                                                    type={'primary'}
                                                                    variant={'outlined'}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box margin={'8px 0'} padding={'8px 24px 0'} >
                                                        {renderVoucher()}
                                                    </Box>
                                                </Box>
                                                <Box className='d-flex justify-content-between w-100' sx={{ maxWidth: { xs: '245px' } }}>
                                                    <Box className='w-100' sx={{ maxWidth: { xs: '150px' } }}>
                                                        <InputTextField
                                                            disabled
                                                            inputTextAlign={'right'}
                                                            value={voucherProduct?.voucherPrice || ''}
                                                            onchange={(e: any) => console.log()}
                                                        />
                                                    </Box>
                                                    <Box sx={{ width: "80px" }}>
                                                        <BasicSelect
                                                            disabled
                                                            labelId="lb-unit"
                                                            selectId="sl-unit"
                                                            label=""
                                                            value={voucherProduct?.voucherPriceUnit || ''}
                                                            options={
                                                                _.map(units, (unit: string, index: number) => {
                                                                    return <MenuItem key={index} value={unit}>
                                                                        {unit === CURRENCY_UNIT.PERCENT ? '%' : t('CURRENCY_CHANGE.TH')}
                                                                    </MenuItem>
                                                                })
                                                            }
                                                        />
                                                    </Box>
                                                </Box>

                                            </Box>
                                        </Grid>

                                        <Grid item className={'w-100'}>
                                            <UseStyled.SummaryCost className='d-flex justify-content-end align-items-center px-3'>
                                                <Typography>{t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')}</Typography>
                                                <Typography className='pl-4 text-right' sx={{ minWidth: { sm: '200px' } }}>
                                                    {numberFormat(cal_sum(false, itemsBarcode[selectedIndex] || {}))} {t('CURRENCY_CHANGE.TH')}
                                                </Typography>
                                            </UseStyled.SummaryCost>
                                        </Grid>
                                        <Grid item className={'w-100'} >
                                            <UseStyled.GroupButton className='d-sm-flex align-items-center justify-content-end mt-2'>
                                                {props.group === 'MEDICINE' && <ButtonCustom onClick={onRemember} textButton={t('TREATMENT_SUMMARY.BUTTON.REMEMBER')} variant={'outlined'} className='mr-sm-3' style={{ margin: 0 }} />}
                                                <ButtonCustom onClick={props.onCancel} textButton={t('BUTTON.CANCEL')} variant={'outlined'} className='mr-sm-3' style={{ margin: 0 }} />
                                                {(props.group === 'MEDICINE') && (
                                                    <>
                                                        <FunctionPrint
                                                            content={() => componentRef.current}
                                                            docTitle={t('TX_INFORMATION.TITLE')}
                                                            removeIfram
                                                            trigger={() => <ButtonCustom
                                                                disabled={(!product.qty || product.price.toString() === '' || (product.qty > (stickerProduct?.stock || 0)) || product.discount > (product.price * product.qty)) || (props.group === 'MEDICINE' && !_.find(doctors, { userId: product.doctorId }))}
                                                                textButton={t('BUTTON.PRINT')}
                                                                variant={'outlined'}
                                                                className='mt-3 mt-sm-0 mr-sm-3'
                                                                style={{ margin: 0 }} />}
                                                            style={'@page { size: 302.36220472px 188.97637795px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                                                        />
                                                    </>
                                                )}
                                                <ButtonCustom onClick={onSubmitPopup} textButton={t('BUTTON.SAVE')} className='mt-3 mt-sm-0' style={{ margin: 0 }} />
                                            </UseStyled.GroupButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            {isShowAddProductChoice && (
                                <PopupManageProductAddForm
                                    type={typeAdd}
                                    items={items}
                                    isShow={isShowAddProductChoice}
                                    onSubmit={(data, type) => handleSubmit(data, type)}
                                    onCancel={() => setIsShowAddProductChoice(false)}
                                    maxLength={maxLength}
                                />
                            )}
                            {popPromotionExtra &&
                                <ModalSelectPromotionExtra
                                    promotionExtraValue={promotionExtraValue}
                                    onClose={onClerPopPromotionExtra}
                                    promotionExtra={promotionExtra}
                                    onChangePromotionExtra={onChangePromotionExtra}
                                    promotionBuy={promotionBuy}
                                />
                            }
                            {
                                popVoucherSelectLot &&
                                <ModalVoucherSelectLot
                                    onClose={onClerPopVoucherSelectLot}
                                    data={dataPopVoucherSelectLot}
                                    handleVoucherSelect={handleVoucherSelect}
                                />
                            }
                        </UseStyled.CustomPopupManageMedicine>
                    </Box>
            }
        />
    )
}
