import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TabContext from '@mui/lab/TabContext/TabContext'
import { Box, Grid } from '@mui/material'
import TabList from '@mui/lab/TabList'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { selectMe } from 'app/slice/user.slice'
import _, { result } from 'lodash'

/** API */
import { routeName } from 'routes/routes-name'
import AboutUs from 'api/admin/clinic-manage/about.api'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import HeaderCard from 'component/CardCustom/HeaderCard'
import AboutClinic from 'features/admin/clinic-management/about-clinic/form-about-clinic'
import AlertMsg from 'features/admin/clinic-management/alertmsg/form-alertmsg'
import FromPatientId from 'features/admin/clinic-management/patienid/from-patienid'
import FormPrefix from 'features/admin/clinic-management/prefix/form-prefix'
import FormApplication from './application/form-application';
import FormTreatment from './treatment/form-treatment'
import { notiError, notiSuccess } from 'component/notifications/notifications'

/** STYLE */
import * as TapStyle from 'features/admin/clinic-management/tabStyle'
import { colors } from 'constants/theme'
import InputCheckbox from 'component/Input/InputCheckbox'
import PaymentChannelsApi from 'api/setting/finances/payment-channels.api'
import Loading from 'component/Loading'


const initStateErrorMessage = {
  PACKAGE: ``,
  FIRSTNAME: ``,
  LASTNAME: ``,
  PHONE: ``,
  EMAIL: ``,
  COUNTRIES: ``,
  ADDRESS: ``,
  ADDRESS_TH: ``,
  CN_NUMBER: ``,
  BRANCH_CN_NUMBER: ``,
  EMPTY_PREFIX: ``,
  EMPTY_CHANNELS: ``
}

export enum BILL_TYPE {
  RECEIPT = 'RECEIPT',
  INVOICE = 'INVOICE'
}

export interface PaymentChannelsType {
  paymentChannelId: number
  paymentChannelName?: string
  sorting?: number
}

export interface BillPrefixesType {
  billPrefixId?: number | null
  prefix: string,
  billType: BILL_TYPE
  paymentChannels: PaymentChannelsType[]
}

export default function ClinicManagement() {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useSelector(selectMe)
  const [tab, setTab] = useState('about')
  const [tabForm, setTabForm] = useState('ABOUT')
  const [dataABout, setDataAbout] = useState<any>()
  const [branchCnNumbers, setBranchCnNumbers] = useState<any>()
  const [optionCnNumber, setOptionCnNumber] = useState('')
  const [cnNumber, setCnNumber] = useState('')
  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [billPrefixes, setBillPrefixes] = useState<BillPrefixesType[]>([]);
  const [medShowType, setMedShowType] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [pdShowType, setPdShowType] = useState<any>()
  const [formatAppointment, setFormatAppointment] = useState<any>()
  const [key, setKey] = useState(0)
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const loadAboutUS = async () => {
    setLoading(true)
    const res = await AboutUs.findAboutUs().finally(() => setLoading(false))
    setKey(key + 1)
    if (res.status === 200) {
      setDataAbout(res.data)
      setOptionCnNumber(res.data?.cnNumber.substr(0, 2))
      setCnNumber(res.data?.cnNumber)
      setBranchCnNumbers(res.data?.branchCnNumbers)
      const items: BillPrefixesType[] = []
      _.map(res.data?.billPrefixes, (bill: BillPrefixesType) => {
        items.push({
          billPrefixId: bill.billPrefixId || null,
          prefix: bill.prefix,
          billType: bill.billType,
          paymentChannels: _.map(bill.paymentChannels, (payment: PaymentChannelsType) => { return _.omit(payment, ['paymentChannelName', 'sorting']) })
        })
      })
      setBillPrefixes(items)
    }

  }

  useEffect(() => {
    if (!_.isEmpty(user)) {
      if (user?.isAdmin === '1') loadAboutUS()
      else history.push(routeName.welcome)
    }
  }, [user])

  const submit = async () => {
    setLoading(true)
    const ele: any[] = dataABout.branchCnNumbers.map((val: any) => ({ branchId: val.branchId, branchCnNumber: val?.branchCnNumber || '' }))
    const registerApps: any[] = dataABout.branchDefaultRegisterApps.map((val: any) => ({ branchId: val.branchId, isDefaultRegisApp: val.isDefaultRegisApp }))
    const newBillPrefixes = await handleEmptyBillPrefixes()
    const data = {
      clinicNameTh: dataABout.clinicNameTh,
      clinicNameEn: dataABout.clinicNameEn,
      phoneNo: dataABout.phoneNo,
      line: dataABout.line,
      website: dataABout.website,
      email: dataABout.email,
      package: dataABout.package,
      countryId: dataABout.countryId,
      provinceId: dataABout.provinceId,
      districtId: dataABout.districtId,
      subDistrictId: dataABout.subDistrictId,
      postcode: dataABout.postcode,
      addressTh: dataABout.addressTh,
      addressEn: dataABout.addressEn,
      hasCertificate: dataABout.hasCertificate,
      reBranchCnNumber: dataABout.reBranchCnNumber,
      enableApp: dataABout.enableApp,
      formatBranchCnNumber: dataABout.formatBranchCnNumber,
      notilineAppointmentCreate: dataABout.notilineAppointmentCreate,
      notilineAppointmentUpdate: dataABout.notilineAppointmentUpdate,
      notilineAppointmentPreday: dataABout.notilineAppointmentPreday,
      notilineAppointmentDay: dataABout.notilineAppointmentDay,
      notilineAppointmentStatus: dataABout.notilineAppointmentStatus,
      isFullReceipts: dataABout.isFullReceipts,
      useBranchHead: dataABout.useBranchHead,
      cnNumber: dataABout.cnNumber,
      branchCnNumbers: ele,
      billPrefixes: newBillPrefixes,
      medShowType: medShowType,
      pdShowType: pdShowType,
      formatAppointment: formatAppointment,
      branchDefaultRegisterApps: registerApps,
      snType: dataABout.snType,
      useWeb: dataABout.useWeb,
      useCrm: dataABout.useCrm,
      openEmailNotification: dataABout?.openEmailNotification || '1',
      openLineNotification: dataABout?.openLineNotification || '1',
      openSmsNotification: dataABout?.openSmsNotification || '1',
      openNotification: dataABout.openNotification,
      hasNoDf: dataABout.hasNoDf
    }

    if (!data.clinicNameTh || !data.clinicNameEn || !data.phoneNo) {
      setTabForm('ABOUT')
      setTab('about')
      setLoading(false)
      return setErrorMessage({
        ...errorMessage,
        ...{
          FIRSTNAME: !data.clinicNameTh ? t('CLINIC_MANAGEMENT.VALIDATION.CLINICNAMETH') : '',
          LASTNAME: !data.clinicNameEn ? t('CLINIC_MANAGEMENT.VALIDATION.CLINICNAMEEN') : '',
          PHONE: !data.phoneNo ? t('CLINIC_MANAGEMENT.VALIDATION.PHONE') : ''
        }
      })
    } else if (!data.cnNumber) {
      setTabForm('PATIENID')
      setTab('id')
      setLoading(false)
      return setErrorMessage({
        ...errorMessage,
        ...{
          CN_NUMBER: !data.cnNumber ? t('CLINIC_MANAGEMENT.VALIDATION.CN_NUMBER') : '',
          BRANCH_CN_NUMBER: data.branchCnNumbers.findIndex(((obj: any) => obj.branchCnNumber === '')) ? t('CLINIC_MANAGEMENT.VALIDATION.BRANCH_CN_NUMBER') : ''
        }
      })
    } else if (_.some(newBillPrefixes, (b: BillPrefixesType) => !b.billType || !b.prefix)) {
      setTabForm('PREFIX')
      setTab('prefix')
      setLoading(false)
      return setErrorMessage({
        ...errorMessage,
        ...{
          EMPTY_PREFIX: _.some(newBillPrefixes, (b: BillPrefixesType) => !b.prefix) ? t('CLINIC_MANAGEMENT.VALIDATION.EMPTY_PREFIX') : '',

        }
      })
    } else {
      await AboutUs.updateAboutUs(data)
        .then((res: any) => {
          if (res.status !== undefined && res.status === 201) {
            loadAboutUS()
            return notiSuccess(t(`CLINIC_MANAGEMENT.ERROR.SUCCESS`), '', null)
          } else {
            return notiError(t(`CLINIC_MANAGEMENT.ERROR.UN_SUCCESS`))
          }
        })
        .catch((e) => {
          const err = e.response.data
          const errorMessage = t(`CLINIC_MANAGEMENT.ERROR.${err.message}`);
          return notiError(errorMessage);
        }).finally(() => setLoading(false))
    }
  }

  const loadPaymentChannels = async () => {
    await PaymentChannelsApi.findAll({}).then((resp) => setPaymentMethods(resp.data))
  }

  const handleEmptyBillPrefixes = () => {

    const selectBillPre: number[] = []
    billPrefixes.forEach((item: BillPrefixesType) => {
      item?.paymentChannels?.forEach((info: PaymentChannelsType) => {
        selectBillPre.push(info.paymentChannelId)
      })
    })
    const newPaymentMethods: { paymentChannelId: number }[] = []
    paymentMethods.forEach((item: any) => {
      if (!selectBillPre.includes(item.paymentChannelId)) {
        newPaymentMethods.push({ paymentChannelId: item.paymentChannelId })
      }
    })

    const newData = [...billPrefixes]
    newData[0] = { ...newData[0], paymentChannels: [...newData[0]?.paymentChannels, ...newPaymentMethods] }

    return newData
  }

  useEffect(() => {
    loadPaymentChannels()
  }, [])

  return (
    <Box className='h-100 container-card overflow-hidden'>
      <Box className="d-flex flex-column h-100 overflow-hidden">
        <HeaderCard text={t('CLINIC_MANAGEMENT.TITLE.MANAGEMENT')} />
        <Box className='pt-0 d-flex flex-column overflow-hidden'>
          <HeaderLabel text={t('CLINIC_MANAGEMENT.TITLE.CLINIC_DENTOL')} goBack={routeName.clinicManagement} onSubmit={submit} />
          <Card className="border shadow-sm mx-4 mb-4 h-auto overflow-hidden" style={{ maxHeight: '100%' }}>
            <Card.Body className="h-100 p-0">
              <Grid lg={12} className={'w-100 h-100 overflow-hidden'}>
                <Box className="d-flex flex-column overflow-hidden h-100" sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: colors.extraLightBlue }}>
                      <TabList className={'px-3'} onChange={handleTabChange} aria-label="personel tabs" variant="scrollable" TabIndicatorProps={{ style: { backgroundColor: colors.themeSecondColor, color: colors.themeSecondColor } }}>
                        <TapStyle.CustomTab
                          tabForm={tabForm}
                          selectLabel={'ABOUT'}
                          label={t('CLINIC_MANAGEMENT.TAB.ABOUT')}
                          value="about"
                          onClick={(e) => setTabForm('ABOUT')}
                          className={`py-3  ${errorMessage.FIRSTNAME || errorMessage.LASTNAME || errorMessage.PHONE ? 'error' : ''}`}
                          style={{ paddingLeft: 12, paddingRight: 12 }} />
                        <TapStyle.CustomTab
                          tabForm={tabForm}
                          selectLabel={'PATIENID'}
                          label={t('CLINIC_MANAGEMENT.TAB.PATIENID')}
                          value="id" onClick={(e) => setTabForm('PATIENID')}
                          className={`py-3 ${errorMessage.CN_NUMBER || errorMessage.BRANCH_CN_NUMBER ? 'error' : ''}`}
                          style={{ paddingLeft: 12, paddingRight: 12 }} />
                        {/* <TapStyle.CustomTab
                          tabForm={tabForm}
                          selectLabel={'ALERTMSG'}
                          label={t('CLINIC_MANAGEMENT.TAB.ALERTMSG')}
                          value="msg"
                          onClick={(e) => setTabForm('ALERTMSG')}
                          className={'py-3'}
                          style={{ paddingLeft: 12, paddingRight: 12 }} /> */}
                        <TapStyle.CustomTab
                          tabForm={tabForm}
                          selectLabel={'PREFIX'}
                          label={t('CLINIC_MANAGEMENT.TAB.PREFIX')}
                          value="prefix"
                          onClick={(e) => setTabForm('PREFIX')}
                          className={'py-3'}
                          style={{ paddingLeft: 12, paddingRight: 12 }} />
                        <TapStyle.CustomTab
                          tabForm={tabForm}
                          selectLabel={'APPLICATION'}
                          label={t('CLINIC_MANAGEMENT.TAB.APPLICATION')}
                          value="application"
                          onClick={(e) => setTabForm('APPLICATION')}
                          className={'py-3'}
                          style={{ paddingLeft: 12, paddingRight: 12 }} />
                        <TapStyle.CustomTab
                          tabForm={tabForm}
                          selectLabel={'TREATMENT'}
                          label={t('CLINIC_MANAGEMENT.TAB.SETTING_OTHER')}
                          value="treatment"
                          onClick={(e) => setTabForm('TREATMENT')}
                          className={'py-3'}
                          style={{ paddingLeft: 12, paddingRight: 12 }} />
                      </TabList>
                    </Box>

                    <Box className=" custom-scroll" style={{ display: tabForm === 'ABOUT' ? 'block' : 'none', overflowY: 'auto' }}>
                      <AboutClinic
                        valArr={dataABout}
                        onchange={setDataAbout}
                        error={errorMessage}
                        resetErrorMessage={() => {
                          setErrorMessage(initStateErrorMessage)
                        }} />
                    </Box>
                    <Box className="h-100 custom-scroll" style={{ display: tabForm === 'PATIENID' ? 'block' : 'none', overflowY: 'auto' }}>
                      <FromPatientId
                        valArr={dataABout}
                        onchange={setDataAbout}
                        error={errorMessage}
                        optionCnNumber={optionCnNumber}
                        cnNumber={cnNumber}
                        branchCnNumbers={branchCnNumbers}
                        resetErrorMessage={() => {
                          setErrorMessage(initStateErrorMessage)
                        }} />
                    </Box>
                    <Box className="h-100 custom-scroll" style={{ display: tabForm === 'ALERTMSG' ? 'block' : 'none', overflowY: 'auto' }}>
                      <AlertMsg
                        valArr={dataABout}
                        onchange={setDataAbout} />
                    </Box>
                    <Box className="h-100 custom-scroll" style={{ display: tabForm === 'PREFIX' ? 'block' : 'none', overflowY: 'auto' }}>

                      <Box className="px-4 pt-4 pb-4">
                        <InputCheckbox
                          checked={dataABout?.isFullReceipts === '1' ? true : false}
                          onChange={(event) => {
                            setDataAbout({ ...dataABout, isFullReceipts: event.target.checked ? '1' : '0' })
                          }}
                          label={t(`CLINIC_MANAGEMENT.FORM.IS_FULL_RECEIPTS`)}
                        />
                      </Box>
                      <Box className="px-4 pt-4 pb-4">
                        {/* HEADER_BILL */}
                        <InputCheckbox
                          checked={dataABout?.useBranchHead === '1' ? true : false}
                          onChange={(event) => {
                            setDataAbout({ ...dataABout, useBranchHead: event.target.checked ? '1' : '0' })
                          }}
                          label={t(`CLINIC_MANAGEMENT.FORM.USE_BRANCH_HEAD`)}
                        />
                      </Box>

                      <FormPrefix
                        paymentMethods={paymentMethods}
                        billPrefixes={billPrefixes}
                        callbackBillPrefixes={(item: BillPrefixesType[]) => setBillPrefixes(item)}
                        errorMessage={{ EMPTY_PREFIX: errorMessage.EMPTY_PREFIX, EMPTY_CHANNELS: errorMessage.EMPTY_CHANNELS }}
                        resetErrorMessage={() => {
                          setErrorMessage(initStateErrorMessage)
                        }} />
                    </Box>
                    <Box className="h-100 custom-scroll" style={{ display: tabForm === 'APPLICATION' ? 'block' : 'none', overflowY: 'auto' }}>
                      <FormApplication
                        package={dataABout?.package}
                        enableApp={dataABout?.enableApp}
                        branchDefaultRegisterApps={dataABout?.branchDefaultRegisterApps}
                        callbackEnableApp={(item) => {
                          setDataAbout({
                            ...dataABout, enableApp: item,
                            ...(item === "0" ?
                              { branchDefaultRegisterApps: (dataABout?.branchDefaultRegisterApps || []).map((val: any) => ({ ...val, isDefaultRegisApp: "0" })) }
                              : (item === "1" && dataABout?.package !== 'STANDARD') ? { branchDefaultRegisterApps: (dataABout?.branchDefaultRegisterApps || []).map((val: any) => ({ ...val, isDefaultRegisApp: "1" })) }
                                : {})
                          })
                        }}
                        callbackRegisterApps={(item) => setDataAbout({ ...dataABout, branchDefaultRegisterApps: item })}
                        useWeb={dataABout?.useWeb || '0'}
                        callbackUseWeb={(item) => setDataAbout({ ...dataABout, useWeb: item })}
                        dataABout={dataABout}
                        setDataAbout={setDataAbout}
                      // 
                      />
                    </Box>

                    <Box className="h-100 custom-scroll" style={{ display: tabForm === 'TREATMENT' ? 'block' : 'none', overflowY: 'auto' }}>
                      <FormTreatment
                        key={key}
                        openNotification={dataABout?.openNotification || '0'}
                        formatAppointment={dataABout?.formatAppointment}
                        medShowType={dataABout?.medShowType}
                        pdShowType={dataABout?.pdShowType}
                        callbackMedShowType={(item) => setMedShowType(item)}
                        callbackPdShowType={(item) => setPdShowType(item)}
                        callbackFormatAppointment={(item) => setFormatAppointment(item)}
                        callbackOpenNotification={(item) => setDataAbout({ ...dataABout, openNotification: item })}
                        dataABout={dataABout}
                        setDataAbout={setDataAbout}
                      />
                    </Box>
                  </TabContext>
                </Box>
              </Grid>
            </Card.Body>
          </Card>
        </Box>
        <Loading show={loading} />
      </Box>
    </Box>
  )
}
