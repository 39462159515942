import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import _ from 'lodash';

/** UTILS || SLICE || SCRIPT */
import { setToothChart, toothChart, treatment, isEditTootChart } from 'app/slice/treatment.slice'
import { handlePositionSelected, handleSelected, renderOperative, renderOperativeHistory } from 'component/ToothChart/script';

/** API */
/** CONSTANTS */
import { colors } from 'constants/theme'

/** COMPONENT */
import { TeethProps, TEETH_OPERATIVES } from 'component/ToothChart/ToothChart'
import PositionTeeth, { QUARTER_NAME_TYPE } from 'assets/toothChart/Operative/PositionTeeth'
import IconXray from 'component/ToothChart/IconXray'
import FixedTeeth from 'assets/toothChart/fixture/74.svg'
import Bridge from 'assets/toothChart/bridge/Bridge';

/** STYLE */
import { DivTeethL, TextNumberL } from 'component/ToothChart/style'
import useWindowSize from 'utils/useWindowSize';

const Div = styled(`div`)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: 'auto',
    top: '1.4%',
    maxWidth: '65px',
}))

export default function Teeth41(props: TeethProps) {
    const teeth = useSelector(toothChart)
    const teethNumber = props.teethNumber
    const historyToothChart = props.historyToothChart
    const treatments = useSelector(treatment)
    const isEditTootCharts: any = useSelector(isEditTootChart)
    const dispatch = useDispatch()

    const [isSelected, setIsSelected]: any = useState(teeth[props.teethNumber]?.isSelect || false)
    const [positions, setPositions]: any = useState([])
    const [treatmentHistory, setTreatmentHistory]: any = useState([{}])

    const [isLowerTeeth, setIsLowerTeeth]: any = useState(false)
    const [isTooth, setIsTooth]: any = useState(false)
    const [isXRay, setIsXRay]: any = useState(false)
    const [isToothRoot, setIsToothRoot]: any = useState(false)
    const [isOrthodontics, setIsOrthodontics]: any = useState(false)
    const [isExtract, setIsExtract]: any = useState(false)
    const [isFixture, setIsFixture]: any = useState(false)
    const [isBridge, setIsBridge]: any = useState(false)

    const [color, setColor]: any = useState(``)
    const [styleSvg, setStyleSvg]: any = useState({ width: 52.43, height: 100.62, viewBox: "0 0 52.43 100.62" })

    const [isEditTeeth, setIsEditTeeth] = useState<boolean>(false);
    const [width] = useWindowSize()
    useEffect(() => {
        const operative = props.operative || ``
        const valColor = props.color || colors.themeSecondColor
        const selected = teeth[teethNumber]?.isSelect || false
        dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: selected, color: valColor } }))
        setIsSelected(selected)

        renderOperative(selected, dispatch, { teethNumber, teeth, color: valColor, operative, setColor, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth })


        if (props.type === 'treatment') {
            renderOperativeHistory(selected, { teethNumber, historyToothChart, setTreatmentHistory, setIsLowerTeeth, setIsXRay, setIsToothRoot, setIsOrthodontics, setIsExtract, setIsFixture, setIsBridge, setIsTooth, setPositions, isEditTootCharts })
        }

        if (_.isEmpty(props.historyToothChart) && !teeth[props.teethNumber]?.isSelect) setPositions([])

        return setStyleSvg({ width: 52.43, height: 100, viewBox: "0 0 52.43 100.62" })
    }, [teeth[props.teethNumber]?.isSelect, props.operative, props.color, props.historyToothChart])

    useEffect(() => {
        const treatmentTooth = props.type === 'treatment' ? treatments.summeryTreatments[isEditTootCharts] : treatments.patientDiagnosis[isEditTootCharts]
        const teethJson = treatmentTooth?.teethJson
        if (isEditTootCharts !== null) {
            if (teethJson) {
                const tooths = JSON.parse(teethJson)
                setIsEditTeeth(_.some(tooths, { teeth: props.teethNumber }))
                if (props.type === 'dx') setPositions(_.get(_.find(tooths, { teeth: teethNumber }), 'positions'))
            } else setIsEditTeeth(false)
        }
    }, [isEditTootCharts]);

    useEffect(() => {
        if (isSelected) {
            dispatch(setToothChart({ key: teethNumber, value: { ...(teeth[teethNumber] || {}), teeth: teethNumber, isSelect: teeth[props.teethNumber]?.isSelect || false, color: '' } }))
            if (teeth[props.teethNumber]?.isSelect) {
                setIsSelected(true)
            } else {
                setIsSelected(false)
            }
        }
    }, [props.historyToothChart]);

    useEffect(() => {
        if (props.type === 'dx' && !isSelected && isEditTootCharts === null) setPositions([])
    }, [isSelected, isEditTootCharts]);

    return (
        <Div>
            <TextNumberL>74</TextNumberL>
            <PositionTeeth quarter={QUARTER_NAME_TYPE.Q3} position={positions} isSelected={isSelected} teethNumber={teethNumber} handleChangePosition={(position) => handlePositionSelected(teeth, isSelected, teethNumber, dispatch, setToothChart, setPositions, position)} />
            <DivTeethL className={`md div-teeth-${props.teethNumber} `}>
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isXRay && props.type === 'treatment') && <IconXray position='L' />) || ''}
                {!isSelected && isEditTootCharts !== null && isEditTeeth ? '' : ((isBridge && props.type === 'treatment') && <Bridge position='L' width={100} />) || ''}
                {(isFixture && props.type === 'treatment') ? <img src={FixedTeeth} className={`teeth-fixture ${isSelected ? 'active' : ''}`} onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} alt="" /> :
                    <svg className={`${isSelected ? 'active' : ''}`} xmlns="https://www.w3.org/2000/svg" onClick={() => handleSelected(teeth, dispatch, setIsSelected, teethNumber, setPositions)} width={styleSvg.width} height={styleSvg.height} viewBox={styleSvg.viewBox} >
                        <g data-name="Group 12344">

                            {(isBridge && props.type === 'treatment') ?
                                <g></g>
                                : ""}

                            <path
                                data-name="Path 5567"
                                d="M36.7337 91.4363C40.1931 74.5678 34.0149 59.0096 33.5376 45.3467C33.4535 42.9384 29.8271 42.8553 29.6286 45.2574C28.5608 58.1975 21.5408 68.9941 22.7933 87.1458C23.064 91.0601 17.1119 91.6836 16.5083 87.8054L16.4921 87.7035C15.1341 78.8542 13.5088 69.9849 13.0875 61.0727C12.48 48.1981 8.34017 36.4314 3.28168 24.8018C0.965242 19.4735 -1.28692 13.1422 2.90348 8.73547C8.03761 3.3368 14.9138 -0.195949 23.1296 4.75888C26.7535 6.94441 30.4612 6.52388 34.0473 3.20512C39.2447 -1.60529 44.4919 0.672345 49.0407 4.714C51.3714 6.78666 55.7966 60.1589 47.4867 83.4582C46.1287 87.2661 45.0468 91.3727 41.9744 94.254C39.742 96.3496 36.1248 94.4016 36.7337 91.4363Z"
                                transform={width > 1440 ? "translate(0 -10)" : "translate(0 -5)"}
                                style={{ fill: (isFixture && props.type === 'treatment') ? 'none' : '#e3d1b4' }}
                            />
                            {(isLowerTeeth && props.type === 'treatment') ?
                                <path
                                    data-name="Path 5567"
                                    d="M36.7337 91.4363C40.1931 74.5678 34.0149 59.0096 33.5376 45.3467C33.4535 42.9384 29.8271 42.8553 29.6286 45.2574C28.5608 58.1975 21.5408 68.9941 22.7933 87.1458C23.064 91.0601 17.1119 91.6836 16.5083 87.8054L16.4921 87.7035C15.1341 78.8542 13.5088 69.9849 13.0875 61.0727C12.48 48.1981 8.34017 36.4314 3.28168 24.8018C0.965242 19.4735 -1.28692 13.1422 2.90348 8.73547C8.03761 3.3368 14.9138 -0.195949 23.1296 4.75888C26.7535 6.94441 30.4612 6.52388 34.0473 3.20512C39.2447 -1.60529 44.4919 0.672345 49.0407 4.714C51.3714 6.78666 55.7966 60.1589 47.4867 83.4582C46.1287 87.2661 45.0468 91.3727 41.9744 94.254C39.742 96.3496 36.1248 94.4016 36.7337 91.4363Z"
                                    transform={width > 1440 ? "translate(0 -10)" : "translate(0 -5)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.lowerTeeth) || (isLowerTeeth && (treatmentHistory[TEETH_OPERATIVES.LOWER_TEETH]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor }}
                                /> : ''}
                            <g data-name="Group 12344">
                                <path
                                    data-name="Path 5568"
                                    d="M47.8191 33.2684C54.6536 28.1539 52.596 17.7436 52.6115 17.2427C49.1234 0.940062 38.3948 -4.28734 30.337 5.30741C25.5446 11.0128 23.0597 3.18046 18.2071 2.88829C6.65532 2.18969 1.96662 12.9457 1.89979 13.1279C1.44859 14.4627 1.11965 15.8358 0.917055 17.2301C0.748334 18.2462 0.592344 19.3498 0.491229 20.4731C0.116751 24.1791 3.19821 34.9323 10.6362 39.8031C14.7725 42.512 18.6455 40.5215 27.8767 41.3804C41.0446 42.6067 43.3452 36.617 47.8191 33.2684Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer1) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer1 || color)) || colors.tooth.defaultLayer1 }}
                                />
                                <path
                                    data-name="Path 5569"
                                    d="M47.3708 34.1397C53.9417 29.2211 51.9639 19.2138 51.9792 18.732C48.626 3.05819 38.3113 -1.96698 30.5628 7.25682C25.9554 12.7421 23.5661 5.21296 18.9009 4.93058C7.79472 4.2602 3.28668 14.6012 3.2226 14.7762C2.78875 16.0595 2.47254 17.3796 2.27788 18.7202C2.11543 19.6975 1.96572 20.7582 1.86911 21.8377C1.50815 25.4011 4.47046 35.7401 11.6226 40.4225C15.5994 43.0266 19.3222 41.1137 28.197 41.9392C40.8577 43.1173 43.0703 37.3585 47.3708 34.1397Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer2) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer2 || color)) || colors.tooth.defaultLayer2 }}
                                />
                                <path
                                    data-name="Path 5570"
                                    d="M47.1272 24.3609C47.1272 24.2843 47.1272 24.2075 47.1191 24.1309C47.1135 24.0981 47.1114 24.0648 47.1129 24.0315C47.0182 22.7006 46.7149 21.8082 46.7266 21.4264C44.0702 9.00928 35.8983 5.02817 29.7609 12.3353C26.1107 16.6811 24.2178 10.7165 20.5216 10.4933C11.723 9.96183 8.15225 18.1543 8.10111 18.2924C7.75738 19.3092 7.50696 20.3551 7.35293 21.4172C7.22478 22.1905 7.10583 23.0315 7.02901 23.8863C6.58438 28.2781 7.02372 33.0948 11.7473 32.6157C11.8194 32.6228 11.8921 32.6186 11.9629 32.6032C12.4547 32.5426 14.1231 32.6825 16.2606 32.9233C20.3627 33.3772 26.2036 34.1649 28.7619 34.4032C31.5754 34.6766 37.4009 33.6598 41.7448 31.4834C44.6937 30 46.9522 27.9805 47.1181 25.462L47.1236 25.4087C47.1411 25.0391 47.1423 24.6899 47.1272 24.3609Z"
                                    transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"}
                                    style={{ fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && colors.tooth.defaultLayer3) || ((isTooth && props.type === 'treatment') && (treatmentHistory[TEETH_OPERATIVES.TOOTH]?.color || colors.tooth.layer3 || color)) || colors.tooth.defaultLayer3 }}
                                />

                                {(isToothRoot && props.type === 'treatment') ?
                                    <>
                                        <path data-name="Path 6577" d="M25.9353 2.60692C25.9452 2.8685 26.084 9.91179 26.3003 16.0354C26.6155 24.9833 23.153 65.6735 22.918 65.9639C24.0523 59.5782 26.1472 22.5154 25.8766 16.0354C25.4967 9.76137 24.655 2.34313 24.6191 2.08984C24.8696 2.09869 25.6726 2.5483 25.9353 2.60692Z" transform={width > 1440 ? "translate(17 16)" : "translate(17 22)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                        <path data-name="Path 6578" d="M2.66762 7.61914C2.65766 7.88072 2.20916 13.3744 1.99348 19.4975C1.67826 28.4454 1.38532 61.7998 1.62035 62.0918C1.59436 62.3207 1.91385 28.9536 2.47516 19.6899C2.85454 13.417 3.71172 7.91611 3.74766 7.66283C3.49715 7.67112 2.90154 7.61914 2.66762 7.61914Z" transform={width > 1440 ? "translate(17 16)" : "translate(17 22)"} style={{

                                            fill: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor,
                                            stroke: ((!isSelected && isEditTootCharts !== null && isEditTeeth) && 'transparent') || (isToothRoot && (treatmentHistory[TEETH_OPERATIVES.TOOTH_ROOT]?.color || colors.themeSecondColor || color)) || colors.themeSecondColor
                                        }} />
                                    </>
                                    : ''}
                                {(isExtract && props.type === 'treatment') ?
                                    <>
                                        <g data-name="Group 9472">
                                            <path data-name="Path 6581" d="m33.013 7.5-26.767 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                            <path data-name="Path 6582" d="m7.012 7.5 26.776 72" transform="translate(-4.317 -5.571)" style={{ fill: 'none', stroke: '#b00020', strokeLinecap: 'round', strokeWidth: '3px' }} />
                                        </g>
                                    </>
                                    : ''}

                                {((!isSelected && isEditTootCharts !== null && isEditTeeth) && '') || (isOrthodontics && props.type === 'treatment') &&
                                    <>
                                        <svg width="54" height="100" viewBox="0 0 54 100" fill="none" xmlns="http://www.w3.org/2000/svg">


                                        </svg>

                                        <defs>
                                            <linearGradient id="paint0_linear_4107_155299" x1="27.9776" y1="19.0345" x2="25.0657" y2="35.6551" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#B0B1B1" />
                                                <stop offset="1" stopColor="#585959" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_4107_155299" x1="27.9854" y1="31.5564" x2="27.9854" y2="25.392" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#C1C4C5" />
                                                <stop offset="1" stopColor="#9E9E9E" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9369">
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M37.0176 95.8435C40.4246 79.2305 34.34 63.9081 33.8699 50.4523C33.7871 48.0804 30.2156 47.9986 30.0201 50.3643C28.9685 63.1083 22.0549 73.7413 23.2885 91.618C23.555 95.473 17.6931 96.0871 17.0987 92.2676L17.0827 92.1672C15.7452 83.452 14.1446 74.7171 13.7297 65.9399C13.1314 53.2605 9.05429 41.672 4.07245 30.2186C1.79111 24.971 -0.426921 18.7357 3.69998 14.3958C8.75631 9.07888 15.5283 5.59966 23.6196 10.4794C27.1886 12.6318 30.8401 12.2177 34.3719 8.94919C39.4905 4.21168 44.6582 6.4548 49.1381 10.4352C51.4335 12.4765 55.7916 65.04 47.6077 87.9863C46.2703 91.7365 45.2048 95.7808 42.1789 98.6185C39.9803 100.682 36.4179 98.7638 37.0176 95.8435Z" fill="#E3D1B4" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M47.9375 32.6491C54.6684 27.612 52.642 17.3594 52.6572 16.8662C49.222 0.810554 38.656 -4.33764 30.7202 5.11172C26.0005 10.7306 23.5532 3.01701 18.7742 2.72926C7.3974 2.04125 2.77974 12.6343 2.71393 12.8138C2.26956 14.1283 1.94561 15.4806 1.74608 16.8538C1.57992 17.8545 1.42629 18.9414 1.32671 20.0476C0.957904 23.6974 3.99267 34.2877 11.318 39.0847C15.3916 41.7526 19.2059 39.7922 28.2973 40.6381C41.2656 41.8458 43.5313 35.9469 47.9375 32.6491Z" fill="#EFEDE7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M47.4934 33.5062C53.9648 28.6622 52.0169 18.8066 52.032 18.332C48.7295 2.89573 38.5711 -2.0533 30.9401 7.03074C26.4025 12.4329 24.0494 5.01785 19.4549 4.73975C8.51699 4.07953 4.07725 14.2638 4.01415 14.4361C3.58687 15.7 3.27545 17.0001 3.08374 18.3204C2.92376 19.2829 2.77631 20.3275 2.68117 21.3907C2.32568 24.9001 5.2431 35.0824 12.2868 39.6939C16.2034 42.2585 19.8698 40.3746 28.6101 41.1876C41.079 42.3479 43.2581 36.6763 47.4934 33.5062Z" fill="#F7F7F7" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M47.2636 23.8763C47.2636 23.8008 47.2636 23.7252 47.2557 23.6497C47.2501 23.6174 47.2481 23.5846 47.2495 23.5519C47.1563 22.2411 46.8576 21.3622 46.8692 20.9863C44.253 8.75729 36.2049 4.8365 30.1605 12.0329C26.5657 16.3128 24.7014 10.4386 21.0612 10.2188C12.3959 9.6954 8.87929 17.7637 8.82892 17.8998C8.4904 18.9011 8.24377 19.9312 8.09208 20.9772C7.96587 21.7388 7.84872 22.5671 7.77307 23.4089C7.33517 27.7342 7.76786 32.4779 12.4199 32.006C12.4909 32.013 12.5625 32.0089 12.6322 31.9937C13.1166 31.934 14.7597 32.0718 16.8648 32.309C20.9047 32.756 26.6571 33.5318 29.1766 33.7665C31.9476 34.0357 37.6848 33.0343 41.9628 30.8909C44.8671 29.43 47.0913 27.441 47.2548 24.9607L47.2602 24.9082C47.2774 24.5442 47.2786 24.2003 47.2636 23.8763Z" fill="white" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M34.5523 14H21.4477C19.5436 14 18 15.5436 18 17.4477V30.5523C18 32.4564 19.5436 34 21.4477 34H34.5523C36.4564 34 38 32.4564 38 30.5523V17.4477C38 15.5436 36.4564 14 34.5523 14Z" fill="#C1C4C5" />
                                            {/* <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M34.5537 14.8623H21.449C20.021 14.8623 18.8633 16.02 18.8633 17.4481V30.5527C18.8633 31.9808 20.021 33.1385 21.449 33.1385H34.5537C35.9818 33.1385 37.1394 31.9808 37.1394 30.5527V17.4481C37.1394 16.02 35.9818 14.8623 34.5537 14.8623Z" stroke="#C1C4C5" /> */}
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M26.4825 33.1048C26.1377 33.7289 29.3716 33.4203 31.7971 33.3893C35.4068 33.3134 37.4668 34.0512 37.4323 25.7182C37.526 22.7613 37.4344 19.8014 37.1582 16.8559C36.2428 15.9664 37.3995 25.882 35.0568 30.2433C32.8469 33.3927 27.0737 32.436 26.4825 33.1048Z" fill="#B0B1B1" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M31.0776 17.4727H24.1822C23.7062 17.4727 23.3203 17.8586 23.3203 18.3346V20.0584C23.3203 20.5344 23.7062 20.9203 24.1822 20.9203H31.0776C31.5536 20.9203 31.9395 20.5344 31.9395 20.0584V18.3346C31.9395 17.8586 31.5536 17.4727 31.0776 17.4727Z" fill="#9E9E9E" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M31.4253 17.4727H24.5299C24.0539 17.4727 23.668 17.8586 23.668 18.3346V20.0584C23.668 20.5344 24.0539 20.9203 24.5299 20.9203H31.4253C31.9013 20.9203 32.2872 20.5344 32.2872 20.0584V18.3346C32.2872 17.8586 31.9013 17.4727 31.4253 17.4727Z" fill="url(#paint0_linear_4107_155299)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M31.4331 25.5791H24.5377C24.0617 25.5791 23.6758 25.965 23.6758 26.441V28.1649C23.6758 28.6409 24.0617 29.0268 24.5377 29.0268H31.4331C31.9091 29.0268 32.295 28.6409 32.295 28.1649V26.441C32.295 25.965 31.9091 25.5791 31.4331 25.5791Z" fill="#CBCCCC" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M31.4331 25.2334H24.5377C24.0617 25.2334 23.6758 25.6193 23.6758 26.0953V27.8192C23.6758 28.2952 24.0617 28.6811 24.5377 28.6811H31.4331C31.9091 28.6811 32.295 28.2952 32.295 27.8192V26.0953C32.295 25.6193 31.9091 25.2334 31.4331 25.2334Z" fill="url(#paint1_linear_4107_155299)" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.29688 22.2207C13.6555 22.9203 24.104 25.3257 52.6587 22.3766" stroke="#B5B7B7" strokeLinecap="round" />
                                            <path transform={width > 1440 ? "translate(0 -20)" : "translate(0 -10)"} d="M1.29688 21.6914C12.7862 22.4567 26.6378 24.8336 52.6587 21.7156" stroke="#BABBBB" strokeLinecap="round" />
                                        </g>
                                    </> || ""}

                                {(isFixture && props.type === 'treatment') ?
                                    <>
                                        <defs>
                                            <linearGradient id="o7e8i1h11a" x1=".209" y1="1.059" x2="1.176" y2="-.286" gradientUnits="objectBoundingBox">
                                                <stop offset={0} stopColor="#e5e5e5" />
                                                <stop offset={1} stopColor="#b4b5b5" />
                                            </linearGradient>
                                            <linearGradient id="jpfbjf47sb" x1="-.342" y1="1.081" x2="1.352" gradientUnits="objectBoundingBox">
                                                <stop offset={0} stopColor="#e5e5e5" />
                                                <stop offset={1} stopColor="#b0b1b1" />
                                            </linearGradient>
                                        </defs>
                                        <g data-name="Group 9510">
                                            <path data-name="Path 5368" d="M498.757 497.957a220.684 220.684 0 0 1-.7 4.234c-.206 1.186-.419 2.32-.619 3.39a77.341 77.341 0 0 0-2.443 10.564 7.1 7.1 0 0 1-1.869 1.953s-3.545 3.042-6.445 2.688-6.9-.9-9.777-4.647l-.007-.007s-7.863-21.855-1.173-24.246 20.779-.844 20.779-.844 3.279.231 2.254 6.915z" transform="translate(-473.026 -490.552)" style={{ fill: '#efede7' }} />
                                            <path data-name="Path 5369" d="M497.934 499.715c-.2 1.3-.413 2.565-.625 3.771-.184 1.056-.373 2.066-.551 3.019a68.936 68.936 0 0 0-2.176 9.408 6.313 6.313 0 0 1-1.664 1.739s-3.157 2.71-5.74 2.394-6.147-.8-8.707-4.138l-.006-.006s-7-19.464-1.045-21.594 18.505-.751 18.505-.751 2.922.206 2.009 6.158z" transform="translate(-473.734 -491.465)" style={{ fill: '#f7f7f7' }} />
                                            <path data-name="Path 5370" d="M497.54 503.023a157.284 157.284 0 0 1-.55 3.316c-.162.929-.329 1.818-.485 2.655a60.606 60.606 0 0 0-1.914 8.273 5.558 5.558 0 0 1-1.464 1.53s-2.777 2.382-5.048 2.1-5.407-.7-7.658-3.639l-.005-.005s-6.158-17.118-.918-18.991 16.275-.661 16.275-.661 2.57.187 1.767 5.422z" transform="translate(-474.575 -492.924)" style={{ fill: colors.white }} />
                                            <g data-name="Group 9524">
                                                <g data-name="Path 6490" transform="translate(3.284 30.16)" style={{ fill: 'url(#o7e8i1h11a)' }}>
                                                    <path d="M13.874 44.996H5.203c-.776 0-1.52-1.975-2.212-5.87C1.818 32.515.845 20.603.1 3.718c0-.976.177-1.896.499-2.586C.909.467 1.312.1 1.734.1h15.609c.422 0 .825.367 1.135 1.033.322.69.499 1.61.499 2.59 0 39.283-4.25 41.273-5.103 41.273z" style={{ stroke: 'none' }} />
                                                    <path d="M1.734.2C.994.2.201 1.614.2 3.719c.745 16.879 1.717 28.784 2.89 35.389.893 5.036 1.707 5.788 2.113 5.788h8.671c.183 0 .564-.11 1.05-.85.634-.963 1.211-2.627 1.715-4.946 1.485-6.83 2.238-18.732 2.238-35.377 0-2.107-.793-3.523-1.534-3.523H1.734m0-.2h15.609c.957 0 1.734 1.667 1.734 3.723 0 39.288-4.245 41.373-5.203 41.373H5.203c-.958 0-3.469-2.085-5.203-41.373C0 1.667.776 0 1.734 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                                                </g>
                                                <g data-name="Path 6489" transform="translate(1 24.957)" style={{ strokeLinejoin: 'round', fill: 'url(#jpfbjf47sb)' }}>
                                                    <path d="M.945 7.569H.938C.912 7.52.892 7.32.865 7.047.795 6.354.653 4.913.1 1.905.103.909.577.1 1.16.1h22.038c.583 0 1.057.81 1.06 1.807-.293 2.008-.447 3.306-.549 4.164-.175 1.486-.187 1.486-.385 1.486a3.548 3.548 0 0 1-.126-.001H1.16c-.062 0-.112.005-.153.008-.025.003-.046.005-.062.005z" style={{ stroke: 'none' }} />
                                                    <path d="M1.16.2C.643.2.206.975.2 1.896c.552 3.005.695 4.447.764 5.14.019.186.034.341.049.427.039-.003.088-.007.147-.007h22.038l.126.001a.404.404 0 0 0 .064-.003c.068-.093.13-.614.222-1.394.102-.859.255-2.155.547-4.16-.004-.923-.442-1.7-.96-1.7H1.16m0-.2h22.038c.64 0 1.16.857 1.16 1.914-.873 5.981-.52 5.742-1.16 5.742H1.16c-.64 0 0 .568-1.16-5.742C0 .857.52 0 1.16 0z" style={{ fill: '#dcdcdc', stroke: 'none' }} />
                                                </g>
                                                <path data-name="Path 6491" d="M5.625 23.16s18.057-.9 18.962-1.957" transform="translate(-2.227 15.296)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                                <path data-name="Path 6492" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-1.94 20.495)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                                <path data-name="Path 6493" d="M7.08 22.925s14.6-.592 15.5-1.645" transform="translate(-1.492 43.022)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                                <path data-name="Path 6584" d="M5.649 23.213S23.1 22.255 24 21.2" transform="translate(-1.684 25.038)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                                <path data-name="Path 6585" d="M6.115 23.237S23.1 22.255 24 21.2" transform="translate(-1.684 31.038)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                                <path data-name="Path 6586" d="M6.643 23.2s16.036-.854 16.941-1.907" transform="translate(-1.684 37.038)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                                <path data-name="Path 6587" d="M8.144 22.87s12.509-.373 13.415-1.426" transform="translate(-1.493 48.66)" style={{ fill: 'none', stroke: '#b1b3b4', strokeLinecap: 'round' }} />
                                            </g>
                                        </g>
                                    </> : ""}
                            </g>
                        </g>
                    </svg>}
            </DivTeethL>
        </Div>
    )
}
