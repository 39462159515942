import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportProps } from 'features/report/report-constants'
import TableRowExport from 'component/Pdf/TableRowExport'
import { Card, Col, Row, Dropdown } from 'react-bootstrap'
import { Box, MenuItem, Divider, Typography, Grid } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { UseSnCode } from 'utils/useSnCode'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import ClinicApi, { BranchInterface, BillInterface } from 'api/master/clinic.api'
import ReportApi, { FindAllReportInterface } from 'api/report.api'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import FilterSelect from 'component/Select/FilterSelect'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import Loading from 'component/Loading'
import { useDownloadExcel } from 'component/Excel/hooks/useExcel'
import FucntionPrint from 'component/Print/print'

/** REPORT */
import IncomeList from './IncomeList'
import IncomeType, { IncomeSum } from './IncomeType'
import IncomeOverdue from './IncomeOverdue'

/** CONSTANTS */
import { getBranch, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { colors } from 'constants/theme'
import { dateTimeToView, dateToView } from 'utils/date.utils'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { routeName } from 'routes/routes-name'
import SelectCheckBoxInput from 'new-components/inputs/select_checkbox_input'
import MainButton from 'new-components/buttons/main-button'
import { KEY_STORAGE } from 'constants/common'

export default function IncomeReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const componentRef = useRef<HTMLDivElement>(null)
  const componentRefPDF = useRef<HTMLDivElement>(null)
  const momentNowRange = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
  const momentDate = () => momentNowRange
  const branchInfo = localStorage.getItem(KEY_STORAGE.CLINIC_INFO)

  const [loading, setLoading] = useState<boolean>(true)

  const [branches, setBranches] = useState<any[]>([])
  const [branchId, setBranchId] = useState<any>(getBranch())
  const [rangeDate, setRangeDate] = useState<string[]>(momentDate)
  const [receiptPrefix, setReceiptPrefix] = useState<any[]>([])
  const [prefix, setPrefix] = useState<string>('ALL')

  const [incomePage, setIncomePage] = useState<number>(1)
  const [incomePageLimit, setIncomePageLimit] = useState<number>(10)
  const [incomeRowCount, setIncomeRowCount] = useState<number>(0)
  const [incomeSortBy, setIncomeSortBy] = useState<string>('createAt')
  const [incomeSortType, setIncomeSortType] = useState<string>('ASC')
  const [incomeData, setIncomeData] = useState<any[]>([])
  const [incomeExport, setIncomeExportData] = useState<any[]>([])

  const [incomeTypePage, setIncomeTypePage] = useState<number>(1)
  const [incomeTypePageLimit, setIncomeTypePageLimit] = useState<number>(10)
  const [incomeTypeRowCount, setIncomeTypeRowCount] = useState<number>(0)
  const [incomeTypeSortBy, setIncomeTypeSortBy] = useState<string>('createAt')
  const [incomeTypeSortType, setIncomeTypeSortType] = useState<string>('ASC')
  const [incomeTypeData, setIncomeTypeData] = useState<any[]>([])
  const [incomeTypeExport, setIncomeTypeExportData] = useState<any[]>([])

  const [incomeOverduePage, setIncomeOverduePage] = useState<number>(1)
  const [incomeOverduePageLimit, setIncomeOverduePageLimit] = useState<number>(10)
  const [incomeOverdueRowCount, setIncomeOverdueRowCount] = useState<number>(0)
  const [incomeOverdueSortBy, setIncomeOverdueSortBy] = useState<string>('')
  const [incomeOverdueSortType, setIncomeOverdueSortType] = useState<string>('')
  const [incomeOverdueData, setIncomeOverdueData] = useState<any[]>([])
  const [incomeOverdueExport, setIncomeOverdueExportData] = useState<any[]>([])

  const [billStatus, setBillStatus] = useState<string>('ALL')

  const optionPaymentChanel = [
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.CASH'), id: 1, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.TRANSFER'), id: 2, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.CREDIT'), id: 3, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.DEBIT'), id: 4, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.TREATMENTRIGHT'), id: 5, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.ADVANCE'), id: 6, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.VOUCHER'), id: 7, },
    { name: t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.PROMOTION'), id: 8, }
  ]
  const [paymentChanelSelect, setPaymentChanelSelect] = useState<number[]>(optionPaymentChanel.map((item) => item.id))

  const verifyPermission = async () => {
    const condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 200
    }
    ReportApi.reportList(condition).then(({ data }) => {
      const check = _.find(data, (val: any) => String(val.reportKey) === 'INCOME_SIMPLE_DAILY') ? true : false
      if (!check) {
        history.push(routeName.report)
        return false
      }
    })

    /** MASTER DATA */
    ClinicApi.findAllBranches()
      .then(({ data }) => setBranches(data))
      .catch((e) => {
        return
      })

    ClinicApi.allBillPrefix()
      .then(({ data }) => {
        const filteredData = data.filter((item: any) => item.prefix !== 'AD')
        setReceiptPrefix([...filteredData, { billPrefixId: null, prefix: 'AD' }])
      })
      .catch((e) => {
        return
      })

    return true
  }

  useEffect(() => {
    verifyPermission()
  }, [])

  /** TABLE 1 */
  const fetchIncome = () => {
    let condition: FindAllReportInterface = {
      page: incomePage,
      pageLimit: incomePageLimit,
      branchId: branchId,
      prefix: prefix
    }
    if (billStatus && billStatus !== 'ALL') condition = { ...condition, status: billStatus }
    if (incomeSortType) condition = { ...condition, sortType: incomeSortType }
    if (incomeSortBy) condition = { ...condition, sortBy: incomeSortBy }
    if (paymentChanelSelect?.length) condition = { ...condition, paymentChanel: paymentChanelSelect.join(',') }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    ReportApi.reportIncomeSimpleDaily(condition)
      .then(({ headers, data }) => {
        setIncomeData(data)
        setIncomeRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }
  // branchId, rangeDate, prefix, billStatus

  const onChangeIncomeSort = (sortByVal: string, sortTypeVal: string) => {
    setIncomeSortType(sortTypeVal)
    setIncomeSortBy(sortByVal)
  }

  const onChangeIncomeRowPerPage = (limit: number) => {
    setIncomePageLimit(limit)
  }

  const onChangeIncomePage = (val: number) => {
    setIncomePage(val)
  }

  const loadIncomeExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId: branchId,
      prefix: prefix,
      export: 1
    }

    if (billStatus && billStatus !== 'ALL') condition = { ...condition, status: billStatus }
    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (paymentChanelSelect?.length) condition = { ...condition, paymentChanel: paymentChanelSelect.join(',') }

    ReportApi.reportIncomeSimpleDaily(condition)
      .then(({ data }) => {
        setIncomeExportData(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, rangeDate, prefix, billStatus, paymentChanelSelect])

  /** TABLE 2 */
  const fetchIncomeType = () => {
    let condition: FindAllReportInterface = {
      page: incomeTypePage,
      pageLimit: incomeTypePageLimit,
      branchId: branchId,
      prefix: prefix
    }
    if (billStatus && billStatus !== 'ALL') condition = { ...condition, status: billStatus }
    if (incomeTypeSortType) condition = { ...condition, sortType: incomeTypeSortType }
    if (incomeTypeSortBy) condition = { ...condition, sortBy: incomeTypeSortBy }
    if (paymentChanelSelect?.length) condition = { ...condition, paymentChanel: paymentChanelSelect.join(',') }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    ReportApi.reportIncomeTypeDaily(condition)
      .then(({ headers, data }) => {
        /** TOTAL */
        const total = {
          paidCancel: 0,
          paidNormal: 0,
          cardTypeName: null,
          paymentChannelName: t('REPORT.TITLE.SUM')
        }

        data.map((t: any) => {
          total.paidCancel += t.paidCancel
          total.paidNormal += t.paidNormal
        })
        setIncomeTypeData(data.concat(total))
        setIncomeTypeRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }

  const onChangeIncomeTypeSort = (sortByVal: string, sortTypeVal: string) => {
    setIncomeTypeSortType(sortTypeVal)
    setIncomeTypeSortBy(sortByVal)
  }

  const onChangeIncomeTypeRowPerPage = (limit: number) => {
    setIncomeTypePageLimit(limit)
  }

  const onChangeIncomeTypePage = (val: number) => {
    setIncomeTypePage(val)
  }

  const loadIncomeTypeExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId: branchId,
      prefix: prefix
    }
    if (billStatus && billStatus !== 'ALL') condition = { ...condition, status: billStatus }
    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
    if (paymentChanelSelect?.length) condition = { ...condition, paymentChanel: paymentChanelSelect.join(',') }

    ReportApi.reportIncomeTypeDaily(condition)
      .then(({ headers, data }) => {
        /** TOTAL */
        const total = {
          paidCancel: 0,
          paidNormal: 0,
          cardTypeName: null,
          paymentChannelName: t('REPORT.TITLE.SUM')
        }

        data.map((t: any) => {
          total.paidCancel += t.paidCancel
          total.paidNormal += t.paidNormal
        })
        setIncomeTypeExportData(data.concat(total))
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, rangeDate[1], prefix, paymentChanelSelect])

  /** TABLE 3 */
  const fetchIncomeOverdue = () => {
    let condition: FindAllReportInterface = {
      page: incomeTypePage,
      pageLimit: incomeTypePageLimit,
      branchId: branchId,
      prefix: prefix
    }
    if (billStatus && billStatus !== 'ALL') condition = { ...condition, status: billStatus }
    if (incomeTypeSortType) condition = { ...condition, sortType: incomeTypeSortType }
    if (incomeTypeSortBy) condition = { ...condition, sortBy: incomeTypeSortBy }
    if (paymentChanelSelect?.length) condition = { ...condition, paymentChanel: paymentChanelSelect.join(',') }

    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    ReportApi.reportIncomeOverdueDaily(condition)
      .then(({ headers, data }) => {
        setIncomeOverdueData(data)
        setIncomeOverdueRowCount(headers['x-total'])
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }

  const onChangeIncomeOverdueSort = (sortByVal: string, sortTypeVal: string) => {
    setIncomeOverdueSortType(sortTypeVal)
    setIncomeOverdueSortBy(sortByVal)
  }

  const onChangeIncomeOverdueRowPerPage = (limit: number) => {
    setIncomeOverduePageLimit(limit)
  }

  const onChangeIncomeOverduePage = (val: number) => {
    setIncomeOverduePage(val)
  }

  const loadIncomeOverdueExport = useCallback(async () => {
    let condition: FindAllReportInterface = {
      page: 1,
      pageLimit: 10000,
      branchId: branchId,
      prefix: prefix
    }
    if (billStatus && billStatus !== 'ALL') condition = { ...condition, status: billStatus }
    if (rangeDate[0] !== null && rangeDate[1] !== null) condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }

    ReportApi.reportIncomeOverdueDaily(condition)
      .then(({ data }) => {
        setIncomeOverdueExportData(data)
      })
      .catch((e) => setLoading(false))
      .finally(() => setLoading(false))
  }, [branchId, rangeDate[1], prefix, billStatus])

  /** UseEffect */
  useEffect(() => {
    fetchIncome()
  }, [incomePage, incomePageLimit, incomeSortType, incomeSortBy])

  useEffect(() => {
    fetchIncomeType()
    fetchIncomeOverdue()
  }, [])

  useEffect(() => {
    loadIncomeExport()
    loadIncomeTypeExport()
    loadIncomeOverdueExport()
  }, [loadIncomeExport, loadIncomeTypeExport, loadIncomeOverdueExport])

  const { onDownload } = useDownloadExcel({
    tableExportRef: componentRef.current,
    filename: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)} ${rangeDate ? `${rangeDate[0]} - ${rangeDate[1]}` : ''}`,
    sheetName: `${t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)}`
  })

  const setHeaderSearch = [
    {
      name: `${t('REPORT.FILTER.BRANCH')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.RECEIPT_STATUS')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.RECEIPT_TYPE')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.START')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: `${t('REPORT.FILTER.DATE_END')}`,
      style: { width: '1%', whiteSpace: 'nowrap' }
    },
    {
      name: `${rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'}`,
      style: { width: '1%', whiteSpace: 'nowrap', paddingRight: '10px' }
    },
    {
      name: ``,
      style: {}
    }
  ]

  const onLoadData = () => {
    fetchIncome()
    fetchIncomeType()
    fetchIncomeOverdue()
    loadIncomeExport()
    loadIncomeTypeExport()
    loadIncomeOverdueExport()
  }

  return (
    <Card className={'border-0 h-100'}>
      <HeaderCard text={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)} />
      <Card.Body>
        <Card.Title className={'mb-0'}>
          <Row className="mx-0">
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBranchId(e.target.value)
                  setIncomePage(1)
                  setIncomeTypePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.BRANCH')}: ${_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')} `}
                label={''}
                selectId="select-branch"
                labelId="label-branch"
                value={branchId}
                options={_.map(branches, (b: BranchInterface) => {
                  return (
                    <MenuItem key={b.branchId} value={b.branchId}>
                      {b.branchName || b.branchNameEn}
                    </MenuItem>
                  )
                })}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setPrefix(e.target.value)
                  setIncomePage(1)
                  setIncomeTypePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.RECEIPT_TYPE')}: ${prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}`}
                label={''}
                selectId="select-prefix"
                labelId="label-prefix"
                value={prefix}
                options={[
                  <MenuItem key="0" value="ALL">
                    {t('REPORT.FILTER.ALL')}
                  </MenuItem>,
                  _.map(receiptPrefix, (data: BillInterface) => {
                    return (
                      <MenuItem key={data.billPrefixId} value={data.prefix}>
                        {data.prefix}
                      </MenuItem>
                    )
                  })
                ]}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>

            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <FilterSelect
                onchange={(e) => {
                  setBillStatus(e.target.value)
                  setIncomeTypePage(1)
                }}
                renderValue={() => `${t('REPORT.FILTER.RECEIPT_STATUS')}: ${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)} `}
                label={''}
                selectId="select-bill-status"
                labelId="label-bill-status"
                value={billStatus}
                options={[
                  <MenuItem key="1" value="ALL">
                    {t('REPORT.FILTER.ALL')}
                  </MenuItem>,
                  <MenuItem key="2" value="PAID">
                    {t('REPORT.FILTER.BILL.PAID')}
                  </MenuItem>,
                  <MenuItem key="3" value="CANCEL">
                    {t('REPORT.FILTER.BILL.CANCEL')}
                  </MenuItem>,
                  <MenuItem key="3" value="OVERDUE">
                    {t('REPORT.FILTER.BILL.OVERDUE')}
                  </MenuItem>
                ]}
                formControlStyle={{ maxWidth: 'unset !important' }}
                classesOption="style-select-doctors"
              />
            </Col>
            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <Box position={'relative'} sx={{ '.MuiInputBase-root': { height: '32px' } }}>
                <SelectCheckBoxInput
                  label={t("PAYMENT_DETAIL.LABEL.PAYMENT_CHANNEL")}
                  onChange={setPaymentChanelSelect}
                  options={optionPaymentChanel}
                  selected={paymentChanelSelect}
                />
              </Box>
            </Col>

            <Col sm={6} md={4} xl={2} className={'pt-2 px-1'}>
              <InputSecondNewRangePicker
                inputHeight={32}
                value={rangeDate || emptyRangePicker}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                  else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  setIncomePage(1)
                  setIncomeTypePage(1)
                }}
                label={''}
                allowClear
                onClear={() => setRangeDate(emptyRangePicker)}
              />
            </Col>
            <Col md={4} lg={3} xl={2} className={'d-flex justify-content-end ml-xl-auto pt-2 px-1 gap-2'}>
              <Box maxWidth={'100px'} width={'100%'} sx={{ button: { width: '100%' } }}>
                <MainButton
                  title={t('INPUT.SEARCH')}
                  onClick={() => {
                    setIncomePage(1)
                    onLoadData()
                  }}
                  type={'primary'}
                  variant={'contained'}
                />
              </Box>
              <Box>
                <UseStyled.DropdownDocument>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-xl-auto">
                    {t('PRINT_DOCUMENT')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onDownload}>{t('Excel')}</Dropdown.Item>
                    <FucntionPrint
                      content={() => componentRefPDF.current}
                      removeIfram
                      trigger={() => <Dropdown.Item>{t('PDF')}</Dropdown.Item>}
                      style={`@page { size: 1122.519685px 793.7007874px ;  margin: 0.5cm;  } @media print { body { -webkit-print-color-adjust: exact; }`}
                    />
                  </Dropdown.Menu>
                </UseStyled.DropdownDocument>
              </Box>

            </Col>
          </Row>
        </Card.Title>

        <Box className={'mt-3 pl-xl-3 pr-xl-2 pb-4'}>
          <IncomeList
            page={incomePage}
            pageLimit={incomePageLimit}
            rowCount={incomeRowCount}
            sortBy={incomeSortBy}
            sortType={incomeSortType}
            data={incomeData}
            onRequestSort={onChangeIncomeSort}
            handleChangeRowsPerPage={onChangeIncomeRowPerPage}
            handleChangePage={onChangeIncomePage}
          />
        </Box>
        <Divider className="mx-0 mt-3 mb-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />

        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography fontSize="20px" marginBottom="8px">ยอดชำระเงิน</Typography>
            <IncomeType
              page={incomeTypePage}
              pageLimit={incomeTypePageLimit}
              rowCount={incomeTypeRowCount}
              sortBy={incomeTypeSortBy}
              sortType={incomeTypeSortType}
              data={incomeTypeData}
              onRequestSort={onChangeIncomeTypeSort}
              handleChangeRowsPerPage={onChangeIncomeTypeRowPerPage}
              handleChangePage={onChangeIncomeTypePage}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography fontSize="20px" marginBottom="8px">รายการอื่นๆ</Typography>
            <IncomeOverdue
              page={incomeOverduePage}
              pageLimit={incomeOverduePageLimit}
              rowCount={incomeOverdueRowCount}
              sortBy={incomeOverdueSortBy}
              sortType={incomeOverdueSortType}
              data={incomeOverdueData}
              onRequestSort={onChangeIncomeOverdueSort}
              handleChangeRowsPerPage={onChangeIncomeOverdueRowPerPage}
              handleChangePage={onChangeIncomeOverduePage}
            />
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography fontSize="20px" marginBottom="8px">ยอดรายได้สุทธิ</Typography>
            <IncomeSum
              page={incomeTypePage}
              pageLimit={incomeTypePageLimit}
              rowCount={incomeTypeRowCount}
              sortBy={incomeTypeSortBy}
              sortType={incomeTypeSortType}
              data={incomeExport}
              branchInfo={branchInfo}
              onRequestSort={onChangeIncomeTypeSort}
              handleChangeRowsPerPage={onChangeIncomeTypeRowPerPage}
              handleChangePage={onChangeIncomeTypePage}
            />
          </Grid>
        </Grid>
      </Card.Body>
      <Loading show={loading} type="fullLoading" />

      <div className="print-show" ref={componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(setHeaderSearch, (val: any) => {
                        return <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500, border: 'none', ...val.style }}>{t(val.name)}</td>
                      })}
                    </tr>
                  </thead>
                </table>
              </UseStyled.Styles>
              <br></br>
              <ExportComponant incomeExport={incomeExport} incomeTypeExport={incomeTypeExport} incomeOverdueExport={incomeOverdueExport} />
            </>
          }
        />
      </div>

      <div className="print-show" ref={componentRefPDF}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.INCOME_SIMPLE_DAILY`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <div style={{ fontSize: '14px' }}>
                  <span>{t('REPORT.FILTER.BRANCH')}: </span>
                  <span style={{ marginRight: '13px' }}>{_.get(_.find(branches, { branchId: branchId }), 'branchName' || 'branchNameEn')}</span>
                  <span>{t('REPORT.FILTER.RECEIPT_TYPE')}: </span>
                  <span style={{ marginRight: '13px' }}>{prefix === 'ALL' ? t(`REPORT.FILTER.ALL`) : prefix}</span>
                  <span>{t('REPORT.FILTER.RECEIPT_STATUS')}: </span>
                  <span style={{ marginRight: '13px' }}>{billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)}</span>
                  <span>{t('REPORT.FILTER.DAY')} </span>
                  <span style={{ marginRight: '5px' }}>{rangeDate[0] ? toBuddhistYear(moment(rangeDate[0]), 'DD/MM/YYYY') : '-'}</span>
                  <span>{t('REPORT.FILTER.TO')} </span>
                  <span style={{ marginRight: '5px' }}>{rangeDate[1] ? toBuddhistYear(moment(rangeDate[1]), 'DD/MM/YYYY') : '-'}</span>
                </div>
              </UseStyled.Styles>
              <ExportComponant incomeExport={incomeExport} incomeTypeExport={incomeTypeExport} incomeOverdueExport={incomeOverdueExport} />
            </>
          }
        />
      </div>
    </Card>
  )
}

export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}

export function ExportComponant({ incomeExport, incomeTypeExport, incomeOverdueExport }: any) {
  const { t } = useTranslation()
  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])
  // REPORT.TABLE.INCOME_DAY.PATIENT_CN_NUMBER
  const headCellIncome = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.BILL_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.DATE_TIME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'APPLICATION.TABLE.CELL.CN_NUMBER', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'PATIENT_MANAGEMENT.TABLE.CELL.SN_CODE', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_NAME', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PATIENT_AGE', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_STATUS', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_CHANNELS', align: 'left', class: 'text-wrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_PAY', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.PAYMENT_OVERDUE', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK', align: 'center', class: 'text-wrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CREATED_BY', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.REMARK_CANCEL', align: 'center', style: { width: '20px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', important: true } },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.CANCEL_BY', align: 'center', class: 'text-nowrap' }
  ]
  if (!snType) {
    headCellIncome.splice(4, 1)
  }
  const headCellIncomeType = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const headCellIncomeOverdue = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' }
  ]

  const renderIncomeOverdueExport = (data: any, no: number) => {
    const num = no + 1
    return (
      <TableRowExport
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: data.paymentChannelName || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || '-' },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || '-' }
        ]}
      />
    )
  }

  const renderIncomeTypeExport = (data: any, no: number) => {
    const num = no + 1
    const paymentChanel = data.paymentChannelName === 'Voucher' ? t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.VOUCHER') : data.paymentChannelName
    return (
      <>
        <TableRowExport
          key={num.toString()}
          id={no.toString()}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'left', label: `${paymentChanel} ${data.cardTypeName !== null ? `(${data.cardTypeName})` : ``}` || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || '-' },
            { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || '-' }
          ]}
        />
      </>
    )
  }

  const renderIncomeExport = (data: any, no: number) => {
    const num = no + 1
    const columns = [
      { option: 'TEXT', align: 'left', label: data.billNo, class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: dateTimeToView(data.paidAt), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.cnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.branchCnNumber || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.snCode || '', class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.patientFullname, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.age, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: t(`REPORT.BILL_STATUS.${data.billStatus}`), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: data.paymentChannel, class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.amountPaid), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.overdue), class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.remark, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.createdBy, class: 'text-nowrap' },
      { option: 'TEXT', align: 'center', label: data.cancelRemark, class: 'text-wrap' },
      { option: 'TEXT', align: 'center', label: data.cancelBy, class: 'text-nowrap' }
    ]
    if (!snType) {
      columns.splice(4, 1)
    }
    return (
      <>
        <TableRowExport key={num.toString()} id={no.toString()} obj={data} columns={columns} />
      </>
    )
  }

  return (
    <>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncome, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {_.map(incomeExport, (d: any, index: number) => {
              return renderIncomeExport(d, index)
            })}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-5">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncomeType, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {[
              _.map(incomeTypeExport, (d: any, index: number) => {
                return renderIncomeTypeExport(d, index)
              })
            ]}
          </tbody>
        </table>
      </UseStyled.Styles>
      <br></br>
      <UseStyled.Styles className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCellIncomeOverdue, (val: any) => {
                return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {[
              _.map(incomeOverdueExport, (d: any, index: number) => {
                return renderIncomeOverdueExport(d, index)
              })
            ]}
          </tbody>
        </table>
      </UseStyled.Styles>
    </>
  )
}
