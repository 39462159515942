import { useCallback, useEffect, useState } from 'react'
import { Box, Grid, IconButton, TableCell, TableRow, Typography, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

/** UTILS || SLICE */
import { treatment, setTreatmentByKey, setSummeryTreatments, TreatmentStateInterface, ProductStickerType, StickerType, loadPromotionTreatments } from 'app/slice/treatment.slice'
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { showModal, resetModal, handleShow } from 'app/slice/modal.slice'

/** API */
import ComponentApi, { Bookmark } from 'api/components-api'
import TreatmentProductApi, { AllProductType, AllProduct, ProductType } from 'api/dentists/treatmentProduct.api'

/** CONSTANTS */
import { fileUrl } from 'api/api'
import { colors } from 'constants/theme'
import { Icons, icons, onImgError } from 'constants/images'

/** COMPONENT */
import InputTextSearch from 'component/Input/InputTextSearch'
import AlertProductOutOfStock, { AlertProductOutOfStockType } from 'component/Treatments/AlertProductOutOfStock'
import PopupManageProduct, { initialProductState } from 'component/Treatments/PopupManageProduct'
import AddBoxIcon from '@mui/icons-material/AddBox'

/** STYLE */
import { IconAddBox, IconBookmark } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import { TypeTabs, ButtonType, ProductItem, ProductBox, ProductCard, InventoryChip, ProductQty, ProductBarCode } from 'features/treatments/MainMenu/OPD/style/ProductUseStyle'
import useWindowSize from 'utils/useWindowSize'
import TableCustom from './Table/TableCustom'
import { selectMe } from 'app/slice/user.slice'
import { notiError } from 'component/notifications/notifications'

import { CURRENCY_UNIT } from 'component/Treatments/PopupManageTreatmentCourse'
import ButtonCustom from 'component/Button/ButtonCustom'
import AddCircleIcon from '@mui/icons-material/AddCircle';



export const ResponsiveTxTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '6%',
          [theme.breakpoints.down('lg')]: {
            width: 130
          }
        },
        '&:nth-of-type(2)': {
          width: '13%',
          [theme.breakpoints.down('lg')]: {
            width: 'auto'
          }
        },
        '&:nth-of-type(3)': {
          width: 130,
          [theme.breakpoints.down('lg')]: {
            width: 130
          }
        },
        '&:nth-of-type(4)': {
          [theme.breakpoints.down('lg')]: {
            width: 120
          }
        },
        '&:nth-of-type(5)': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 130,
        },
        '&:nth-of-type(6)': {
          width: 110
        },
        '&:last-of-type': {
          width: 85,
          [theme.breakpoints.down('lg')]: {
            width: 80
          }
        },
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        padding: '6px 15px',
        verticalAlign: 'middle',
        '&:nth-of-type(3)': {
          paddingLeft: 4,
          paddingRight: 4,
        },
        '&:last-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
        },
      }
    }
  },
  '.MuiTablePagination-root': {
    '.MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiInputBase-root': {
      display: 'none'
    }
  }
}))

type ProdType = {
  type: 'Treatment' | 'Payment' | 'RetrospectiveOrder'
  userId?: number
  patient?: any
  patientCaseId: number
  hasChangeProductPrice: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional: 'PERMISS' | 'UNPERMISS'
  pdShowType?: '1' | '0'
}

type ImageType = {
  fileId?: number
  filePath?: string
  fileName?: string
}

const initProductOutOfStock = {
  name: '',
  code: '',
  qty: 0,
  unit: ''
}

export default function PD(props: ProdType) {
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const modal: boolean = useSelector(handleShow)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const user = useSelector(selectMe)
  const branch_ = getClinicInfo()

  const userId = props.userId
  const branchId = Number(getBranch())
  const [typeSelected, setTypeSelected] = useState<string | number>('All')
  const [productsType, setProductsType] = useState([])
  const [products, setProducts] = useState<any[]>([])
  const [popupProductOutOfStock, setPopupProductOutOfStock] = useState<AlertProductOutOfStockType>(initProductOutOfStock)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(props.pdShowType === '1' ? 6 : 50)
  const [rowCount, setRowCount] = useState(0)
  const [search, setSearch] = useState('')
  const [btnBarcode, setBtnBarcode] = useState(false);


  // popup manage product
  const [popupManageProduct, setPopupManageProduct] = useState<ProductStickerType>(initialProductState)
  const [isShowPopupProduct, setIsShowPopupProduct] = useState(false)
  const [isNotAddProduct, setIsNotAddProduct] = useState<boolean>(false)


  useEffect(() => {
    setIsNotAddProduct((user?.hasCreateProduct === 'UNPERMISS' && (props.type === 'Payment' || props.type === 'RetrospectiveOrder')) ? true : false)
  }, [user])

  const loadProductsType = async () => {
    const params: AllProductType = { group: 'PRODUCT' }
    const resp = await TreatmentProductApi.findAllProductType(params)
    if (resp.status === 200) setProductsType(resp.data)
  }

  const loadProduct = useCallback(
    async (valueUserId: number) => {
      let condition: AllProduct = { page: page, pageLimit: pageLimit, branchId: branchId, userId: valueUserId, group: 'PRODUCT' }
      if (search) condition = { ...condition, search: search }
      if (typeSelected === 'BOOKMARK') condition = { ...condition, isBookmark: '1' }
      if (typeSelected && typeSelected !== 'BOOKMARK' && typeSelected !== 'All' && typeSelected !== 'OUT_OF_STOCK') condition = { ...condition, typeId: Number(typeSelected) }
      if (typeSelected === 'OUT_OF_STOCK') {
        const resp = await TreatmentProductApi.findAllProductOutOfStock(condition)
        if (resp.status === 200) {
          setProducts(resp.data.map((item: any) => { return { ...item, isOutOfStock: true } }))
          setRowCount(resp.headers['x-total'])
        }
      }
      else {
        const resp = await TreatmentProductApi.findAllProduct(condition)
        if (resp.status === 200) {
          setProducts(resp.data)
          setRowCount(resp.headers['x-total'])
        }
      }
    },
    [search, typeSelected, page, pageLimit, branchId])

  const handleChangeType = (type: string | number) => {
    if (type === 'BOOKMARK') setTypeSelected('BOOKMARK')
    else if (type === 'All') setTypeSelected(0)
    else if (type === 'OUT_OF_STOCK') setTypeSelected('OUT_OF_STOCK')
    else setTypeSelected(Number(type))
  }

  const handleBookmark = async (id: number, bookmarkId: string) => {
    const payload: Bookmark = { bookmarkType: 'PRODUCT', userId: userId || 0, itemId: id, isBookmark: bookmarkId ? '0' : '1' }
    const resp = await ComponentApi.bookmark(payload)
    if (resp.status === 201) loadProduct(userId || 0)
  }

  const handleAddProduct = (pd: any, select?: boolean) => {
    if (pd.qty < 1) {
      setPopupProductOutOfStock({ name: pd.itemName || pd.itemNameEn, code: pd.itemCode, qty: pd.qty, unit: pd.itemUnitName || pd.itemUnitNameEn })
      dispatch(showModal())
      return
    }

    const pinnedDoctorId = _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId;
    const hasOverdue = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: pinnedDoctorId });
    const doctorIdToUse = !hasOverdue ? pinnedDoctorId : null;
    const item = { ...pd, total: pd.price, doctorId: doctorIdToUse, discount: 0, additional: 0, discountUnit: 'BAHT', additionalUnit: 'BAHT', stock: pd.qty, isEdit: null }
    const hasItemInSummary = _.some(treatments.summeryTreatments, (sum, index) => {
      if (!sum?.promotion?.length) return sum.itemId === item.itemId
      else return sum.itemId === item.itemId && sum?.promotion.every((info: any) => info?.promotionType !== 'EXTRA')
    })
    const doctorIsInConsentDocument = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId })
    const sticker: StickerType = {
      itemId: pd.itemId,
      itemName: pd.itemName,
      itemNameEn: pd.itemNameEn,
      itemCode: pd.itemCode,
      image: {
        fileId: pd?.image?.fileId || null,
        filePath: pd?.image?.filePath || '',
        fileName: pd?.image?.fileName || ''
      },
      stock: pd.qty,
      defaultProductUnitName: pd.itemUnitName || pd.itemUnitNameEn,
      dosage: 1,
      expiredDate: pd.expireDate || ''
    }

    if (!hasItemInSummary) {
      handleNewProduct(createNewItem(pd, sticker, 0, doctorIsInConsentDocument));
    } else {
      const index = _.findIndex(treatments.summeryTreatments, { itemId: pd.itemId });
      const currentQty = Number(treatments.summeryTreatments[index]?.qty);
      if (currentQty > pd.qty) showOutOfStockPopup(pd);
      else if (!isShowPopupProduct && currentQty >= 1) {
        const currentQty = Number(treatments.summeryTreatments[index].qty) + 1
        if (currentQty > pd.qty) {
          setPopupProductOutOfStock({ name: pd.itemName || pd.itemNameEn, code: pd.itemCode, qty: pd.qty, unit: pd.itemUnitName || pd.itemUnitNameEn })
          dispatch(showModal())
          return
        } else {
          if ((props?.type === 'Payment' || props?.type === 'Treatment')) {
            const newData = [...treatments.summeryTreatments]
            newData[index] = { ...newData[index], qty: currentQty <= pd.qty ? currentQty : pd.qty, total: currentQty <= pd.qty ? currentQty * pd.price : pd.qty * pd.price }
            dispatch(loadPromotionTreatments(props?.patient?.patientId, [...newData]))

            const newDataPromotionDiscount = { promotionDiscount: 0, promotionDiscountUnit: CURRENCY_UNIT.BAHT, }
            const newDataVoucherDiscount = { voucherDiscount: 0, voucherDiscountUnit: CURRENCY_UNIT.BAHT, voucherListId: 0, voucher: {} }
            if (newData[index]?.promotion?.length) {
              const promotionUsage = newData[index]?.promotion?.map((item: any) => item?.promotionBranchId)
              const newPromotions: any = []
              treatments?.summeryTreatments?.forEach((item: any, num: number) => {
                if (item?.promotion?.length) {
                  const checkType = item?.promotion?.some((info: any) => info?.promotionType === 'EXTRA' && promotionUsage.includes(info?.promotionBranchId))
                  if (!checkType) {
                    const newPromotionItem = item?.promotion?.filter((info: any) => !promotionUsage.includes(info?.promotionBranchId)) || []
                    if (index === num) {
                      newPromotions.push({ ...item, promotion: newPromotionItem, qty: currentQty <= pd.qty ? currentQty : pd.qty, total: currentQty <= pd.qty ? currentQty * pd.price : pd.qty * pd.price, ...newDataPromotionDiscount, ...newDataVoucherDiscount })
                    } else {
                      newPromotions.push({ ...item, promotion: newPromotionItem })
                    }
                  }
                } else {
                  if (index === num) {
                    newPromotions.push({ ...item, qty: currentQty <= pd.qty ? currentQty : pd.qty, total: currentQty <= pd.qty ? currentQty * pd.price : pd.qty * pd.price, ...newDataPromotionDiscount, ...newDataVoucherDiscount })
                  } else {
                    newPromotions.push(item)
                  }
                }
              })
              dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: newPromotions }))
            } else {
              const newData = [...treatments.summeryTreatments]
              const newDataVoucherDiscount = { voucherDiscount: 0, voucherDiscountUnit: CURRENCY_UNIT.BAHT, voucherListId: 0, voucher: {} }
              newData[index] = { ...newData[index], qty: currentQty <= pd.qty ? currentQty : pd.qty, total: currentQty <= pd.qty ? currentQty * pd.price : pd.qty * pd.price, ...newDataVoucherDiscount }
              dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: newData }))
            }
          } else {
            dispatch(setSummeryTreatments({ index: index, key: 'qty', value: currentQty <= pd.qty ? currentQty : pd.qty }))
            dispatch(setSummeryTreatments({ index: index, key: 'total', value: currentQty <= pd.qty ? currentQty * pd.price : pd.qty * pd.price }))
          }
        }
      }
    }
  }

  const createNewItem = (prod: any, sticker: StickerType, tempOrderProductId: number | null, doctorIsInConsentDocument: boolean | null): ProductStickerType => ({
    ...prod,
    sticker: JSON.stringify(sticker),
    itemId: prod.itemId,
    tempOrderProductId,
    doctorId: !doctorIsInConsentDocument ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
    qty: 1,
    price: prod.price,
    discount: 0,
    discountUnit: 'BAHT',
    additional: 0,
    additionalUnit: 'BAHT',
    total: prod.price
  });


  const handleNewProduct = (newItem: ProductStickerType) => {
    setPopupManageProduct(newItem);
    setIsShowPopupProduct(true)
    dispatch(showModal())
  };

  const showOutOfStockPopup = (prod: any) => {
    setPopupProductOutOfStock({
      name: prod.itemName || prod.itemNameEn,
      code: prod.itemCode,
      qty: prod.qty,
      unit: prod.itemUnitName || prod.itemUnitNameEn
    });
    dispatch(showModal());
  };



  const renderImage = (image: ImageType) => {
    if (image?.filePath && image?.fileName) return `${fileUrl}/${image.filePath}/${image.fileName}`
    else return icons.prodDefault
  }

  const renderDateExpired = (date: string, day: number) => {
    if (date && day) {
      const now = moment()
      const diff = moment(date).add('days', 1).diff(now, 'days')
      if (diff <= day) return colors.themeThirdColor
      else return colors.textLightGray
    } else return colors.textLightGray
  }

  const desktopHeadCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'type', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_TYPE') }
  ]

  const mobileHeadCells = [{ id: 'action', disablePadding: false, label: 'Action', align: width >= 768 ? 'center' : 'right', class: 'pr-1 pr-md-3' }]

  const tableHeadCells = [
    { id: 'code', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_CODE'), align: 'center', width: '130px' },
    { id: 'service', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_NAME') },
    { id: 'unit', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_UNIT_NAME'), align: 'center' },
    { id: 'price', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_PRICE'), align: 'right' },
    { id: 'exp', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_EXPIRE_DATE'), align: 'center' },
    { id: 'qty', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_QTY'), align: 'center' }
  ]

  const headCells = width >= 768 ? (width >= 1200 && [...desktopHeadCells, ...tableHeadCells, ...mobileHeadCells]) || [...tableHeadCells, ...mobileHeadCells] : [...mobileHeadCells]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { amounDayBeforeExpired, bookmark, bookmarkId, itemId, itemCode, expireDate, group, inventoryId, itemName, itemTypeName, itemUnitName, minStock, price, qty, qtyExpireDate, isOutOfStock } = objData

    return (
      <TableRow key={itemId}>
        {(width >= 768 && (
          <>
            {width >= 1200 && (
              <>
                <TableCell align="center" scope="row">
                  {no}
                </TableCell>
                <TableCell align="left" scope="row" className="text-ellipsis" title={itemTypeName}>
                  {itemTypeName}
                </TableCell>
              </>
            )}
            <TableCell align="center" scope="row" className="text-ellipsis">
              {itemCode}
            </TableCell>
            <TableCell align="left" scope="row" className="text-ellipsis">
              {itemName}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis">
              {itemUnitName}
            </TableCell>
            <TableCell align="right" scope="row" className="text-ellipsis">
              {numberFormat(price)}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis" style={{ color: renderDateExpired(expireDate, amounDayBeforeExpired) }}>
              {`${toBuddhistYear(moment(expireDate), 'DD/MM/YY') === 'NaN' ? '-' : toBuddhistYear(moment(expireDate), 'DD/MM/YY')}`}{`${toBuddhistYear(moment(expireDate), 'DD/MM/YY') === 'NaN' ? '' : `(${numberFormat(qtyExpireDate || 0, 0, 0)})`}`}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis" style={{ color: qty < minStock ? 'red' : '' }}>
              {qty}
            </TableCell>
            <TableCell align="center" scope="row">
              <IconBookmark key={itemId} className="p-0 mr-1" onClick={() => handleBookmark(itemId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
              </IconBookmark>
              <IconAddBox disabled={(props?.hasChangeProductPrice === 'PERMISS' ? false : true) || isOutOfStock || isNotAddProduct} key={itemId} className="p-0 ml-1" onClick={() => handleAddProduct(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                <AddBoxIcon style={{ width: 30, height: 30 }} />
              </IconAddBox>
            </TableCell>
          </>
        )) || (
            <>
              <TableCell align="left" scope="row">
                <Box className="d-flex justify-content-between">
                  <Box>
                    <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                      {t('TREATMENT_PRODUCT.TABLE.PRODUCT_CODE')}
                    </Typography>
                    <Typography className="d-inline">{itemCode}</Typography>
                  </Box>
                  <Box>
                    <IconBookmark key={itemId} className="p-0 mr-1" onClick={() => handleBookmark(itemId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                      {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
                    </IconBookmark>
                    <IconAddBox disabled={props?.hasChangeProductPrice === 'PERMISS' ? false : true || isNotAddProduct} key={itemId} className="p-0 ml-1" onClick={() => handleAddProduct(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  </Box>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.PRODUCT_NAME')}
                  </Typography>
                  <Typography className="d-inline">{itemName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.PRODUCT_UNIT_NAME')}
                  </Typography>
                  <Typography className="d-inline">{itemUnitName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.PRODUCT_PRICE')}
                  </Typography>
                  <Typography className="d-inline">{numberFormat(price)}</Typography>
                </Box>
              </TableCell>
            </>
          )}
      </TableRow>
    )
  }

  const onPopupManageProduct = () => {
    setBtnBarcode(true)
    setPopupManageProduct(initialProductState)
    setIsShowPopupProduct(true)
    dispatch(showModal())
  }

  const onCancelPopupManageProduct = () => {
    setBtnBarcode(false);
    setPopupManageProduct(initialProductState);
    setIsShowPopupProduct(false);
    dispatch(resetModal());
  };

  useEffect(() => {
    loadProductsType()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [typeSelected])

  useEffect(() => {
    if (userId) loadProduct(userId)
  }, [loadProduct, userId])

  useEffect(() => {
    if (!modal) {
      onCancelPopupManageProduct()
      setPopupProductOutOfStock(initProductOutOfStock)
      dispatch(resetModal())
    }
  }, [modal])

  const handleCannotAddProduct = () => {
    if (isNotAddProduct) {
      return notiError(t('TREATMENT_PRODUCT.PRODUCT.ERROR.NOTADD'))
    }
  }


  const handleFindItemMed = (id: number) => {
    if (treatments.summeryTreatments?.length) {
      const newData = treatments?.summeryTreatments?.filter((item: any) => item?.itemId === id) || []
      return newData?.length && newData.map((item: any) => item?.qty || 0)?.reduce((a: any, c: any) => {
        return a + c;
      }, 0)
    }
    return 0
  }

  return (
    <Box>
      <Grid container sx={{ width: '100%' }}>
        <Grid item sm={4} lg={4} xl={4} ><Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('TREATMENT_PRODUCT.TITLE.PRODUCT')}</Typography></Grid>
        <Grid item sm={8} lg={8} xl={8} sx={{ gap: 2, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingBottom: '10px' }}>
          <Box paddingBottom={'10px'}>
            <ButtonCustom
              variant="outlined"
              startIcon={<AddCircleIcon />}
              onClick={() => onPopupManageProduct()}
              textButton={t('TREATMENT_PRODUCT.CARD.SELECT_PRODUCT_BY_BARCODE')}

            />
          </Box>
          <Box className="w-100" sx={{ maxWidth: 379 }}>
            <InputTextSearch
              keyInput={'search'}
              label={t('TREATMENT_PRODUCT.SEARCH_PRODUCT')}
              value={search}
              onchange={(event) => setSearch(event.target.value)}
              onClear={(event) => setSearch('')}
            />
          </Box>
        </Grid>
      </Grid >
      <TypeTabs className="d-flex flex-wrap custom-scroll">
        <Box className="px-1 pt-2">
          <ButtonType variant="outlined" title={t('ALL')} className={`${typeSelected === 'All' || typeSelected === 0 ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={() => handleChangeType('All')} key={`type-all`}>
            <span className="text-ellipsis">{t('ALL')}</span>
          </ButtonType>
        </Box>
        <Box className="px-1 pt-2" style={{ width: 150 }} >
          <ButtonType variant="outlined" title={t('TREATMENT_PRODUCT.PRODUCT.PD_OUT')} className={`${typeSelected === 'OUT_OF_STOCK' || typeSelected === 'OUT_OF_STOCK' ? 'active' : ''} overflow-hidden`} data-value={'OUT_OF_STOCK'} onClick={() => handleChangeType('OUT_OF_STOCK')} key={`out-of-stock`}>
            <span className="text-ellipsis">{t('TREATMENT_PRODUCT.PRODUCT.PD_OUT')}</span>
          </ButtonType>
        </Box>
        <Box className="px-1 pt-2">
          <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={() => handleChangeType('BOOKMARK')} key={`type-bookmark`}>
            {typeSelected === 'BOOKMARK' ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
          </ButtonType>
        </Box>
        {_.map(productsType, (type: ProductType, index: number) => {
          return (
            <Box key={`prod-type-${index}`} className="px-1 pt-2">
              <ButtonType
                variant="outlined"
                key={type.productTypeId}
                title={type.productTypeName || type.productTypeNameEn}
                onClick={() => handleChangeType(type.productTypeId)}
                className={`${Number(typeSelected) === Number(type.productTypeId) ? 'active' : ''} text-ellipsis`}
              >
                <span className="text-ellipsis">{type.productTypeName || type.productTypeNameEn}</span>
              </ButtonType>
            </Box>
          )
        })}
      </TypeTabs>
      {(props.pdShowType === '0' || branch_?.pdShowType === '0') && (
        <ProductItem className="d-flex align-items-start flex-wrap custom-scroll mt-3 mt-xl-4">
          {(!_.isEmpty(products) &&
            _.map(products, (prod: any, index: number) => {
              const countItem = handleFindItemMed(prod.itemId)
              return (
                <ProductBox key={`prod-${index}`} className="px-1 px-xl-2 mb-2 mb-xl-3">
                  <ProductCard>
                    {countItem ? <ProductQty>{countItem}</ProductQty> : <></>}
                    <Box onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="card-image">
                      <Box className="position-relative">
                        {prod.isOutOfStock && <span className='position-absolute top-50 start-50 translate-middle fw-bolder' style={{ fontSize: '22px', opacity: 0.9, zIndex: 1, borderBottom: '3px solid', paddingBottom: '' }}>{t('TREATMENT_PRODUCT.PRODUCT.OUT_STOCK')}</span> || <span></span>}
                        <img src={renderImage(prod.image)} alt={prod.itemName} className={`${_.isEmpty(prod.image) || !prod.image?.filePath || !prod.image?.fileName ? 'no-prod-image' : ''}`} onError={onImgError} style={{ ...(prod.isOutOfStock && { opacity: 0.5 }) }} />
                      </Box>
                    </Box>
                    <Box className="card-detail">
                      {(prod.itemCode && (
                        <Typography onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="text-ellipsis" sx={{ paddingBottom: '2px', color: colors.textLightGray, fontSize: '11px', fontWeight: 500 }}>
                          {prod.itemCode}
                        </Typography>
                      )) || <></>}
                      <Box className="d-flex align-items-center justify-content-between">
                        <Typography onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="text-ellipsis" sx={{ flex: 1, lineHeight: 1.6 }}>
                          {prod.itemName || prod.itemNameEn}
                        </Typography>
                        <IconBookmark className="p-0 ml-2" onClick={() => handleBookmark(prod.itemId, prod.bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                          {prod.isBookmark ? <Icons.StarActive width="20" height="20" /> : <Icons.StarInActive width="20" height="20" />}

                        </IconBookmark>
                      </Box>
                      <Typography onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="text-ellipsis" sx={{ marginTop: '-2px', fontSize: '14px', fontWeight: 500 }}>
                        {numberFormat(prod.price)} {t('CURRENCY_CHANGE.TH')}
                      </Typography>
                      {prod.isOutOfStock === 0 &&
                        <Box onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="d-flex align-items-center justify-content-between pt-1">
                          <Typography
                            className="text-ellipsis"
                            sx={{
                              color: renderDateExpired(prod.expireDate, prod.amounDayBeforeExpired),
                              fontSize: '11px',
                              fontWeight: 500
                            }}
                          >
                            {(prod.expireDate && `EXP ${toBuddhistYear(moment(prod.expireDate), 'DD/MM/YY')} (${numberFormat(prod.qtyExpireDate, 0, 0)})`) || `Lot (${numberFormat(prod.qtyExpireDate, 0, 0)})`}
                          </Typography>
                          <InventoryChip label={`${t('TREATMENT_PRODUCT.CARD.QUANTITY')}: ${numberFormat(prod.qty, 0, 0)}`} limit={prod.qty <= prod.minStock} className="ml-2 p-0" />
                        </Box>
                        ||
                        <Box sx={{ height: '22px' }}>
                        </Box>
                      }

                    </Box>
                  </ProductCard>
                </ProductBox>
              )
            })) || (
              <Typography className="w-100 text-center" sx={{ color: colors.disabledGray, paddingTop: '48px' }}>
                {t('TREATMENT_PRODUCT.TITLE.EMPTY_PRODUCT')}
              </Typography>
            )}
        </ProductItem>
      )}
      {(props.pdShowType === '1' || branch_?.pdShowType === '1') && (
        <ResponsiveTxTable className="mt-2">
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            setPageLimit={(limit) => setPageLimit(limit)}
            setPage={(val) => setPage(val)}
            rowCount={rowCount}
            headCells={headCells}
            rowsData={products.map((val, i) => {
              return renderData(val, i)
            })}
          />
        </ResponsiveTxTable>
      )}

      {popupProductOutOfStock.name && <AlertProductOutOfStock type="product" name={popupProductOutOfStock.name} code={popupProductOutOfStock.code} qty={popupProductOutOfStock.qty} unit={popupProductOutOfStock.unit} />}

      {isShowPopupProduct ? (
        <PopupManageProduct
          page={props.type}
          group="PRODUCT"
          type="thumbnail"
          item={popupManageProduct}
          patientCaseId={props.patientCaseId}
          userId={userId}
          openScanBarcode={btnBarcode}
          onCancel={onCancelPopupManageProduct}
          onSubmit={onCancelPopupManageProduct}
          patientName={`${props.patient.firstname} ${props.patient.lastname}`}
          hasChangeProductPrice={props.hasChangeProductPrice}
          hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional}
          patientId={props?.patient?.patientId}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
