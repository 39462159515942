import { useCallback, useEffect, useState } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

/** API */
import PaymentApi from 'api/counter/payment/payment.api'
import AboutUs from 'api/admin/clinic-manage/about.api'

/** INTERFACE */
import { PaymentList as PaymentListType, PrintReceiptInterface } from 'api/counter/payment/payment-interface'

/** COMPONENT */
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import DefaultPagination from 'component/Pagination/DefaultPagination'

/** UTILS & CONSTANT */
import { dateToView } from 'utils/date.utils'
import UseWindowSize from 'utils/useWindowSize'
import { checkPackage, getBranch, getClinicInfo, numberFormat } from 'utils/app.utils'
import { PACKAGE_ADDONS } from 'constants/package'

/** STYLE */
import { colors } from 'constants/theme'
import * as UseStyled from 'features/finance/payment/style/payment-style'
import { routeName } from 'routes/routes-name'
import { useHistory, useLocation } from 'react-router-dom'
import { fileUrl } from 'api/api'
import Loading from 'component/Loading'
import { KEY_STORAGE } from 'constants/common'

import { useDispatch, useSelector } from 'react-redux'
import { branchInfo, editBranchInfo } from 'app/slice/user.slice'

export interface IPaymentListProps {
  branchId: number
}

export default function PaymentList(props: IPaymentListProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [width, height] = UseWindowSize()
  const [loading, setLoading] = useState<boolean>(false)
  /** FILTER */
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [filterRangeDate, setFilterRangeDate] = useState<string[]>([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [page, setPage] = useState(1)

  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('date')
  const [sortType, setSortType] = useState('DESC')

  /** data */
  const [payments, setPayments] = useState<PaymentListType[]>([])

  useEffect(() => {
    if (localStorage.getItem(KEY_STORAGE.DATE_FINANCE)) {
      const state: any = localStorage.getItem(KEY_STORAGE.DATE_FINANCE)
      if (state) {
        setFilterRangeDate(JSON.parse(state))
      }
    }
  }, [localStorage.getItem(KEY_STORAGE.DATE_FINANCE)])

  const loadData = useCallback(async () => {
    setLoading(true)
    let condition: any = { branchId: getBranch() }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (filterSearch) condition = { ...condition, search: filterSearch }
    if (filterSearch) condition = { ...condition, search: filterSearch }
    if (filterRangeDate) {
      if (filterRangeDate[0] !== null && filterRangeDate[1] !== null) condition = { ...condition, dateStart: filterRangeDate[0], dateEnd: filterRangeDate[1] }
    }
    const patients = await PaymentApi.list(condition).finally(() => setLoading(false))
    if (patients.status === 200) {
      setPayments(patients.data)
      setRowCount(patients.headers['x-total'])
    } else {
      setPayments([])
      setRowCount(0)
    }
  }, [page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate])

  useEffect(() => {
    loadData()
  }, [page, pageLimit, sortBy, sortType, filterSearch, filterRangeDate])

  const handleOrderPayment = (orderPaymentChannelText: any) => {
    let orderPaymentChannelParts = []
    if (orderPaymentChannelText) orderPaymentChannelParts = orderPaymentChannelText.split('<br/>');
    const jsonData: any = {};
    orderPaymentChannelParts.forEach((part: any) => {
      const regex = /(.+?) \(([\d.,]+) บาท\)/;
      const match = part.match(regex);
      if (match) {
        const name = match[1].trim();
        const amount = parseFloat(match[2].replace(/,/g, ''));
        jsonData[name] = amount;
      }
    });

    const dataOrderPayment: any = {};
    for (const key in jsonData) {
      const [method, bank] = key.split(' : ');
      if (bank) {
        if (method === "สิทธิการรักษา") {
          if (dataOrderPayment[method]) {
            dataOrderPayment[method] = [...dataOrderPayment[method], { name: bank, amount: jsonData[key] }]
          } else {
            dataOrderPayment[method] = [{ name: bank, amount: jsonData[key] }]
          }
        } else {
          if (!dataOrderPayment[method]) {
            dataOrderPayment[method] = {};
          }
          dataOrderPayment[method] = { name: bank, amount: jsonData[key] }
          // dataOrderPayment[method]["amount"] = jsonData[key];
        }

      } else {
        dataOrderPayment[method] = jsonData[key];
      }
    }
    return dataOrderPayment
  }
  const loadPermissExport = useCallback(async () => {
    const branchId = getBranch()
    AboutUs.detailClinic(Number(branchId))
      .then(({ data }) => {
        dispatch(editBranchInfo({ value: { data } }))
        localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...data, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))
      })
      .catch((e) => { return })
  }, [])

  useEffect(() => {
    loadPermissExport()
  }, [])

  const desktopHeadCells = [
    { id: 'no', disablePadding: false, label: t('#'), class: 'text-nowrap' },
    { id: 'dateAt', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.DATE'), class: 'text-nowrap' },
    { id: 'paymentNumber', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.PAYMENT_NUMBER'), class: 'text-nowrap' },
    { id: 'message', disablePadding: false, label: t('APPLICATION.TABLE.CELL.CN_NUMBER'), class: 'text-nowrap' },
    { id: 'branchId', disablePadding: false, label: t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID'), class: 'text-nowrap pr-1' },
    { id: 'patientFullName', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.PATIENT_FULL_NAME'), class: 'text-nowrap' },
    { id: 'orderPaymentChannel', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.RECEIVE_TYPE'), class: 'text-nowrap' },
    { id: 'amountChange', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.AMOUNT_CHANGE'), align: 'right', class: 'text-nowrap' },
    { id: 'createdBy', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.STAFF'), class: 'text-nowrap' },
    { id: 'statusOrder', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.STATUS'), class: 'text-nowrap' }
  ]

  const tableHeadCells = [{ id: 'action', disablePadding: false, label: t('PAYMENT_QUEUE.TABLE.ACTION'), class: 'text-nowrap' }]

  const headCells = width >= 1200 ? [...desktopHeadCells, ...tableHeadCells] : [...tableHeadCells]

  const renderData = (objData: PaymentListType, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { orderPaymentId, dateAt, timeAt, paymentNumber, cnNumber, branchCnNumber, patientFullName, orderPaymentChannelText, amountChange, createdBy: staffBy, statusOrder, orderPaymentChannelTreatmentRight } = objData
    const objRenderData = {
      key: String(no),
      id: String(orderPaymentId),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no, class: 'px-1 text-ellipsis' },
        { option: 'TEXT', align: 'center', label: `${dateToView(dateAt)} ${timeAt} ${t('TIME_UNIT')}` || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: paymentNumber || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: cnNumber || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: branchCnNumber || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: patientFullName || '-', class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: <div dangerouslySetInnerHTML={{ __html: orderPaymentChannelText !== null ? orderPaymentChannelText : orderPaymentChannelTreatmentRight || '-' }} /> },
        { option: 'TEXT', align: 'right', label: numberFormat(amountChange || 0) || 0, class: 'text-nowrap' },
        { option: 'TEXT', align: 'left', label: staffBy || '-', class: 'text-ellipsis' },
        { option: 'TEXT', align: 'left', label: t(`PAYMENT_QUEUE.STATUS.${statusOrder}`) || '-', class: 'text-nowrap' },
        {
          option: 'COMPONENT',
          align: 'center',
          component:
            <ButtonPrintBill
              orderPaymentId={orderPaymentId}
              onLoading={setLoading}
            // isDisabled={statusOrder === 'CANCEL'} 
            />,
          class: 'text-nowrap'
        }
      ]
    }

    const dataOrderPayment: any = handleOrderPayment(orderPaymentChannelText)

    return (
      <TableRowCommon
        {...objRenderData}
        rowLink={true}
        onClick={(e, val) => {
          const data: any = { ...val, dataOrderPayment }
          history.push(routeName.paymentDetail, data)
          localStorage.setItem(KEY_STORAGE.DATE_FINANCE, JSON.stringify(filterRangeDate))
        }}
      />
    )
  }

  const renderDataSlim = (objData: PaymentListType, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const {
      orderPaymentId,
      dateAt,
      timeAt,
      paymentNumber,
      cnNumber,
      branchCnNumber,
      patientFullName,
      orderPaymentChannel,
      orderPaymentChannelTreatmentRight: trChannelText,
      orderPaymentChannelAdvance: advCannelText,
      amountChange,
      createdBy: staffBy,
      statusOrder,
      orderPaymentChannelText
    } = objData
    return (
      <UseStyled.TBody>
        <div className={'d-flex align-items-center position-relative'}>
          <UseStyled.Cell>
            <Row className="pl-4 mx-0">
              <div className="position-absolute w-auto px-0" style={{ marginLeft: '-1.5rem' }}>
                {no}
              </div>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.DATE')}
              </Col>
              <Col xs={6} lg={8}>
                {dateToView(dateAt) || '-'}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.TIME')}
              </Col>
              <Col xs={6} lg={8}>
                {timeAt || '-'}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.PAYMENT_NUMBER')}
              </Col>
              <Col xs={6} lg={8} style={{ cursor: 'pointer', textDecoration: 'underline', color: colors.themeSecondColor }}
                onClick={() => {
                  const dataOrderPayment: any = handleOrderPayment(orderPaymentChannelText)
                  const data: any = { ...objData, dataOrderPayment }
                  history.push(routeName.paymentDetail, data)
                }}
              >
                {paymentNumber || '-'}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('APPLICATION.TABLE.CELL.CN_NUMBER')}
              </Col>
              <Col xs={6} lg={8}>
                {cnNumber || '-'}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PATIENT_MANAGEMENT.TABLE.CELL.BRANCH_ID')}
              </Col>
              <Col xs={6} lg={8}>
                {branchCnNumber || '-'}
              </Col>

              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.PATIENT_FULL_NAME')}
              </Col>
              <Col xs={6} lg={8}>
                {patientFullName || '-'}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.RECEIVE_TYPE')}
              </Col>
              <Col xs={6} lg={8}>
                {<div dangerouslySetInnerHTML={{ __html: orderPaymentChannelText }} /> || trChannelText}
                {/* {orderPaymentChannel || '-'} */}
              </Col>
              {/* <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.TREATMENT_RIGHT')}
              </Col>
              <Col xs={6} lg={8}>
                {<div dangerouslySetInnerHTML={{ __html: orderPaymentChannelText }} /> || trChannelText}
              </Col> */}
              {/* {checkPackage(PACKAGE_ADDONS.ADDON_ADVANCE) && (
                <>
                  <Col xs={6} lg={4} className="font-weight-bold">
                    {t('PAYMENT_QUEUE.TABLE.ADVANCE')}
                  </Col>
                  <Col xs={6} lg={8}>
                    {advCannelText || '-'}
                  </Col>
                </>
              )} */}
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.AMOUNT_CHANGE')}
              </Col>
              <Col xs={6} lg={8}>
                {numberFormat(amountChange || 0) || '0'}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.STAFF')}
              </Col>
              <Col xs={6} lg={8}>
                {staffBy || '-'}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.STATUS')}
              </Col>
              <Col xs={6} lg={8}>
                {t(`PAYMENT_QUEUE.STATUS.${statusOrder}`)}
              </Col>
              <Col xs={6} lg={4} className="font-weight-bold">
                {t('PAYMENT_QUEUE.TABLE.ACTION')}
              </Col>
              <Col xs={6} lg={8}>
                <ButtonPrintBill orderPaymentId={orderPaymentId} onLoading={setLoading} />
              </Col>
            </Row>
          </UseStyled.Cell>
        </div>
      </UseStyled.TBody>
    )
  }

  return (
    <div>
      <Loading show={loading} type="fullLoading" />
      <div className="p-3 " style={{ boxShadow: `0px 3px 6px ${colors.black08}` }}>
        <h5>{t('PAYMENT.TITEL')}</h5>
      </div>
      <div className="p-3">
        <Row className="mb-2 mx-0">
          <Col xs={12} md={12} lg={6} xxl={4} className="px-1 mb-2">
            <InputTextSearch
              keyInput={'search'}
              label={t('PAYMENT.SEARCH')}
              value={filterSearch}
              onchange={(event) => {
                setFilterSearch(event.target.value)
                setPage(1)
              }}
              onClear={(event) => {
                setFilterSearch('')
                setPage(1)
              }}
            />
          </Col>
          <Col xs={12} md={12} lg={6} className="px-1 mb-2">
            <Box sx={{ maxWidth: { lg: '320px' } }}>
              <InputSecondNewRangePicker
                value={filterRangeDate || []}
                inputHeight={32}
                onchange={(val: any) => {
                  if (_.isEmpty(val)) setFilterRangeDate(emptyRangePicker)
                  else setFilterRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                  setPage(1)
                  localStorage.removeItem(KEY_STORAGE.DATE_FINANCE)
                }}
                allowClear
                onClear={() => setFilterRangeDate(emptyRangePicker)}
              />
            </Box>
          </Col>
        </Row>
        <div className=" overflow-auto custom-scroll " style={{ maxHeight: `calc(${height}px - 270px)` }}>
          <UseStyled.TableConfig className="table-payment custom-scroll">
            {width >= 1200 ? (
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                rowCount={rowCount}
                onSort={(sortByVal: string, sortTypeVal: string) => {
                  setSortType(sortTypeVal)
                  setSortBy(sortByVal)
                }}
                setPageLimit={setPageLimit}
                setPage={setPage}
                headCells={headCells}
                rowsData={_.map(payments, (val, i) => renderData(val, i))}
              />
            ) : (
              <>
                <UseStyled.THead>
                  <UseStyled.Cell>{t('#')}</UseStyled.Cell>
                </UseStyled.THead>
                <div className={'treatment-body'}>
                  {_.map(payments, renderDataSlim)}
                  <div className={'mt-2 '}>
                    <DefaultPagination count={rowCount} allRow={rowCount} pageLimit={pageLimit} page={page} setPage={setPage} />
                  </div>
                </div>
              </>
            )}
          </UseStyled.TableConfig>
        </div>
      </div>
    </div>
  )
}

export interface IPrintProps {
  orderPaymentId: number
  isDisabled?: boolean
  onLoading: (load: boolean) => void
}
export function ButtonPrintBill(props: IPrintProps) {
  const { t } = useTranslation()
  const branchesInfo = { data: getClinicInfo() }
  console.log('branchesInfo', branchesInfo);

  const printReceipt = async (type: 'A5' | 'A5_ORI' | 'A5_COPY' | 'A5_EN' | 'A5_EN_ORI' | 'A5_EN_COPY' | 'A5_LANDSCAPE' | 'A5_LANDSCAPE_ORI' | 'A5_LANDSCAPE_COPY' | 'A4' | 'A4_ORI' | 'A4_COPY' | 'THERMAL', isShow?: 'DF', isFixDF?: boolean) => {
    const printProp: PrintReceiptInterface = { lang: 'TH', type: 'FULL' }
    let receipt: any = ''
    props.onLoading(true)
    if (type === 'THERMAL') {
      printProp.hasServicePrice = isShow === 'DF' ? "1" : '0'
      receipt = await PaymentApi.printThermal(props.orderPaymentId, printProp)
    } else if (type === 'A5' || type === 'A5_ORI' || type === 'A5_COPY' || type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') {
      if (type === 'A5_ORI' || type === 'A5_EN_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_COPY' || type === 'A5_EN_COPY') printProp.type = 'COPY'

      if (type === 'A5_EN' || type === 'A5_EN_ORI' || type === 'A5_EN_COPY') printProp.lang = 'EN'
      printProp.fixDF = isFixDF ? '1' : '0'

      receipt = await PaymentApi.printA5(props.orderPaymentId, printProp)
    } else if (type === 'A5_LANDSCAPE' || type === 'A5_LANDSCAPE_ORI' || type === 'A5_LANDSCAPE_COPY') {
      printProp.orientation = 'LANDSCAPE'
      printProp.hasServicePrice = isShow === 'DF' ? "1" : '0'
      printProp.fixDF = isFixDF ? '1' : '0'
      if (type === 'A5_LANDSCAPE_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A5_LANDSCAPE_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA5(props.orderPaymentId, printProp)
    } else if (type === 'A4' || type === 'A4_ORI' || type === 'A4_COPY') {
      if (type === 'A4_ORI') printProp.type = 'ORIGINAL'
      else if (type === 'A4_COPY') printProp.type = 'COPY'
      receipt = await PaymentApi.printA4(props.orderPaymentId, printProp)
    }
    props.onLoading(false)
    if (receipt && receipt.status === 200) return window.open(`${fileUrl}/${receipt.data}`, '_blank')
    return false
  }

  return (
    <>
      <UseStyled.DropdownCustom>
        <Dropdown.Toggle id="dropdown-basic" disabled={props?.isDisabled || false}>
          {t('PAYMENT_QUEUE.PRINT_BILL')}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {branchesInfo?.data?.receiptA5Default === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5')}>{t('PAYMENT_QUEUE.BILL_A5')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5Original === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_ORI')}>{t('PAYMENT_QUEUE.BILL_A5_ORI')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5Copy === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_COPY')}>{t('PAYMENT_QUEUE.BILL_A5_COPY')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5DefaultEn === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_EN')}>{t('PAYMENT_QUEUE.BILL_A5_EN')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5OriginalEn === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_EN_ORI')}>{t('PAYMENT_QUEUE.BILL_A5_EN_ORI')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5CopyEn === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_EN_COPY')}>{t('PAYMENT_QUEUE.BILL_A5_EN_COPY')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5DefaultLandscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE')}>{t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5OriginalLandscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE_ORI')}>{t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE_ORI')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5CopyLandscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE_COPY')}>{t('PAYMENT_QUEUE.BILL_A5_LANDSCAPE_COPY')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5DfLscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE', 'DF')}>{t('RECEIPT.A5_DF_LSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5DfOrgLscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE_ORI', 'DF')}>{t('RECEIPT.A5_DF_ORG_LSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5DfCopyLscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE_COPY', 'DF')}>{t('RECEIPT.A5_DF_COPY_LSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5FixDfLscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE', 'DF', true)}>{t('RECEIPT.A5_FIX_DF_LSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5FixDfOrgLscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE_ORI', 'DF', true)}>{t('RECEIPT.A5_FIX_DF_ORG_LSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA5FixDfCopyLscape === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A5_LANDSCAPE_COPY', 'DF', true)}>{t('RECEIPT.A5_FIX_DF_COPY_LSCAPE')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA4Default === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A4')}>{t('PAYMENT_QUEUE.BILL_A4')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA4Original === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A4_ORI')}>{t('PAYMENT_QUEUE.BILL_A4_ORI')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptA4Copy === "1" &&
            <Dropdown.Item onClick={() => printReceipt('A4_COPY')}>{t('PAYMENT_QUEUE.BILL_A4_COPY')}</Dropdown.Item>
          }
          {branchesInfo?.data?.receiptThermal === "1" &&
            <Dropdown.Item onClick={() => printReceipt('THERMAL')}>{t('PAYMENT_QUEUE.BILL_THERMAL')}</Dropdown.Item>
          }
          {/* {branchesInfo?.data?.receiptThermalDf === "1" &&
            <Dropdown.Item onClick={() => printReceipt('THERMAL', "DF")}>{t('RECEIPT.THERMAL_DF')}</Dropdown.Item>
          } */}
        </Dropdown.Menu>
      </UseStyled.DropdownCustom>
    </>
  )
}

// branchesInfo