import { numberFormat } from 'utils/app.utils';
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  branchInfo?: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
}

export default function IncomeType(props: FcComponent) {
  const { t } = useTranslation()

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.TYPE_OF_PAYMENT', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_CANCEL', align: 'right', class: 'text-nowrap' },
  ]

  const renderData = (data: any, no: number) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1

    const paymentChanel = data.paymentChannelName === 'Voucher' ? t('PAYMENT.FORM_PAYMENT.PAYMENT_METHODS.OPTION.VOUCHER') : data.paymentChannelName

    return <>
      <TableRowCommon
        key={num.toString()}
        id={no.toString()}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: `${paymentChanel}${data.cardTypeName !== null ? `/${data.cardTypeName}` : ``}` || "-" },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidNormal) || "-" },
          { option: 'TEXT', align: 'right', label: numberFormat(data.paidCancel) || "-" }
        ]}
      />
    </>
  }

  return (
    <UseStyled.ResponsiveReportTable>
      <TableCustom
        page={props.page}
        pageLimit={props.pageLimit}
        sortType={props.sortType}
        sortBy={props.sortBy}
        rowCount={props.rowCount}
        onSort={props.onRequestSort}
        setPageLimit={props.handleChangeRowsPerPage}
        setPage={props.handleChangePage}
        headCells={headCells}
        rowsData={
          [
            _.map(props.data, (sd: any, index: number) => {
              return (
                renderData(sd, index)
              )
            })
          ]
        }
        hidePagination={true}
        tableMinWidth={640}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  )
}
export function IncomeSum(props: FcComponent) {
  const { t } = useTranslation()


  const headCells = [
    { id: 'message', disablePadding: false, labelKey: '#', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
  ]

  const renderDataSum = () => {
    const data = props.data
    const calculateAdvance = JSON.parse(props.branchInfo)?.calculateAdvance
    const isIncrease = calculateAdvance === "INCREASE"

    let sum = 0
    _.map(data, (o: any) => {
      if (o.type === "ORDER_PAYMENT")
        sum += Number(o.amountPaid) - Number(o.amountAdvance)
      if (!isIncrease && o.type === "ORDER_PAYMENT")
        sum += Number(o.amountAdvance)
      else if (isIncrease && o.type === "TRANSECTION_ADVANCE")
        sum += Number(o.amountPaid)
    })

    return <>
      <TableRowCommon
        key={`sum`}
        id={`sum`}
        obj={null}
        columns={[
          { option: 'TEXT', align: 'left', label: `รวม` || "-" },
          { option: 'TEXT', align: 'right', label: numberFormat(sum) || "-" },
        ]}
      />
    </>
  }

  return (
    <>
      <Box sx={{ maxWidth: '640px' }}>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[renderDataSum()]}
            hidePagination={true}
            tableMinWidth={640}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
